<template>
  <div>
    {{ label }}
    <v-radio-group :value="value" @change="$emit('change', $event)">
      <v-radio
        v-for="radio in radios"
        :key="radio.name"
        :disabled="disabled"
        :label="radio.name"
        :value="radio.name"
        :error-messages="validationError"
      ></v-radio>
    </v-radio-group>
  </div>
</template>

<script>
export default {
  name: "RadioButtonList",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    label: {
      type: String,
      default: ""
    },
    radios: {
      type: Array,
      required: true
    },
    value: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    validationError: {
      type: String,
      default: null
    }
  }
};
</script>

<style scoped></style>
