import axiosPackage from 'axios'
import {Auth} from 'aws-amplify'
import {v4 as uuidv4} from 'uuid';
import * as jdenticon from "jdenticon";

let axios
let usPrefix = ""

export default class DocumentController {
  prefix = 'forms'
  store = null

  constructor(store, prefix) {
    this.prefix = prefix
    this.store = store
    axios = axiosPackage.create({
      baseURL: this.store.getters.config['api_url']
    })

    axios.interceptors.request.use(async config => {
        await Auth.currentSession().then(res => {
          config.headers['Authorization'] = res.getAccessToken().getJwtToken()
          localStorage.setItem('tkn', res.getAccessToken().getJwtToken())
        }).catch(err => console.log(err))
        return config
      }
    )

    axios.interceptors.response.use(response => response, function (error) {
      console.log(error);

      if (!error.response) {
        //todo this could be a NetworkError if we hit a route with CORS errors
        store.commit("setSyncAlertError")
      } else {
        if (/4[0-9]{2}/.exec(error.response.status)) {
          store.commit("setSyncAlertError")
        }
        if (error.response.status === 404) {
          //todo add i18n
          store.commit("setSyncAlertError", "document not found")
        }
        if (error.response.status === 403) {
          store.commit("setSyncAlertError", "no permissions")
        }
      }
      return Promise.reject(error);
    });
  }

  async getDocumentList(param) {
    return axios
      .get('db/v2/' + this.prefix, {params: param})
      .then(response => {
        response.data.forEach(document => {
          document.creatorName = this.resolveUserNameById(document.creator)
          document.editorName = this.resolveUserNameById(document.editor)
        })

        return response
      }).then(response => {
        // https://stackoverflow.com/questions/8900732/sort-objects-in-an-array-alphabetically-on-one-property-of-the-array
        response.data = response.data.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
        return response
      })
  }

  async getDocument(docId) {
    if (localStorage.getItem('lang') === "en") {
      usPrefix = "us/"
    } else {
      usPrefix = ""
    }

    return axios
      .get('db/v2/' + usPrefix + this.prefix + '/' + docId)
      .then(response => {
        response.data.creatorName = this.resolveUserNameById(response.data.creator)
        response.data.editorName = this.resolveUserNameById(response.data.editor)
        return response
      })
  }

  async getDocumentByRevId(docId, revId) {
    if (localStorage.getItem('lang') === "en") {
      usPrefix = "us/"
    } else {
      usPrefix = ""
    }

    return axios
      .get('db/v2/' + usPrefix + this.prefix + '/' + docId + '/revisions/'+ revId)
      .then(response => {
        response.data.creatorName = this.resolveUserNameById(response.data.creator)
        response.data.editorName = this.resolveUserNameById(response.data.editor)
        return response
      })
  }

  async validateDocument(docId) {
    if (localStorage.getItem('lang') === "en") {
      usPrefix = "us/"
    } else {
      usPrefix = ""
    }

    return axios
      .get('db/v2/' + usPrefix + this.prefix + '/' + docId + '/validation')
      .then(response => {
        return response
      })
  }

  async getDocumentChangelog(docId) {
    if (localStorage.getItem('lang') === "en") {
      usPrefix = "us/"
    } else {
      usPrefix = ""
    }

    return axios
      .get('db/v2/' + usPrefix + this.prefix + '/' + docId + '/changelog')
      .then(response => {
        let data = JSON.parse(response.data)

        data = data.forEach(element => {
          element.creatorName = this.resolveUserNameById(response.data.creator)
        })
        return response
      })
  }

  async getDocumentChangelogFiles(docId) {
    if (localStorage.getItem('lang') === "en") {
      usPrefix = "us/"
    } else {
      usPrefix = ""
    }

    return axios
      .get('db/v2/' + usPrefix + this.prefix + '/' + docId + '/changelogFiles')
      .then(response => {
        return response
      })
  }

  async getRevisions(docId) {
    if (localStorage.getItem('lang') === "en") {
      usPrefix = "us/"
    } else {
      usPrefix = ""
    }

    return axios
      .get('db/v2/' + usPrefix + this.prefix + '/' + docId + '/revisions')
      .then(response => {
        return response
      })
  }

  async getRevision(docId, revId) {
    if (localStorage.getItem('lang') === "en") {
      usPrefix = "us/"
    } else {
      usPrefix = ""
    }

    return axios
      .get('db/v2/' + usPrefix + this.prefix + '/' + docId + '/revisions/' + revId)
      .then(response => {
        response.data.creatorName = this.resolveUserNameById(response.data.creator)
        response.data.editorName = this.resolveUserNameById(response.data.editor)
        return response
      })
  }

  async checkRevisionDocuments(templateRevisionId) {
    if (localStorage.getItem('lang') === "en") {
      usPrefix = "us/"
    } else {
      usPrefix = ""
    }

    return axios
      .get('db/v2/' + usPrefix + this.prefix + '/' + templateRevisionId + '/activeDocuments')
      .then(response => {
        return response.data
      })
  }

  addUUIDs(document) {
    if (this.prefix === "templates") {
      document.sections.forEach(section => {
        if(!section.id)
          section.id = uuidv4()
        loopThroughFields(section)
      })
    }

    function loopThroughFields(section) {
      section['fields'].forEach(field => {
        if(!field.id)
          field.id = uuidv4()
        if (field.type === 'Section') {
          loopThroughFields(field)
        }
      })
    }
  }

  createDocument(document) {
    // todo add check in the backend that every field has UUID
    this.addUUIDs(document)

    if (localStorage.getItem('lang') === "en") {
      usPrefix = "us/"
    } else {
      usPrefix = ""
    }

    return axios
      .post('db/v2/' + usPrefix + this.prefix, document)
  }

  updateDocument(document) {
    // todo add check in the backend that every field has UUID
    this.addUUIDs(document)
    if (localStorage.getItem('lang') === "en") {
      usPrefix = "us/"
    } else {
      usPrefix = ""
    }

    return axios
      .put('db/v2/' + usPrefix + this.prefix + '/' + document.id, document)
  }

  deleteDocument(docId) {
    if (localStorage.getItem('lang') === "en") {
      usPrefix = "us/"
    } else {
      usPrefix = ""
    }

    return axios
      .delete('db/v2/' + usPrefix + this.prefix + '/' + docId)
  }

  getUserList() {
    return axios.get('/users')
      .then((res) => {
        return res.data
      })
  }

  getUser(userId) {
    return axios.get('/users/' + userId)
      .then(res => {
        return res.data
      })
  }

  getGroupList() {
    return axios.get('/groups')
      .then((res) => {
        return res.data
      })
  }

  getGroup(groupName) {
    return axios.get('/groups/' + groupName)
      .then(res => {
        return res.data
      })
  }

  generateProfilePicture(parameter) {
    jdenticon.configure({
      lightness: {
        color: [0.40, 0.80],
        grayscale: [0.30, 0.90]
      },
      saturation: {
        color: 0.50,
        grayscale: 0.00
      },
      backColor: "#fff"
    })

    return "data:image/svg+xml;base64," + btoa(jdenticon.toSvg(parameter, 100).toString())
  }

  resolveUserNameById(userId) {
    let userList = this.store.getters.getUserList
    let user = userList.get(userId)
    if (user) {
      return user.name
    } else {
      //todo call getUsers api endpoint and refresh cache :D
      return '???'
    }
  }

  formatDate(dateString) {
    const date = new Date(dateString);

    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Monate sind nullbasiert
    const year = date.getUTCFullYear();
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');

    return `${day}.${month}.${year} - ${hours}:${minutes}`;
  }

  getAttachment(docId, attachmentId, fileType) {
    return axios.get('/db/' + this.prefix + '/' + docId + '/attachments/' + attachmentId + '/' + fileType)
      .then(response => {
        if (response.data === "data:image/png;base64,") {
          return null
        } else {
          return response.data
        }
      })
  }

  putAttachment(docId, attachmentId, fileType, fileID, file) {
    let data = file
    let buf = null
    let fileToStore = null

    let headers = {}

    switch (fileType) {
      case "image":
        buf = file.replace('data:image/png;base64,', "");
        buf = Buffer.from(data, 'base64');
        fileToStore = buf
        //headers = { 'Content-Type': 'image/png'};
        headers = {'Content-Type': 'text/plain'}
        break
    }

    if (localStorage.getItem('lang') === "en") {
      usPrefix = "us/"
    }

    // todo this should never be the case
    return axios
      .put('/db/' + usPrefix + this.prefix + '/' + docId + '/attachments/' + attachmentId + '/' + fileType + '/' + fileID, "" + data, {headers})
  }

  deleteAttachment(docId, attachmentId, fileType, fileID) {
    if (localStorage.getItem('lang') === "en") {
      usPrefix = "us/"
    }

    //todo there should be a 404 because no images are in the us S3
    return axios
      .delete('/db/' + usPrefix + this.prefix + '/' + docId + '/attachments/' + attachmentId + '/' + fileType+ '/' + fileID)
  }

  createRevision(document, comment) {
    return axios
      .post('/db/v2/' + usPrefix + this.prefix + '/' + document.id + '/revisions', { comment: comment })
  }

  upgradeTemplateRevision(documentID, templateRevision) {
    return axios
      .put('/db/v2/' + usPrefix + this.prefix + '/' + documentID + '/upgradeTemplateVersion' + '/' + templateRevision)
  }

  getDocumentsForUser() {
    return axios.get('/db/profile/documents')
      .then(res => {
        return res.data
      })
  }
}

