import JsPDF from "jspdf";
import canvg from "canvg";
import "jspdf-autotable";

const sectionFontSize = 22;
const defaultFontSize = 12;

const DIN_A4_HEIGHT = 29.7;
const DIN_A4_WIDTH = 21.0;

const LEFT_MARGIN = 2;
const RIGHT_MARGIN = 2;
const TOP_MARGIN = 2;
const BOTTOM_MARGIN = 2;

const DOTS_PER_CM = 72 / 2.54;
const CM_PER_DOT = 2.54 / 72;

const HEADER_HEIGHT = TOP_MARGIN;
const FOOTER_HEIGHT = BOTTOM_MARGIN;

const DEPTH_MARGIN = 0.5;
const TEXT_MARGIN = 0.1;

const TEXT_LINE_HEIGHT = 1.15;

const LINE_SPACING = 0.5;
const FIELD_SPACING = 0.5;
const SECTION_SPACING = 1;

const ABSOLUT_CONTENT_LINE_BOTTOM = DIN_A4_HEIGHT - FOOTER_HEIGHT;
const CONTENT_WIDTH = DIN_A4_WIDTH - LEFT_MARGIN - RIGHT_MARGIN;
const CONTENT_HEIGHT = DIN_A4_HEIGHT - TOP_MARGIN - BOTTOM_MARGIN;

const TABLE_HEADER_FILL_COLOR = "#CCCCCC";
const TABLE_HEADER_TEXT_COLOR = "#000000";

export default async function(doc) {
  let jsPDF = new JsPDF("p", "cm", "a4", true);

  let dimension = new Dimension();

  for (let i = 0; i < doc.sections.length; i++) {
    let section = doc.sections[i];
    let res = await iterateSection(jsPDF, dimension, section);
    jsPDF = res.jsPDF;
    dimension = res.dimension;
  }

  jsPDF.save(
    doc.name + " " + new Intl.DateTimeFormat("de-DE").format(new Date()) + ".pdf"
  );
}

async function iterateSection(jsPDF, dimension, section) {
  dimension = printLine(jsPDF, dimension, section.name, sectionFontSize);

  for (let i = 0; i < section.fields.length; i++) {
    let field = section.fields[i];
    switch (field.type) {
      case "Section":
        // eslint-disable-next-line no-case-declarations
        let res = await iterateSection(jsPDF, dimension.increaseDepth(), field);
        jsPDF = res.jsPDF;
        dimension = new Dimension(
          dimension.depth,
          res.dimension.absolutMarginTop
        );
        break;
      case "Text":
        dimension = printText(dimension, jsPDF, field);
        break;
      case "Date":
        dimension = printDate(dimension, jsPDF, field);
        break;
      case "TextArea":
        dimension = printMultiLine(dimension, jsPDF, field);
        break;
      case "InfoBox":
        dimension = printMultiLine(dimension, jsPDF, field);
        break;
      case "CheckboxList":
        dimension = printCheckBoxList(dimension, jsPDF, field);
        break;
      case "RadioButtonList":
        dimension = printRadioButtonList(dimension, jsPDF, field);
        break;
      case "Table":
        dimension = printTable(dimension, jsPDF, field);
        break;
      case "Signature":
        dimension = await printSignature(dimension, jsPDF, field);
        break;
      case "CheckboxText":
        dimension = printCheckBox(
          dimension,
          jsPDF,
          field.name,
          defaultFontSize,
          "normal",
          field.value
        );
        break;
      case "NumberText":
        dimension = printNumberText(dimension, jsPDF, field);
        break;
      case "Vector":
        dimension = printVector(dimension, jsPDF, field);
        break;
      case "Picture":
        dimension = await printPicture(dimension, jsPDF, field);
        break;
      case "Sketch":
        dimension = await printPicture(dimension, jsPDF, field);
        break;
      default:
        break;
    }
  }

  return { jsPDF: jsPDF, dimension: dimension.nextSection() };
}

function printTable(dimension, jsPDF, field) {
  dimension = printLine(jsPDF, dimension, field.name);

  let body = [];
  for (let i = 0; i < field.elements.values.length; i++) {
    let col = [];
    let headerIDs = field.elements.headers
      .flatMap(header => header.value)
      .filter(value => value !== "actions");
    for (let j = 0; j < headerIDs.length; j++) {
      col.push(field.elements.values[i][headerIDs[j]]);
    }
    body.push(col);
  }

  let table;

  jsPDF.autoTable({
    startY: dimension.absolutMarginTop,
    head: [
      field.elements.headers
        .flatMap(header => header.text)
        .filter(value => value !== "Aktionen")
    ],
    body: body,
    didDrawPage: data => {
      table = data.table;
    },
    headStyles: {
      fillColor: TABLE_HEADER_FILL_COLOR,
      textColor: TABLE_HEADER_TEXT_COLOR
    }
  });

  dimension.absolutMarginTop = table.finalY + FIELD_SPACING * 2;

  return dimension;
}

function printRadioButtonList(dimension, jsPDF, field) {
  dimension = printLine(jsPDF, dimension, field.name);

  let checkedElement = field.checkedElement;

  for (let i = 0; i < field.elements.length; i++) {
    dimension = printRadioButton(
      dimension,
      jsPDF,
      field.elements[i].name,
      defaultFontSize,
      "normal",
      checkedElement === field.elements[i].name,
      true
    );
  }

  return dimension;
}

function printCheckBoxList(dimension, jsPDF, field) {
  dimension = printLine(jsPDF, dimension, field.name);

  for (let i = 0; i < field.elements.length; i++) {
    dimension = printCheckBox(
      dimension,
      jsPDF,
      field.elements[i].name,
      defaultFontSize,
      "normal",
      field.elements[i].checked
    );
  }

  return dimension;
}

function printRadioButton(
  dimension,
  jsPDF,
  text = "",
  fontSize = defaultFontSize,
  fontStyle = "normal",
  checked = false,
  textOffset = 1.2
) {
  let lineDimension = printLine(
    jsPDF,
    dimension.increaseDepth(textOffset),
    getEmptyOrValue(text),
    fontSize,
    fontStyle
  ).decreaseDepth(textOffset);

  jsPDF.setLineWidth(0.001);
  jsPDF.roundedRect(
    dimension.absolutMarginLeft,
    dimension.absolutMarginTop < lineDimension.absolutMarginTop
      ? dimension.absolutMarginTop - fontSize * CM_PER_DOT
      : TOP_MARGIN - fontSize * CM_PER_DOT,
    fontSize * CM_PER_DOT,
    fontSize * CM_PER_DOT,
    (fontSize * CM_PER_DOT) / 2,
    (fontSize * CM_PER_DOT) / 2,
    checked ? "DF" : "S"
  );
  return lineDimension;
}

function printCheckBox(
  dimension,
  jsPDF,
  text = "",
  fontSize = defaultFontSize,
  fontStyle = "normal",
  checked = false,
  textOffset = 1.2
) {
  let lineDimension = printLine(
    jsPDF,
    dimension.increaseDepth(textOffset),
    getEmptyOrValue(text),
    fontSize,
    fontStyle
  ).decreaseDepth(textOffset);

  jsPDF.setLineWidth(0.001);
  jsPDF.rect(
    dimension.absolutMarginLeft,
    dimension.absolutMarginTop < lineDimension.absolutMarginTop
      ? dimension.absolutMarginTop - fontSize * CM_PER_DOT
      : TOP_MARGIN - fontSize * CM_PER_DOT,
    fontSize * CM_PER_DOT,
    fontSize * CM_PER_DOT,
    checked ? "DF" : "S"
  );
  return lineDimension;
}

function printText(dimension, jsPDF, field) {
  return printLine(
    jsPDF,
    dimension,
    getEmptyOrValue(field.name) +
      ": " +
      getEmptyOrValue(field.prefix) +
      " " +
      getEmptyOrValue(field.value) +
      " " +
      getEmptyOrValue(field.suffix),
    defaultFontSize
  );
}

function getEmptyOrValue(value) {
  return value === undefined || value === null ? "" : value;
}

function printMultiLine(dimension, jsPDF, field) {
  return printLine(
    jsPDF,
    dimension,
    getEmptyOrValue(field.name) + ": \n" + getEmptyOrValue(field.value),
    defaultFontSize
  );
}

async function printSignature(dimension, jsPDF, field) {
  if (field.value && field.value.svg) {
    let svg = atob(field.value.svg.replace("data:image/svg+xml;base64,", ""));

    let canvas = document.createElement("canvas");
    let ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    if (dimension.absolutMarginTop > ABSOLUT_CONTENT_LINE_BOTTOM - 5) {
      jsPDF = jsPDF.addPage();
      return printSignature(new Dimension(dimension.depth), jsPDF, field);
    }

    let canv = canvg.fromString(ctx, svg);
    canv.start({ ignoreMouse: true });
    await canv.ready().then(() => {
      jsPDF.addImage(
        canvas,
        "PNG",
        dimension.absolutMarginLeft,
        dimension.absolutMarginTop,
        10,
        5
      );
    });
  }

  return dimension.next(5);
}

async function printPicture(dimension, jsPDF, field) {
  if (field.name) {
    dimension = printLine(jsPDF, dimension, field.name, defaultFontSize);
  }

  if (field.value) {
    let imageSize = getDimensionsOfImage(field.value);

    let maxHeightCM = DIN_A4_HEIGHT - HEADER_HEIGHT - FOOTER_HEIGHT;
    let maxWidthCM = DIN_A4_WIDTH - LEFT_MARGIN * 2;

    let dpi = 100;
    let inch = 2.54; // in a meaningful unit (cm)
    let dpcm = dpi / inch;

    imageSize.heightCM = imageSize.height / dpcm;
    imageSize.widhtCM = imageSize.width / dpcm;

    if (imageSize.heightCM > maxHeightCM) {
      let scaleDownRatio = maxHeightCM / imageSize.heightCM;
      imageSize.width = imageSize.width * scaleDownRatio;
      imageSize.widhtCM = imageSize.widhtCM * scaleDownRatio;
      imageSize.height = imageSize.height * scaleDownRatio;
      imageSize.heightCM = imageSize.heightCM * scaleDownRatio;
    }

    if (imageSize.widhtCM > maxWidthCM) {
      let scaleDownRatio = maxWidthCM / imageSize.widhtCM;
      imageSize.width = imageSize.width * scaleDownRatio;
      imageSize.widhtCM = imageSize.widhtCM * scaleDownRatio;
      imageSize.height = imageSize.height * scaleDownRatio;
      imageSize.heightCM = imageSize.heightCM * scaleDownRatio;
    }

    if (
      dimension.absolutMarginTop >
      ABSOLUT_CONTENT_LINE_BOTTOM - imageSize.heightCM
    ) {
      jsPDF = jsPDF.addPage();
      return printPicture(new Dimension(dimension.depth), jsPDF, field);
    }

    jsPDF.addImage(
      field.value,
      "JPEG",
      dimension.absolutMarginLeft,
      dimension.absolutMarginTop,
      imageSize.widhtCM,
      imageSize.heightCM,
      undefined,
      "SLOW"
    );

    dimension = dimension.next(imageSize.heightCM);
  }

  return dimension;
}

function getDimensionsOfImage(imageSrc = "") {
  let image = document.createElement("img");
  image.src = imageSrc;
  return { height: image.height, width: image.width };
}

function printDate(dimension, jsPDF, field) {
  return printLine(
    jsPDF,
    dimension,
    getEmptyOrValue(field.name) + ": " + getEmptyOrValue(field.value),
    defaultFontSize
  );
}

function printVector(dimension, jsPDF, field) {
  dimension = printLine(
    jsPDF,
    dimension,
    getEmptyOrValue(field.name),
    defaultFontSize,
    "bold"
  );
  let text = "";
  if (field.prefix) text += field.prefix + " ";
  if (field.elements) text += field.elements.join(" x ");
  if (field.suffix) text += " " + field.suffix;
  return printLine(jsPDF, dimension, text);
}

function printNumberText(dimension, jsPDF, field) {
  dimension = printLine(
    jsPDF,
    dimension,
    getEmptyOrValue(field.name),
    defaultFontSize,
    "bold"
  );

  let text = "";
  if (field.fields[1].name) {
    text +=
      getEmptyOrValue(field.fields[1].name) +
      ": " +
      getEmptyOrValue(field.fields[1].value) +
      "\n";
  }
  if (field.fields[0].name) {
    text +=
      getEmptyOrValue(field.fields[0].name) +
      ": " +
      getEmptyOrValue(field.fields[0].value) +
      "\n";
  }
  if (field.fields[2].name) {
    text +=
      getEmptyOrValue(field.fields[2].name) +
      ": " +
      getEmptyOrValue(field.fields[2].value) +
      "\n";
  }

  text = text.trimRight();

  return printLine(jsPDF, dimension, text);
}

function printLine(
  jspdf,
  dimension,
  text,
  fontSize = defaultFontSize,
  fontStyle = "normal"
) {
  let lines;
  if (typeof text === "string") {
    lines = jspdf.splitTextToSize(text, dimension.maxContentWidth, {
      fontSize: fontSize,
      fontStyle: fontStyle
    });
  } else {
    lines = text;
  }

  if (lines.length === 0) return dimension;

  let bottomEnd =
    (fontSize * lines.length * TEXT_LINE_HEIGHT) / DOTS_PER_CM +
    dimension.absolutMarginTop;

  if (bottomEnd > ABSOLUT_CONTENT_LINE_BOTTOM) {
    let maxLines = Math.floor(
      (dimension.maxContentHeight * DOTS_PER_CM) / (TEXT_LINE_HEIGHT * fontSize)
    );

    if (maxLines < 0) maxLines = 0;

    dimension = printLine(
      jspdf,
      dimension,
      lines.slice(0, maxLines),
      fontSize,
      fontStyle
    );

    jspdf = jspdf.addPage();

    return printLine(
      jspdf,
      new Dimension(dimension.depth),
      lines.slice(maxLines),
      fontSize,
      fontStyle
    );
  }

  jspdf
    .setFontSize(fontSize)
    .setFont(undefined, fontStyle)
    .text(lines, dimension.absolutMarginLeft, dimension.absolutMarginTop, {
      lineHeightFactor: TEXT_LINE_HEIGHT
    });

  return dimension.next(bottomEnd - dimension.absolutMarginTop);
}

class Dimension {
  absolutMarginLeft = 0;
  absolutMarginRight = 0;
  absolutMarginTop = 0;
  absolutMarginBottom = 0;

  maxContentWidth = 0;
  maxContentHeight = 0;

  depth = 0;

  constructor(depth = 0, y = HEADER_HEIGHT) {
    this.absolutMarginTop = y < HEADER_HEIGHT ? HEADER_HEIGHT : y;
    this.absolutMarginBottom = DIN_A4_HEIGHT - BOTTOM_MARGIN;
    this.absolutMarginRight = DIN_A4_WIDTH - RIGHT_MARGIN;
    this.absolutMarginLeft = LEFT_MARGIN + depth * DEPTH_MARGIN;

    this.maxContentHeight = this.absolutMarginBottom - this.absolutMarginTop;
    this.maxContentWidth = this.absolutMarginRight - this.absolutMarginLeft;

    this.depth = depth;
  }

  increaseDepth(amount = 1) {
    return new Dimension(this.depth + amount, this.absolutMarginTop);
  }

  decreaseDepth(amount = 1) {
    return new Dimension(this.depth - amount, this.absolutMarginTop);
  }

  next(usedHeight = 0) {
    return new Dimension(
      this.depth,
      this.absolutMarginTop + usedHeight + FIELD_SPACING
    );
  }

  nextSection() {
    return new Dimension(this.depth, this.absolutMarginTop + SECTION_SPACING);
  }
}
