<template>
  <div>
    <v-row>
      <v-col>
        <v-text-field
          v-model="mutableValue.values[1]"
          :disabled="mutableValue.fields[1].disabled || disabled"
          :prefix="mutableValue.fields[1].prefix"
          :suffix="mutableValue.fields[1].suffix"
          :label="mutableValue.fields[1].name + ' - ' + mutableValue.name"
          @change="$emit('change', mutableValue)"
        />
      </v-col>
      <v-col v-if="value.extraText">
        <v-text-field
          v-model="mutableValue.values[0]"
          :disabled="mutableValue.fields[0].disabled || disabled"
          :prefix="mutableValue.fields[0].prefix"
          :suffix="mutableValue.fields[0].suffix"
          :label="mutableValue.fields[0].name"
          @change="$emit('change', mutableValue)"
        />
      </v-col>
      <v-col cols="auto">
        <v-text-field
          v-model="mutableValue.values[2]"
          :disabled="mutableValue.fields[2].disabled || disabled"
          :prefix="mutableValue.fields[2].prefix"
          :suffix="mutableValue.fields[2].suffix"
          :label="mutableValue.fields[2].name + ' - ' + mutableValue.name"
          @change="$emit('change', mutableValue)"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "NumberTextField",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    name: {
      type: String,
      default: ""
    },
    value: {
      type: Object,
      required: true
    },
    prefix: {
      type: String,
      default: ""
    },
    suffix: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    mutableValue() {
      if(!this.value.values){
        this.value.values = []
      }
      return this.value;
    }
  }
};
</script>

<style scoped></style>
