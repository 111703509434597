<template>
  <div>
    <v-datetime-picker
      v-if="timeEnabled"
      date-format="dd.MM.yyyy"
      :time-picker-props="{ format: this.timeFormat }"
      :date-picker-props="{ locale: this.locale }"
      :label="label"
      clear-text="Leeren"
      :disabled="disabled"
      @input="$emit('change', $event.toISOString())"
    ></v-datetime-picker>
    <v-menu
      v-if="!timeEnabled"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
      :disabled="disabled"
    >
      <template #activator="{ on }">
        <v-text-field
          :value="localizedValue"
          :label="label"
          :error-messages="validationError"
          prepend-icon="event"
          readonly
          :disabled="disabled"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        :value="value"
        :locale="locale"
        @input="emit($event)"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "DateTimePicker",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    label: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    timeEnabled: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    validationError: {
      type: String,
      default: null
    }
  },
  data: function() {
    return {
      time: "",
      menu: false,
    };
  },
  computed: {
    localizedValue: function() {
      if (this.value)
        return new Date(this.value).toLocaleDateString(this.locale, {
          year: "numeric",
          month: "2-digit",
          day: "2-digit"
        });

      return "";
    },
    locale: function () {
      if (localStorage.getItem('lang') === "de") {
        return "de-DE"
      } else {
        return "en-EN"
      }
    },
    timeFormat: function () {
      if (localStorage.getItem('lang') === "de") {
        return "24hr"
      } else {
        return "12hr"
      }
    },
    timeUS: function () {
      return this.value + "adasd"
    }
  },
  methods: {
    emit: function(event) {
      this.menu = false;
      this.$emit("change", event);
    }
  }
};
</script>

<style scoped></style>
