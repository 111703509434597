<template>
  <v-row>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          color="primary">
          <v-icon>mdi-filter</v-icon>
        </v-btn>
      </template>

      <v-card>

        <v-card-title>Filter</v-card-title>
        <v-divider/>
        <v-card-text>
          <v-autocomplete
            :items="users"
            prepend-icon="mdi-account"
            v-model="created_by_filter"
            label="Erstellt von"
            item-text="name"
            item-value="id"
            return-object
          ></v-autocomplete>
        </v-card-text>
        <v-card-text>
          <v-autocomplete
            :items="users"
            prepend-icon="mdi-account"
            v-model="updated_by_filter"
            label="Bearbeitet von"
            item-text="name"
            item-value="id"
            return-object
          ></v-autocomplete>
        </v-card-text>

        <v-card-text>
          <v-menu
            v-model="created_at_menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="created_at_filter"
                label="Erstellt am"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="created_at_filter"
              no-title
              @input="created_at_menu = false"
            ></v-date-picker>
          </v-menu>
        </v-card-text>
        <v-card-text>
          <v-menu
            v-model="updated_at_menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="updated_at_filter"
                label="Bearbeitet am"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="updated_at_filter"
              no-title
              @input="updated_at_menu = false"
            ></v-date-picker>
          </v-menu>
        </v-card-text>

        <!--        <v-btn color="primary" @click="toggleSort">-->
        <!--          <v-icon>{{ sortIcons[sortCounter] }}</v-icon>-->
        <!--        </v-btn>-->

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="resetFilters">
            <v-icon left>mdi-filter-remove</v-icon>
            Filter löschen
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="emitFilters"
          >
            <v-icon left>mdi-filter</v-icon>
            Filter anwenden
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </v-row>
</template>

<script>
export default {
  name: "MultiFilter",
  props: {
    searchText: {
      type: String,
      default: ''
    },
    currentPage: {
      type: Number,
      default: 1
    }
  },
  created() {
    this.loadUsers()
  },
  computed: {
    created_start_filter() {
      return this.created_at_filter ? this.created_at_filter + "T00:00:00.000Z" : ""
    },
    created_end_filter() {
      return this.created_at_filter ? this.created_at_filter + "T23:59:59.999Z" : ""
    },
    updated_start_filter() {
      return this.updated_at_filter ? this.updated_at_filter + "T00:00:00.000Z" : ""
    },
    updated_end_filter() {
      return this.updated_at_filter ? this.updated_at_filter + "T23:59:59.999Z" : ""
    }
  },
  data: function () {
    return {
      sortIcons: [
        'mdi-sort-alphabetical-ascending',
        'mdi-sort-alphabetical-descending',
        'mdi-sort-calendar-ascending',
        'mdi-sort-calendar-descending',
        'mdi-sort-clock-ascending',
        'mdi-sort-clock-descending',
      ],
      sortCounter: 0,
      dialog: false,
      created_by_filter: "",
      updated_by_filter: "",
      created_at_filter: "",
      updated_at_filter: "",
      users: [],
      created_at_menu: false,
      updated_at_menu: false
    }
  },
  methods: {
    loadUsers: async function () {
      let users = await this.$userController.getUserList()
      this.users = users.sort(
        (a, b) =>
          a.name.toLowerCase() === b.name.toLowerCase() ? 0 :
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 :
              -1
      )
    },
    toggleSort: function () {
      this.sortCounter++
      if (this.sortCounter >= this.sortIcons.length)
        this.sortCounter = 0
    },
    resetFilters: function () {
      this.dialog = false
      this.created_by_filter = ""
      this.created_at_filter = ""
      this.updated_at_filter = ""
      this.updated_by_filter = ""
      this.$emit('filtersReset')
    },
    emitFilters: function () {
      this.dialog = false
      let filters = {}
      if (this.created_start_filter) filters.created_start = this.created_start_filter
      if (this.created_end_filter) filters.created_end = this.created_end_filter
      if (this.updated_start_filter) filters.updated_start = this.updated_start_filter
      if (this.updated_end_filter) filters.updated_end = this.updated_end_filter
      if (this.created_by_filter) filters.created_by = this.created_by_filter.id
      if (this.updated_by_filter) filters.updated_by = this.updated_by_filter.id
      this.$emit('filtersApply', filters)
    },
    emitSearchText(value) {
      this.$emit('update:search-text', value)
    }
  }
}
</script>

<style scoped>

</style>
