export default {
  Section: {
    type: "Section",
    name: "",
    fields: []
  },
  TextField: {
    type: "Text",
    prefix: "",
    name: "",
    suffix: "",
    value: ""
  },
  Date: {
    type: "Date",
    name: "",
    value: ""
  },
  TextArea: {
    type: "TextArea",
    name: "",
    value: ""
  },
  InfoBox: {
    type: "InfoBox",
    name: "",
    value: ""
  },
  CheckboxList: {
    type: "CheckboxList",
    name: "",
    elements: [
      {
        name: ""
      }
    ]
  },
  RadioButtonList: {
    type: "RadioButtonList",
    name: "",
    elements: [
      {
        name: ""
      }
    ]
  },
  Table: {
    type: "Table",
    name: "",
    elements: {
      headers: [],
      values: []
    }
  },
  Signature: {
    type: "Signature",
    description: "",
    value: {
      signee: "",
      userId: null,
      date: "",
      time: null
    }
  },
  CheckboxText: {
    type: "CheckboxText",
    name: ""
  },
  NumberText: {
    type: "NumberText",
    name: "",
    extraText: false,
    fields: [
      {
        type: "Text",
        name: ""
      },
      {
        type: "Text",
        name: ""
      },
      {
        type: "Text",
        disabled: true,
        name: "Anzahl",
        value: 1
      }
    ]
  },
  Vector: {
    type: "Vector",
    name: "",
    elements: [""],
    prefix: "",
    suffix: ""
  },
  PictureField: {
    type: "Picture",
    name: "",
    value: ""
  },
  Sketch: {
    type: "Sketch"
  }
};
