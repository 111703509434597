import axiosPackage from 'axios'
import {Auth} from 'aws-amplify'

let axios

export default class ExportController {
  prefix = 'forms'
  store = null

  constructor(store) {
    this.store = store
    axios = axiosPackage.create({
      baseURL: this.store.getters.config['api_url']
    })

    axios.interceptors.request.use(async config => {
        await Auth.currentSession().then(res => {
          config.headers['Authorization'] = res.getAccessToken().getJwtToken()
        }).catch(err => console.log(err))
        return config
      }
    )
  }

  async getAllCSV() {
    return axios
      .get('db/export')
      .then(response => {
        return response.data
      })
  }
}
