<template>
  <v-col class="col-md-8" cols="11">
    <!-- From -->
    <div v-if="mutableForm.sections">
      <div v-for="(section, index) in mutableForm.sections">
        <Section
          v-model="mutableForm.sections[index]"
          :anchor="index"
          :changelog-mode="changelogMode"
          :disabled="disabled"
          @change="$emit('change', mutableForm)"
        ></Section>
        <v-divider ></v-divider>
      </div>
    </div>
  </v-col>
</template>

<script>
import Section from "./Section";
import {format, register} from 'timeago.js'
import de from 'timeago.js/lib/lang/de'
import en from 'timeago.js/lib/lang/en_US'

export default {
  name: "ValueEditor",
  components: {Section},
  model: {
    prop: "form",
    event: "change"
  },
  props: {
    form: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    changelogMode: {
      type: Boolean,
      default: false
    },
    showMetaHeader: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      id: null
    };
  },
  computed: {
    mutableForm: function () {
      return this.form;
    }
  },
  methods: {
    renderHeader: function (form) {
      register('de', de)
      register('en', en)
      if (localStorage.getItem('lang') === "de")
        return "Erstellt am " + new Intl.DateTimeFormat('de-DE', {
            dateStyle: 'full',
            timeStyle: 'short'
          }).format(new Date(form.created)) +
          " von " + form.creatorName + " zuletzt bearbeitet " + format(form.updated, 'de') + " von " + form.editorName +
          ". Template ID: " + this.form.template
      else
        return "Created at " + new Intl.DateTimeFormat('en-US', {
            dateStyle: 'full',
            timeStyle: 'short'
          }).format(new Date(form.created)) +
          " von " + form.creatorName + " last changed " + format(form.updated, 'en') + " by " + form.editorName +
          ". Template ID: " + this.form.template
    }
  }
};
</script>

<style scoped></style>
