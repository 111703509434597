<template>
  <div>
    <h3>{{ label }}</h3>
    <p>{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: "InfoBox",
  props: {
    text: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
p {
  white-space: pre-wrap;
}
</style>
