<template>
  <v-pagination
    v-model="page"
    :length="pages"
    prev-icon="mdi-menu-left"
    next-icon="mdi-menu-right"
    @input='emitPageValue'
  ></v-pagination>
</template>

<script>

export default {
  name: "Pagination",
  props: {
    items: {
      type: Array,
      default: []
    },
    searchText: {
      type: String,
      default: ''
    },
    currentPage: {
      type: Number,
      default: 1
    }
  },
  data: function () {
    return {
      page: 1,
    }
  },
  computed: {
    pages: function () {
      if(this.searchText !== '') {
        let filteredList = this.items.filter(
          form =>
            form.name
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) > -1
            ||
            form.creatorName
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) > -1
            ||
            form.editorName
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) > -1
        )
        let size = Math.ceil(filteredList.length / 10)
        if(size > 1)
          return size
        return 1
      }

      return Math.ceil(
        this.items.length / 10
      )
    }
  },
  watch: {
    pages: function () {
      this.emitPageValue(1)
    }
  },
  methods: {
    // https://v2.vuejs.org/v2/guide/components-custom-events.html#sync-Modifier
    // https://stackoverflow.com/a/62009157/6615718
    emitPageValue(value){
      this.$emit('update:current-page', value)
    }
  }
}
</script>

<style scoped>

</style>
