<template>
  <div >
    <h1>{{ $t('headlines.upgradeDocuments')}}</h1>
    <template v-if="loading">
      <v-skeleton-loader
        max-width="300"
        type="card, list-item"
      ></v-skeleton-loader>
    </template>
    <template v-if="!loading">
      <v-expansion-panels
        multiple
      >
        <v-expansion-panel
          v-for="(item,i) in revisions.slice().reverse()"
          :key="i"
        >
          <v-expansion-panel-header>
              <div>
                <div>{{ item.selectString }}</div>
                <div class="subtitle">{{ 'Angelegt von ' + $formController.resolveUserNameById(item.editor) + ' am ' + formatDate(item.updated) }}</div>
              </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="subtitle">Versionskommentar {{ item.comment ? item.comment : "-" }}</p>
            <v-list-item v-for="(doc, index) in openDocuments[item.templateRevision]" :key="index">
              <v-list-item-content>
                <v-list-item-title v-text="doc.NAME['S']"></v-list-item-title>
              </v-list-item-content>
              <div class="icon-row">
                <template v-if="$userController.isAdmin() && revisions.length > 1">
                  <UpgradeTemplateRevisionDialog
                    :revisions="revisions"
                    :formID="doc.ID['S']"
                    @fetch="fetch()"
                  ></UpgradeTemplateRevisionDialog>
                </template>

                <v-icon @click="goToDocument(doc.ID['S'])">mdi-eye-outline</v-icon>
              </div>
            </v-list-item>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
  </div>
</template>

<style scoped>
.subtitle {
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.6);
}
</style>

<script>
import UpgradeTemplateRevisionDialog from "../dialog/UpgradeTemplateRevisionDialog.vue";

export default {
  name: "UpgradeDocumentList",
  components: {UpgradeTemplateRevisionDialog},
  data: function () {
    return {
      revisions: [],
      openDocuments: {},
      loading: true,
    }
  },
  methods: {
    async fetch() {
      this.loading = true

      let controller = this.$templateController

      const response = await controller.getRevisions(this.$route.params.id);
      this.revisions = response.data;

      for (const revision of this.revisions) {
        const res = await controller.checkRevisionDocuments(revision.templateRevision);
        this.openDocuments[revision.templateRevision] = res.Items;

        revision.selectString = revision.name + ' - Version ' + revision.revisionNumber;
      }

      this.loading = false;
      console.log(this.openDocuments);
    },
    goToDocument(docID) {
      this.$router.push('/forms/' + docID)
    },
    goToRevision(revisionItem) {
      this.$router.push('forms/' + revisionItem.revisionNumber)
    },
    formatDate(dateString) {
      const date = new Date(dateString);

      const day = String(date.getUTCDate()).padStart(2, '0');
      const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Monate sind nullbasiert
      const year = date.getUTCFullYear();
      const hours = String(date.getUTCHours()).padStart(2, '0');
      const minutes = String(date.getUTCMinutes()).padStart(2, '0');

      return `${day}.${month}.${year} - ${hours}:${minutes}`;
    },
  },
  mounted() {
    this.fetch()
  }

};
</script>

