<template>
  <v-row v-if="loading" justify="center">
    <v-col cols="12" class="text-center">
      <v-progress-circular indeterminate color="primary" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "LoadingSpinner",
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped></style>
