import { render, staticRenderFns } from "./TemplateList.vue?vue&type=template&id=1d17b706&scoped=true"
import script from "./TemplateList.vue?vue&type=script&lang=js"
export * from "./TemplateList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d17b706",
  null
  
)

export default component.exports