<template>
  <v-data-table :headers="headers" :items="mutableValues" :caption="caption">
    <template #top>
      <v-toolbar flat color="white">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="mb-2"
          :disabled="disabled"
          @click="newItem"
        >
          {{ $t('fields.table.newEntry') }}
        </v-btn>

        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    v-for="(header, index) in headers"
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-if="index !== headers.length - 1"
                      v-model="currentItem[header.value]"
                      :label="header.text"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                {{ currentIndex === -1 ? $t('actions.cancel') : "OK" }}
              </v-btn>
              <v-btn
                v-if="currentIndex === -1"
                color="blue darken-1"
                text
                @click="save"
                >{{ $t('actions.save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template #[`item.actions`]="{ item }">
      <v-icon small class="mr-2" :disabled="disabled" @click="editItem(item)">
        mdi-pencil
      </v-icon>

      <delete-table-entry-dialog @delete="deleteItem(item)">
        <template #default="{ on }">
          <v-icon small :disabled="disabled" v-on="on">
            mdi-delete
          </v-icon>
        </template>
      </delete-table-entry-dialog>
    </template>

    <template #no-data>
      <label>{{ $t('fields.table.noData') }}</label>
    </template>
  </v-data-table>
</template>

<script>
import DeleteTableEntryDialog from "./dialog/DeleteTableItemDialog";

export default {
  name: "Table",
  components: { DeleteTableEntryDialog },
  model: {
    prop: "values",
    event: "change"
  },
  props: {
    headers: {
      type: Array,
      required: true
    },
    values: {
      type: Array,
      default: () => []
    },
    caption: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  // props: ["headers", "values", "caption", "disabled"],
  data: function() {
    return {
      dialog: false,
      deleteItemDialog: false,
      currentItem: {},
      currentIndex: -1
    };
  },
  computed: {
    formTitle: function() {
      return this.currentIndex === -1 ? this.$t('fields.table.newElement') : this.$t('fields.table.editElement');
    },
    mutableValues: function() {
      return this.values;
    }
  },
  created() {
    console.log(this.mutableValues);
    console.log(this.values);
    console.log(this.headers);
    console.log(this.disabled);
  },
  methods: {
    save: function() {
      if (this.currentIndex === -1) this.mutableValues.push(this.currentItem);
      else this.mutableValues[this.currentIndex] = this.currentItem;

      this.$emit("change", this.mutableValues);
      this.dialog = false;
    },
    newItem: function() {
      this.currentIndex = -1;
      this.currentItem = {};
      this.dialog = true;
      this.$emit("change", this.mutableValues);
    },
    editItem: function(item) {
      this.currentItem = item;
      this.currentIndex = this.mutableValues.indexOf(item);
      this.dialog = true;
    },
    deleteItem: function(item) {
      this.currentIndex = this.mutableValues.indexOf(item);
      this.mutableValues.splice(this.currentIndex, 1);
      this.$emit("change", this.mutableValues);
    }
  }
};
</script>

<style>
caption {
  text-align: left;
  font-weight: bold;
  margin-bottom: 5px;
}
</style>
