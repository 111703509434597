<template>
  <v-container>
    <v-bottom-navigation
      v-if="saving"
      app
      height="200">
      <v-progress-linear
        class="mb-8"
        color="cyan"
        indeterminate>
      </v-progress-linear>
    </v-bottom-navigation>

    <template>
      <v-row justify="center">
        <v-dialog v-model="showNoSaveConfirm" persistent max-width="500px">
          <v-card>
            <v-card-title class="headline">
              {{ $t('actions.unsavedChanges') }}
            </v-card-title>
            <v-card-text>
              {{ $t('actions.unsavedChangesDescription') }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="cancelRoute">
                {{ $t('actions.cancel') }}
              </v-btn>
              <v-btn text color="error" @click="confirmRoute">
                {{ $t('actions.continueWithOutSave') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <template v-if="loading">
      <div>
        <v-sheet class="ma-9">
          <v-skeleton-loader
            max-width="300"
            type="card, list-item"
          ></v-skeleton-loader>
        </v-sheet>
      </div>
    </template>
    <template v-else>
      {{$store.commit("setMetadata",form.name)}}
      <v-row justify="center">
        <value-editor
          v-if="$store.state.eye && form"
          v-model="form"
          @change="
          () => (!saveEnabled ? $store.commit('setSaveEnabled', true) : '')
        "
        />

        <structure-editor v-else-if="!$store.state.eye && form" v-model="form" />
      </v-row>
    </template>
  </v-container>
</template>

<script>
import StructureEditor from "../components/template/StructureEditor";
import ValueEditor from "../components/form/ValueEditor";
import { mapGetters } from "vuex";
import pdfExport from '@/components/export/pdf-export';

export default {
  name: "Form",
  components: {
    ValueEditor,
    StructureEditor
  },
  computed: {
    ...mapGetters([
      "navigationContext",
      "config",
      "saveEnabled",
      "saveEvent",
      "additionalNavigationEntries",
      "printIntended",
      "requiredFieldsDetail"
    ])
  },
  watch: {
    requiredFieldsDetail(value) {
      if (value) this.$router.push(`${this.$route.params.id}/required-fields-detail`);
    },
    saveEvent(value) {
      if (value) this.save();
    },
    printIntended(value) {
      if (value) {
        this.$store.commit("resetPrint");
        this.pdf();
      }
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path === from.path) {
      next();
      return;
    }
    if (!this.saveEnabled) next();
    if (this.saveEnabled && this.noSaveConfirmed) next();
    this.requestLeaveConfirmation(to);
    next(false);
  },
  beforeRouteLeave(to, from, next) {
    let shouldLeave = false;

    if (to.path === from.path) shouldLeave = true;
    if (!this.saveEnabled) shouldLeave = true;
    if (this.saveEnabled && this.noSaveConfirmed) shouldLeave = true;

    if (shouldLeave) {
      this.$store.commit("clearAdditionalNavigationEntries");
    } else {
      this.requestLeaveConfirmation(to);
    }
    next(shouldLeave);
  },
  data: function() {
    return {
      edit: false,
      form: null,
      showNoSaveConfirm: false,
      noSaveConfirmed: false,
      targetRoute: "/",
      saving: false,
      loading: true
    };
  },
  mounted() {
    this.$store.commit("setEye", true);
    this.$store.commit("sync");
    this.$store.commit("setSaveVisible", true);
    this.$store.commit("setRequiredFieldsDetailEnabled", true);
    this.fetch(this.$route.params.id)
  },
  destroyed() {
    this.$store.commit("setMetadata", null);
    this.$store.commit("setRequiredFieldsDetailEnabled", false);
  },
  methods: {
    updateNavigationEntries: function(form) {
      let entries = [];
      for (let i = 0; i < form.sections.length; i++) {
        let section = form.sections[i];
        entries.push({ name: section.name, href: "#" + i });

        for (let j = 0; j < section.fields; j++) {
          let field = section.fields[j];
          if (field.type === "section")
            entries.push({ name: field.name, href: "#" + i + "-" + j });
        }
      }

      this.$store.commit("setAdditionalNavigationEntries", entries);
    },
    fetch: function(id) {
      this.$formController.getDocument(id)
        .then(res => {
          this.form = res.data;
          console.log(res.data)
          this.updateNavigationEntries(this.form)
          this.loading = false
          return res.data;
        })
        .finally( () => { this.loading = false } )
    },
    pdf: function() {
      pdfExport(this.form);
    },
    save: function() {
      return (
        this.$formController.updateDocument(this.form)
          // refetch to prevent conflicts on next save
          .then(() => this.fetch(this.$route.params.id))
          // generate success message
          .then(() => {
            this.$store.commit("setSaveEnabled", false);
            this.$store.commit("setSaveEvent", false);
            return {
              active: true,
              color: "success",
              message: "Erfolgreich gespeichert."
            };
          })
          // generate error message // todo better description
          .catch(err => {
            this.$store.commit("setSaveEvent", false);
            return {
              active: true,
              color: "error",
              message: err
            };
          })
          .then(alert => this.$store.commit("setSyncAlert", alert))
      ); // send alert
    },
    requestLeaveConfirmation: function(to) {
      this.showNoSaveConfirm = true;
      this.noSaveConfirmed = false;
      this.targetRoute = to.fullPath;
    },
    cancelRoute: function() {
      this.noSaveConfirmed = false;
      this.showNoSaveConfirm = false;
    },
    confirmRoute: function() {
      this.noSaveConfirmed = true;
      this.showNoSaveConfirm = false;
      this.$router.push(this.targetRoute);
    }
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
