<template>
  <div class="pb-10">
    <v-row>
      {{ label }}
      <div style="visibility: collapse">.</div>
    </v-row>
    <v-row v-for="element in elements" :key="element.name">
      <v-checkbox
        v-model="value"
        :value="element.name"
        :label="element.name"
        hide-details
        :error-messages="validationError"
        :disabled="disabled"
        @change="$emit('change', value)"
      />
    </v-row>
  </div>
</template>

<script>
export default {
  name: "CheckboxList",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    label: {
      type: String,
      default: ""
    },
    elements: {
      type: Array,
      required: true
    },
    value: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    validationError: {
      type: String,
      default: null
    }
  },
  computed: {
    mutableValue: function() {
      return this.value;
    }
  }
};
</script>

<style scoped></style>
