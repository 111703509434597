<template>

  <v-container v-if="form" class="container">
    <div :class="formStyleClass">

      <FormChild
        v-if="!loading"
        :fetch="fetch"
        :form="form"
        :formStyleClass="formStyleClass"
        :showMetaHeader="showMetaHeader">
      </FormChild>
      <v-sheet
        v-if="loading"
        class="ma-9"
      >
        <v-skeleton-loader
          class="mx-auto"
          max-width="300"
          type="card, list-item"
        ></v-skeleton-loader>
      </v-sheet>
    </div>
    <div class="right-component">
      <v-progress-linear
        v-if="validating"
        indeterminate
        color="cyan">
      </v-progress-linear>
      <div v-if="!validating" class="content" justify="center">
        <strong v-if="conflicts.length > 0" class="mx-8"> {{ $t('document.missingValueDemand') }} </strong>
        <strong v-else> {{ $t('document.noMissingValues') }} </strong>
        <div v-for="(section, index) in conflicts" :key="index" class="mx-8" justify="center">
          <strong align="center"> Section: {{ section.section.name }} </strong>
          <v-row v-for="(field, subindex) in section.fields" :key="subindex" class="ma-3">
            <v-card class="mb-4" width="100%" align="center" justify="center">
              <v-card-title>
              <span class="ml-2">
                  {{ field.label }}
                </span>
              </v-card-title>
              <v-card-text>
                <div>

                  <strong> {{ $t('document.missingValue') }} </strong> - Type: {{field.type}}
                  <v-btn class="ml-4" @click="scrollToField(field.id)" icon>
                    <v-icon>mdi-eye-arrow-left-outline</v-icon>
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-row>
        </div>
      </div>
    </div>
  </v-container>
</template>

<style scoped>
.container {
  display: flex;
  width: 100vw;
  height: 100vh;
}

.half-screen {
  width: 50%;
  height: 100%;
}

.right-component {
  display: flex;
  height: 100%;
  background-color: #e0e0e0; /* optionale Hintergrundfarbe */
  overflow-y: auto; /* ermöglicht vertikales Scrollen */
}
</style>

<script>
import StructureEditor from "../components/template/StructureEditor";
import ValueEditor from "../components/form/ValueEditor";
import FormChangelog from "@/components/form/FormChangelog.vue";
import FormChild from "../components/form/Form";
import {mapGetters} from "vuex";

export default {
  name: "RequiredFieldsDetail",
  components: {
    ValueEditor,
    StructureEditor,
    FormChangelog,
    FormChild
  },
  computed: {
    ...mapGetters([
      "config",
      "saveCompleted",
      "saveEvent",
      "requiredFieldsDetail"
    ]),
  },
  watch: {
    async saveCompleted(value) {
      if (value) {
        await this.fetch(this.$route.params.id);
        await this.validateForm(this.$route.params.id);
        this.$store.commit("setSaveEventCompleted", false);
        this.loading = false;
      }
    },
    requiredFieldsDetail(value) {
      if (!value) this.$router.push(`/forms/${this.$route.params.id}`);
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path === from.path) {
      next();
      return;
    }
    if (!this.saveEnabled) next();
    if (this.saveEnabled && this.noSaveConfirmed) next();
    this.requestLeaveConfirmation(to);
    next(false);
  },
  beforeRouteLeave(to, from, next) {
    let shouldLeave = false;

    if (to.path === from.path) shouldLeave = true;
    if (!this.saveEnabled) shouldLeave = true;
    if (this.saveEnabled && this.noSaveConfirmed) shouldLeave = true;

    if (shouldLeave) {
      this.$store.commit("clearAdditionalNavigationEntries");
    } else {
      this.requestLeaveConfirmation(to);
    }
    next(shouldLeave);
  },
  data: function () {
    return {
      edit: false,
      form: null,
      showNoSaveConfirm: false,
      noSaveConfirmed: false,
      targetRoute: "/",
      loading: false,
      validating: false,
      conflicts: [],
      formStyleClass: "half-screen",
      showMetaHeader: true,
    };
  },
  mounted() {
    this.$store.commit("setEye", true);
    this.$store.commit("sync");
    this.$store.commit("setSaveVisible", true);
    this.$store.commit("setRequiredFieldsDetailEnabled", true);
    this.fetch(this.$route.params.id);
    this.validateForm(this.$route.params.id);
  },
  destroyed() {
    this.$store.commit("setMetadata", null);
    this.$store.commit("setRevisionMode", false);
  },
  methods: {
    scrollToField(fieldId) {
      const fieldElement = document.getElementById(fieldId);
      if (fieldElement) {
        fieldElement.scrollIntoView({behavior: 'smooth', block: 'center'});
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);

      const day = String(date.getUTCDate()).padStart(2, '0');
      const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Monate sind nullbasiert
      const year = date.getUTCFullYear();
      const hours = String(date.getUTCHours()).padStart(2, '0');
      const minutes = String(date.getUTCMinutes()).padStart(2, '0');

      return `${day}.${month}.${year} - ${hours}:${minutes}`;
    },
    fetch: function (id) {
      this.loading = true;
      this.$formController.getDocument(id)
        .then(res => {
          this.form = res.data;
          console.log(this.form);
        })
      this.loading = false;
    },
    validateForm: function (id) {
      this.validating = true;
      this.$formController.validateDocument(id)
        .then(res => {
          this.conflicts = []
          res.data.forEach(section => {
            if (section.fields.length > 0) {
              this.conflicts.push(section);
            }
          })
          this.validating = false;
          console.log(this.conflicts);
        })
    },
    cancelRoute: function () {
      this.noSaveConfirmed = false;
      this.showNoSaveConfirm = false;
    },
    confirmRoute: function () {
      this.noSaveConfirmed = true;
      this.showNoSaveConfirm = false;
      this.$router.push(this.targetRoute);
    }
  }
}
;
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
