<template>
  <div>
    <v-row>
      <v-col>
        <h3>Tabelle</h3>
      </v-col>
      <v-col cols="auto">
        <v-btn
          class="mx-1"
          fab
          dark
          x-small
          color="secondary"
          @click="$emit('delete')"
        >
          <v-icon dark>mdi-trash-can</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-col cols="12">
          <v-text-field
            v-model="mutableValue.name"
            label="Tabellenname"
            @change="$emit('change', mutableValue)"
          />
        </v-col>
        <v-row v-for="(element, index) in mutableValue.elements.headers">
          <v-text-field
            v-if="index !== value.elements.headers.length - 1"
            v-model="element.text"
            prepend-icon="mdi-table-column"
            label="Spaltenname"
            append-outer-icon="mdi-table-column-remove"
            @change="$emit('change', mutableValue)"
            @click:append-outer="deleteItem(index)"
          />
        </v-row>
        <v-btn color="primary" fab x-small @click="addItem()">
          <v-icon dark>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "TableBuilder",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  computed: {
    mutableValue: function() {
      return this.value;
    }
  },
  methods: {
    addItem: function() {
      this.mutableValue.elements.headers.pop();
      this.mutableValue.elements.headers.push({
        text: "",
        value: "col" + this.mutableValue.elements.headers.length
      });
      this.mutableValue.elements.headers.push({
        text: "Aktionen",
        value: "actions",
        sortable: "false"
      });
      this.$emit("change", this.mutableValue);
    },
    deleteItem: function(index) {
      this.mutableValue.elements.headers.splice(index, 1);
      this.$emit("change", this.mutableValue);
    }
  }
};
</script>

<style scoped></style>
