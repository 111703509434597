<template>
  <v-dialog v-model="dialog" width="500">
    <template #activator="{ on }">
      <v-list-item v-on="on" @click="validateForm(form.id)">
        <v-list-item-icon>
          <v-btn icon >
            <v-icon>mdi-file-document-plus-outline</v-icon>
          </v-btn>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t('document.newRevision')}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-card>
      <v-form>
        <v-card-title>Neue Revision erstellen</v-card-title>
        <v-card-subtitle>Hiermit wird das aktuelle Dokument <b>{{ form.name }}</b> auf read-only gesetzt und eine neue
          Revision erstellt.
        </v-card-subtitle>
        <v-col>
          <v-textarea
            v-model="revComment"
            label="Kommentar für neue Revision"
          ></v-textarea>
        </v-col>
        <v-alert v-if="showAlert" :type="alertType">
          {{ $t(alertKey) }}
          <br>
          <br>
          <v-btn outlined @click="showRequiredFields" v-if="!formValid">Hier klicken zum anzeigen</v-btn>
          <v-checkbox v-model="ignoreRequiredFields" v-if="!formValid" label="Dennoch anlegen"
          ></v-checkbox>
        </v-alert>

        <v-card-actions>
          <v-spacer/>
          <v-btn text @click="dialog = false">Close</v-btn>
          <v-btn :disabled="!formValid && !ignoreRequiredFields" :loading="loading" color="primary" text
                 @click="createRevision()">
            Revision erstellen
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'CreateNewRevisionDialog',
  components: {},
  props: {
    type: {
      type: String,
      required: true
    },
    form: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      loading: false,
      formValid: false,
      currentForm: this.form,
      revComment: "",
      conflicts: [],
      alertType: 'success',
      showAlert: false,
      alertKey: '',
      ignoreRequiredFields: false,
    }
  },
  methods: {
    showRequiredFields: function () {
      this.$router.push(`/forms/${this.form.id}/required-fields-detail`);
    },
    createRevision: async function () {
      this.loading = true
      let res
      if (this.type === "FORM") {
        res = await this.$formController.createRevision(this.form, this.revComment)
      } else {
        res = await this.$templateController.createRevision(this.form, this.revComment)
      }
      this.loading = false
      if (res) {
        this.dialog = false
        if (this.type === "FORM") {
          await this.$router.push(`/forms/${this.form.id}`);
        } else {
          await this.$router.push(`/templates/${this.form.id}`);
        }
      } else {
        //todo error here
      }

    },
    validateForm: async function (id) {
      if (this.type === "TEMPLATE") {
        this.formValid = true;
        this.alertType = "success"
        this.alertKey = 'document.noMissingValues'
        this.showAlert = false;
        this.loading = false
        return
      }

      this.loading = true
      await this.$formController.validateDocument(id)
        .then(res => {
          this.conflicts = []
          res.data.forEach(section => {
            if (section.fields.length > 0) {
              this.conflicts.push(section);
            }
          })
        })
      console.log(this.conflicts)
      if (this.conflicts.length === 0) {
        this.formValid = true;
        this.alertType = "success"
        this.alertKey = 'document.noMissingValues'
      } else {
        this.formValid = false;
        this.alertType = "error"
        this.alertKey = 'document.missingValueDemand'
      }
      this.showAlert = true;
      this.loading = false
    },
  }
}
</script>
