<template>
  <div>
    <v-row>
      <v-col>
        <h3>Ja / Nein Textfeld</h3>
      </v-col>
      <v-col cols="auto">
        <v-btn
          class="mx-1"
          fab
          dark
          x-small
          color="secondary"
          @click="$emit('delete')"
        >
          <v-icon dark>mdi-trash-can</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="mutableValue.name"
          prepend-icon="check_box_outline_blank"
          label="Beschreibung"
          @change="$emit('change', mutableValue)"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "CheckboxTextBuilder",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  computed: {
    mutableValue: function() {
      return this.value;
    }
  }
};
</script>

<style scoped></style>
