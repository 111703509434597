<template>
  <div>
    <v-img :src="link"></v-img>
    {{ name }}
  </div>
</template>

<script>
export default {
  name: "PictureField",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    name: {
      type: String,
      default: ""
    },
    link: {
      type: String,
      default: ""
    }
  }
};
</script>

<style scoped></style>
