<template>
  <v-card :disabled="formLoading" class="mx-auto">
    <v-img dark height="50px" style="background-color: #1976D2">
    </v-img>

    <v-list>
      <v-list-item @click="">

        <v-list-item-content v-if="!formLoading">
          <v-list-item-title class="text-h4">{{form.name}}</v-list-item-title>
          <v-list-item-subtitle>{{ $t('document.created') }} {{formatDate(form.created)}}</v-list-item-subtitle>
          <v-list-item-subtitle>{{ $t('document.lastEdit') }} {{formatDate(form.updated)}}</v-list-item-subtitle>
        </v-list-item-content>

        <v-progress-linear
          v-if="formLoading"
          indeterminate
          color="cyan">
        </v-progress-linear>

      </v-list-item>

      <v-divider></v-divider>
      <v-divider></v-divider>
      <v-divider></v-divider>
      <v-divider></v-divider>

      <v-list-item @click="forwardTo()">
        <v-list-item-icon>
          <v-btn icon>
            <v-icon>edit</v-icon>
          </v-btn>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('document.edit') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <rename-document-dialog :document="form" type="template" @rename="fetch($route.params.id)"/>

      <v-divider></v-divider>

      <v-list-item v-if="!form" :disabled="formLoading">
        <v-list-item-icon>
          <v-btn icon>
            <v-icon>mdi-file-document-plus-outline</v-icon>
          </v-btn>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('document.newRevision') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item disabled @click="">
        <v-list-item-icon>
          <v-btn icon>
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('document.delete') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<style scoped>

</style>

<script>

import CopyFormDialog from "../../form/dialog/CopyFormDialog.vue";
import CreateNewRevisionDialog from "../../form/dialog/CreateNewRevision.vue";
import DeleteFormDialog from "../../form/dialog/DeleteFormDialog.vue";
import RenameDocumentDialog from '@/components/form/dialog/RenameDocumentDialog'

export default {
  name: "TemplateDocumentCard",
  components: {RenameDocumentDialog, DeleteFormDialog, CreateNewRevisionDialog, CopyFormDialog},
  data: function () {
    return {
      formLoading: true,
      form: {},
    }
  },
  mounted() {
    this.fetch(this.$route.params.id)
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);

      const day = String(date.getUTCDate()).padStart(2, '0');
      const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Monate sind nullbasiert
      const year = date.getUTCFullYear();
      const hours = String(date.getUTCHours()).padStart(2, '0');
      const minutes = String(date.getUTCMinutes()).padStart(2, '0');

      return `${day}.${month}.${year} - ${hours}:${minutes}`;
    },
    forwardTo(type = 'templates', destination, id = this.$route.params.id) {
      if (destination) {
        this.$router.push(`/${type}/${id}/${destination}`);
      } else {
        this.$router.push(`/${type}/${id}`);
      }

    },
    fetch: function (id) {
      this.$templateController.getDocument(id)
        .then(res => {
          this.form = res.data;
          this.formLoading = false;
        })
    }
  }

};
</script>

