<template>
  <v-dialog v-model="dialog" width="500">
    <template #activator="{ on }">
      <v-btn disabled icon v-on="on">
        <v-icon>mdi-trash-can</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>{{ $t('document.delete') + ': ' + template.name}}</v-card-title>
      <v-card-text>
        {{ $t('document.deleteTemplate', { name: template.name }) }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false">{{ $t('actions.cancel') }}</v-btn>
        <v-btn text color="error" :loading="loading" @click="deleteTemplate()">{{ $t('actions.delete') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DeleteTemplateDialog",
  props: {
    template: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      loading: false
    };
  },
  methods: {
    deleteTemplate: function () {
      this.loading = true
      this.$templateController.deleteDocument(this.template.id)
        .then( () => {
          this.$emit('delete', this.template)
          this.dialog = false
        } )
        .finally(() => this.loading = false)
    }
  }
};
</script>

<style scoped></style>
