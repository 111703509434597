<template>
  <v-dialog v-model="dialog" width="500">
    <template #activator="{ on }">
      <v-btn icon v-on="on">
        <v-icon>mdi-content-duplicate</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-form v-model="isValid">
        <v-card-title>{{ $t('document.duplicate') }}</v-card-title>
        <v-col>
          <v-text-field
            v-model="newId"
            :rules="[rules.required, rules.duplicates]"
            :label="$t('document.new-name')"
          ></v-text-field>
        </v-col>
        <v-card-actions>
          <v-spacer/>
          <v-btn text @click="dialog = false">{{ $t('actions.cancel') }}</v-btn>
          <v-btn :disabled="!isValid" text color="primary" :loading="loading" @click="copyForm">{{ $t('actions.duplicate') }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CopyFormDialog',
  props: {
    form: {
      type: Object,
      required: true
    },
    forms: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      dialog: false,
      isValid: false,
      loading: false,
      newId: this.form.name + this.$t("template.copyPostfix"),
      existingIds: [],
      rules: {
        required: value => !!value || this.$t("validation.required"),
        duplicates: value =>
          !this.existingIds.includes(value) || this.$t("template.existing")
      }
    }
  },
  computed: {
    ...mapGetters(['config']),
  },
  mounted () {
    this.forms.forEach(form => this.existingIds.push(form.name))
  },
  methods: {
    copyForm: async function () {
      this.isValid = false
      this.loading = true
      this.$formController.getDocument(this.form.id)
        .then(res => (res.data))
        .then(this.clearSignatures)
        .then(async form =>
          await this.$formController.createDocument({
            'name': this.newId,
            template: form.template,
            sections: form.sections,
            templateRevision: form.templateRevision
          })
            .then(resp => {
              this.$router.push('/forms/' + resp.data.id)
            })
        )
        .finally( () => this.loading = false)
    },
    //todo move to api-client or even backend api
    clearSignatures: function (form) {
      form.sections.forEach(section => {
        this.iterateAllFields(section, field => {
          if (field.type === 'Signature') field.value = {}
        })
      })
      return form
    },
    iterateAllFields: function (section, func) {
      section.fields.forEach(field => {
        if (field.type === 'Section') this.iterateAllFields(field, func)
        func(field)
      })
    },
  }
}
</script>

<style scoped></style>
