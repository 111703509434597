<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <template>
          <v-skeleton-loader
            v-if="loading"
            class="mx-auto"
            max-width="300"
            type="card"
          ></v-skeleton-loader>
          <v-card class="mx-auto" v-else tile>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="title">{{ $t('settings.grpMgmt.title') }}</v-list-item-title>
                <v-list-item-subtitle>{{ $t('settings.grpMgmt.desc') }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-card-actions v-if="false">
              <v-btn color="primary">{{ $t('settings.grpMgmt.action') }}</v-btn>
            </v-card-actions>

            <v-card-actions>

              <v-simple-table>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-left">
                      {{ $t('settings.grpMgmt.name') }}
                    </th>
                    <th class="text-left">
                      {{ $t('actions.edit') }}
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                    v-for="group in groupList"
                    :key="group.GroupName"
                  >
                    <td>{{ group.GroupName }}</td>
                    <td>
                      <v-icon small class="mr-2" @click="" disabled>
                        mdi-pencil
                      </v-icon>
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>

            </v-card-actions>
          </v-card>
        </template>
      </v-col>
    </v-row>

    <v-row v-for="group in groupList">
      <v-col cols="12" >
        <GroupList
          :loading="false"
          :group="group"
          :userInGroupMap="userInGroupMap"
          :selectedUser="selectedUser"
          :headers="headers"
          @update-list="getGroups()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import GroupList from "@/components/settings/groups/GroupList.vue";
export default {
  name: "GroupListView",
  components: {
    GroupList
  },
  computed: {
    ...mapGetters(["getUserList"]),
  },
  data() {
    return {
      groupList: [],
      userInGroupMap: {},
      selectedUser: {},
      loading: false,
      headers: [
        {
          text: this.$t('login.username'),
          value: 'Username'
        },
        {
          text: 'Status',
          value: 'UserStatus'
        },
        {
          text: 'Action',
          value: 'Enabled'
        }
      ]
    };
  },
  methods: {
    getGroups: async function () {
      this.groupList = await this.$formController.getGroupList()
        .then(async groups => {
          for (const groupsKey in groups) {
            this.userInGroupMap[groups[groupsKey].GroupName] = await this.$formController.getGroup(groups[groupsKey].GroupName)
            this.selectedUser[groups[groupsKey].GroupName] = "";
          }
          return groups;
        })
        .catch(() => this.$store.commit("setSyncAlertError"));
    },
  },
  mounted() {
    this.getGroups()
  }
};
</script>
