<template>
  <v-container fluid>
    <v-row>
      <!-- Info Card -->
      <v-col cols="12" md="4">
        <TemplateDocumentCard/>
      </v-col>

      <!-- Revision Table -->
      <v-col cols="12" md="8">
        <UpgradeDocumentList />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RevisionTable from "../components/form/info/RevisionTable.vue";
import DocumentCard from "../components/form/info/DocumentCard.vue";
import TemplateDocumentCard from "../components/template/info/TemplateDocumentCard.vue";
import UpgradeDocumentList from "@/components/template/info/UpgradeDocumentList.vue";

export default {
  name: "TemplateInfoPage",
  components: {TemplateDocumentCard, DocumentCard, RevisionTable, UpgradeDocumentList},
};
</script>

<style scoped>
</style>
