<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <template>
          <v-skeleton-loader
            v-if="loading"
            class="mx-auto"
            max-width="300"
            type="card"
          ></v-skeleton-loader>
          <v-card v-else class="mx-auto" tile>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="title">{{ $t('settings.userMgmt.title') }}</v-list-item-title>
                <v-list-item-subtitle>{{ $t('settings.userMgmt.desc') }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-col>
              <v-card-actions>
                <template>
                  <v-row>
                    <v-col>
                      <v-dialog
                        v-model="dialog"
                        max-width="600px"
                        persistent
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ $t('settings.userMgmt.action') }}
                          </v-btn>
                        </template>
                        <v-card>
                          <v-form v-model="isFormValid" ref="userCreationForm">
                          <v-card-title>
                            <span class="text-h5">Create User</span>
                          </v-card-title>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="user.username"
                                    hint="Username equals email address"
                                    label="Username*"
                                    persistent-hint
                                    required
                                    :rules="rules"
                                  ></v-text-field>
                                </v-col>
                                <v-col
                                  cols="12"
                                  md="5"
                                  sm="6"
                                >
                                  <v-text-field
                                    v-model="user.given_name"
                                    label="given name*"
                                    required
                                    :rules="rules"
                                  ></v-text-field>
                                </v-col>
                                <v-col
                                  cols="12"
                                  md="5"
                                  sm="6"
                                >
                                  <v-text-field
                                    v-model="user.family_name"
                                    label="family name*"
                                    required
                                    :rules="rules"
                                  ></v-text-field>
                                </v-col>
                                <v-col
                                  cols="12"
                                  md="2"
                                  sm="6"
                                >
                                  <v-autocomplete
                                    v-model="user.locale"
                                    :items="['de-DE', 'en-US']"
                                    label="Locale"
                                    :rules="rules"
                                  ></v-autocomplete>
                                </v-col>
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="user.password"
                                    label="Password*"
                                    required
                                    type="password"
                                    :rules="passwordRules"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <small>*indicates required field</small>
                            </v-container>

                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="dialog = false"
                            >
                              Close
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              :disabled="!isFormValid"
                              @click="createUser()"
                            >
                              Create
                            </v-btn>
                          </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </template>
              </v-card-actions>

              <v-text-field
                v-model="searchText"
                clearable
                dense
                hide-details
                prepend-inner-icon="mdi-magnify"

                solo
                @click:clear="searchText = ''"
              ></v-text-field>
            </v-col>


            <v-card-actions>
              <template>
                <v-container>
                  <v-data-table
                    :headers="headers"
                    :items="filterResults(searchText)"
                    :items-per-page="10"
                    :loading="loadingList"
                  >
                    <template v-slot:item.status="{ item }">
                      <v-chip
                        :color="getColor(item.status)"
                        dark
                      >
                        {{ item.status }}
                      </v-chip>
                    </template>
                    <template v-slot:item.id="{ item }">
                      <v-list-item-action>
                        <edit-user-dialog :user="item" :policies="policies"  @password-changed="getUserList()" />
                      </v-list-item-action>

                    </template>
                  </v-data-table>
                </v-container>
              </template>

            </v-card-actions>
          </v-card>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EditUserDialog from '../components/settings/user/dialog/EditUserDialog'

export default {
  name: "UserListView",
  components: {
    EditUserDialog
  },
  data() {
    return {
      userList: [],
      policies: [],
      dialog: false,
      loading: false,
      loadingList: false,
      isFormValid: false,
      user: {
        username: "",
        given_name: "",
        family_name: "",
        locale: "",
        password: "",
        permanent: false
      },
      headers: [
        {
          text: 'Username',
          value: 'username'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'locale',
          value: 'locale'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: 'Action',
          value: 'id'
        }
      ],
      searchText: "",
      rules: [
        value => !!value || this.t$("validation.required"),
      ],
      passwordRules: [
        value => !!value || this.t$("validation.required"),
        value => (value || '').length >= 8 || 'Min 8 characters',
      ],
    };
  },
  methods: {
    createUser: async function () {
      this.dialog = false
      await this.$userController.createUser(this.user)
        .then(() => this.$refs.userCreationForm.reset())
        .catch(() => this.$store.commit("setSyncAlertError"))
      await this.getUserList()
    },
    getUserList: async function () {
      this.loadingList = true;
      this.userList = await this.$userController.getUserList()
        .catch(() => this.$store.commit("setSyncAlertError")
      );
      await this.$store.commit("setUserList", this.userList)
      this.loadingList = false;
    },
    getColor: function (userStatus) {
      return userStatus === "CONFIRMED" ? "green" : "red"
    },
    filterResults(searchText) {
      const lowerSearchText = searchText.toLowerCase();
      return this.userList.filter(user => {
        return Object.values(user).some(value =>
          typeof value === "string" && value.toLowerCase().includes(lowerSearchText)
        );
      });
    },
    async getPasswordPolicy() {
      this.policies = await this.$userController.getPasswordPolicy()
    }
  },
  mounted() {
    this.getUserList();
    this.getPasswordPolicy();
  }
};
</script>
