<template>
  <v-container class="right-component">
    <div class="content">
      <h1>{{ $t('headlines.changelog') }}</h1>
      <p>{{ $t('headlines.changelog-subHeadline') }}</p>

      <div v-for="(changeGroup, index) in changelog.slice().reverse()" :key="index">
        <h5 v-if="changeGroup[0].type === 'Sketch'">{{ formatDate(changeGroup[0].ts) }} </h5>
        <h5 v-else>{{ formatDate(changeGroup[0].new.ts) }} </h5>
        <v-row v-for="(change, index) in changeGroup" class="ma-3">
          <v-card class="mb-4">
            <v-card-title v-if="change.type === 'Sketch'">
              <span class="ml-2">
                  {{ formatDate(change.ts) }}
                </span>
              <v-chip class="ml-4" color="primary" text-color="white">
                {{ $formController.resolveUserNameById(change.editor) }}
              </v-chip>
            </v-card-title>
            <v-card-title v-else >
              <span class="ml-2">
                  {{ formatDate(change.new.ts) }}
                </span>
              <v-chip class="ml-4" color="primary" text-color="white">
                {{ $formController.resolveUserNameById(change.new.editor) }}
              </v-chip>
            </v-card-title>
            <v-card-text v-if="change.type === 'Sketch'">
              <div>
                <strong>{{ $t('document.changelogSketch-deleted') }}</strong> {{ formatSketchValue(change).deletedPngs }}
              </div>
              <div>
                <strong>{{ $t('document.changelogSketch-updated') }}</strong> {{ formatSketchValue(change).changedPngs }}
              </div>
              <div>
                <strong>{{ $t('document.changelogSketch-added') }}</strong> {{ formatSketchValue(change).addedPngs }}
              </div>
            </v-card-text>
            <v-card-text v-else>
              <div>
                <strong>{{ $t('document.oldValue') }}:</strong> {{ formatValue(change.old.value) }}
              </div>
              <div>
                <strong>{{ $t('document.newValue') }}:</strong> {{ formatValue(change.new.value) }}
              </div>
            </v-card-text>
            <v-card-actions v-if="change.type === 'Sketch'">
              <v-btn icon @click="$emit('mapChangelogToFile', [change.fieldID, null])">
                <v-icon>mdi-eye-arrow-left-outline</v-icon>
              </v-btn>
            </v-card-actions>
            <v-card-actions v-else>
              <v-btn icon @click="$emit('mapChangelogToFile', [change.fieldID, change.new.ts])">
                <v-icon>mdi-eye-arrow-left-outline</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'FormChangelog',
  props: ['changelog'],
  methods: {
    formatDate(ts) {
      const date = new Date(ts);
      return date.toLocaleString();
    },
    formatSketchValue(value) {
      const deletedPngs = value.old
        .filter(png => !value.new.some(image => image.id === png.id))
        .map(png => png.id);

      const changedPngs = value.old
        .filter(png => {
          const checkPng = value.new.find(image => image.id === png.id);
          return checkPng && checkPng !== png;
        })
        .map(png => png.id);

      const addedPngs = value.new
        .filter(png => !value.old.some(image => image.id === png.id))
        .map(png => png.id);

      return {
        deletedPngs: deletedPngs.map(item => item === null ? 'No Value' : JSON.stringify(item)).join(', ').replace('.png', ''),
        changedPngs: changedPngs.map(item => item === null ? 'No Value' : JSON.stringify(item)).join(', ').replace('.png', ''),
        addedPngs: addedPngs.map(item => item === null ? 'No Value' : JSON.stringify(item)).join(', ').replace('.png', '')
      }

    },
    formatValue(value) {
      if (Array.isArray(value)) {
        console.log(typeof value[0])
        if (typeof value[0] === 'object' && value[0] !== null) {
          // needed vor sketch type
          if (value[0].id && value[0].id.includes('png')) {
            return this.$t('document.changedSketch')
          }
          // needed for table type
          return value.map(item =>
            Object.entries(item).map(([key, value]) => `${value}`).join(': ')
          ).join('; ');
        } else {
          console.log('value', value)
          return value.map(item => item === null ? 'No Value' : JSON.stringify(item)).join(', ');
        }
      } else if (typeof value === 'object') {
        if (value.signee) {
          // needed for signature type
          return this.$t('document.signedBy') + value.signee;
        }
        return JSON.stringify(value, null, 2);
      } else if (value === undefined) {
        return value;
      }
      return value.toString();
    }
  }
};
</script>

<style scoped>
.right-component {
  display: flex;
  height: 100%;
  background-color: white; /* optionale Hintergrundfarbe */
  overflow-y: auto; /* ermöglicht vertikales Scrollen */
}

.content {
  padding: 20px; /* Innenabstand für den Inhalt */
}
</style>
