<template>
  <div>
    <v-snackbar
      bottom
      :value="syncAlert.active"
      :color="syncAlert.color"
      :timeout="-1"
    >
      {{ syncAlert.message }}
      <v-btn text @click="closeSnackbar">
        x
      </v-btn>
    </v-snackbar>
  </div>

</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ErrorSnackbar",
  computed: {
    ...mapGetters([
      'syncAlert',
    ])
  },
  methods: {
    closeSnackbar(){
      this.$store.commit('unsetSyncAlert')
    }
  }
};
</script>
