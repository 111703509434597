<template>
  <v-dialog v-model="dialog" width="500">
    <template #activator="{ on }">
      <v-list-item v-on="on">
        <v-list-item-icon>
          <v-btn icon >
            <v-icon>mdi-form-textbox</v-icon>
          </v-btn>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t('document.rename')}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-card>
      <v-form>
        <v-card-title>{{ $t('document.rename') }}</v-card-title>
        <v-col>
          <v-text-field
            v-model="documentName"
            :label="$t('document.new-name')"
          />
        </v-col>
        <v-card-actions>
          <v-spacer/>
          <v-btn text @click="dialog = false">{{ $t('actions.cancel') }}</v-btn>
          <v-btn
            text
            color="primary"
            @click="renameDoc()"
            :loading="loading"
          >{{ $t('actions.rename') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'RenameDocumentDialog',
  props: {
    document: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      dialog: false,
      documentName: "",
      loading: false
    }
  },
  watch: {
    document(newDocument) {
      this.documentName = newDocument.name;
    }
  },
  methods: {
    renameDoc: function () {
      this.loading = true
      if (this.type === 'form') {
        this.$formController.getDocument(this.document.id)
          .then(res => (this.forms = res.data))
          .then(async doc => {
            doc.name = this.documentName
            await this.$formController.updateDocument(doc)
              .then(() => {
                this.dialog = false
                this.$emit("rename", this.item);
              })
          })
          .finally( () => this.loading = false )
      } else {
        this.$templateController.getDocument(this.document.id)
          .then(res => (this.forms = res.data))
          .then(async doc => {
            doc.name = this.documentName
            await this.$templateController.updateDocument(doc)
              .then(() => {
                this.dialog = false
                this.$emit("rename", this.item);
              })
          })
          .finally( () => this.loading = false )
      }
    }
  }
}
</script>

<style scoped></style>
