<template>

  <v-container v-if="form" class="container">
    <div :class="formStyleClass">

      <v-bottom-navigation
        app
        height="auto">
        <v-row class="justify-center align-center">
          <v-col class="justify-center align-center" cols="6">
            <v-banner
              class="d-flex justify-center align-center text-center"
              outlined
              single-line
              sticky
            >
              {{ formatDate(form.updated) }}
            </v-banner>
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="selectedRevisionNumber"
              :items="revisions"
              item-text="selectString"
              item-value="revisionNumber"
              label="Select"
              persistent-hint
              single-line
              @change="fetchRevision(selectedRevisionNumber)"
            ></v-select>
          </v-col>
        </v-row>
      </v-bottom-navigation>

      <FormChild
        v-if="!loadingDocument"
        :fetch="fetch"
        :form="form"
        :formStyleClass="formStyleClass"
        :showMetaHeader="showMetaHeader">
      </FormChild>
      <v-sheet
        v-if="loadingDocument"
        class="ma-9"
      >
        <v-skeleton-loader
          class="mx-auto"
          max-width="300"
          type="card, list-item"
        ></v-skeleton-loader>
      </v-sheet>
    </div>
    <div :class="formStyleClass">

      <FormChild
        v-if="!loadingRevision"
        :fetch="fetch"
        :form="selectedRevision"
        :formStyleClass="formStyleClass"
        :showMetaHeader="showMetaHeader">
      </FormChild>
      <v-sheet
        v-if="loadingRevision || showRevisionPrompt"
        class="ma-9"
      >
        <v-skeleton-loader
          class="mx-auto"
          max-width="300"
          type="card, list-item"
        ></v-skeleton-loader>
      </v-sheet>
      <v-sheet
        v-if="showRevisionPrompt"
        class="ma-9"
      >
        <h4 v-if="showRevisionPrompt" class="text-center"> {{ $t('document.selectRevision') }}</h4>
      </v-sheet>

    </div>
  </v-container>
  <v-container v-else class="container">
    <div :class="formStyleClass">
      <v-sheet
        class="ma-9"
      >
        <v-skeleton-loader

          max-width="300"
          type="card, list-item"
        ></v-skeleton-loader>
      </v-sheet>
    </div>
  </v-container>
</template>

<style scoped>
.container {
  display: flex;
  width: 100vw;
  height: 100vh;
}

.half-screen {
  width: 50%;
  height: 100%;
}

.changelog {
  background-color: #f0f0f0; /* optional: Hintergrundfarbe */
}
</style>

<script>
import StructureEditor from "../components/template/StructureEditor";
import ValueEditor from "../components/form/ValueEditor";
import FormChangelog from "@/components/form/FormChangelog.vue";
import FormChild from "../components/form/Form";
import {mapGetters} from "vuex";

export default {
  name: "RevisionCompare",
  components: {
    ValueEditor,
    StructureEditor,
    FormChangelog,
    FormChild
  },
  computed: {
    ...mapGetters([
      "config",
      "revisionMode"
    ]),
  },
  watch: {
    revisionMode(value) {
      if (!value) this.$router.push(`/forms/${this.$route.params.id}`);
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path === from.path) {
      next();
      return;
    }
    if (!this.saveEnabled) next();
    if (this.saveEnabled && this.noSaveConfirmed) next();
    this.requestLeaveConfirmation(to);
    next(false);
  },
  beforeRouteLeave(to, from, next) {
    let shouldLeave = false;

    if (to.path === from.path) shouldLeave = true;
    if (!this.saveEnabled) shouldLeave = true;
    if (this.saveEnabled && this.noSaveConfirmed) shouldLeave = true;

    if (shouldLeave) {
      this.$store.commit("clearAdditionalNavigationEntries");
    } else {
      this.requestLeaveConfirmation(to);
    }
    next(shouldLeave);
  },
  data: function () {
    return {
      edit: false,
      form: null,
      showNoSaveConfirm: false,
      noSaveConfirmed: false,
      targetRoute: "/",
      loadingDocument: false,
      loadingRevision: false,
      showRevisionPrompt: true,
      formStyleClass: "half-screen",
      selectedRevisionNumber: 0,
      selectedRevision: null,
      revisionDates: [],
      showMetaHeader: true,
      revisions: {},
    };
  },
  mounted() {
    this.$store.commit("setEye", true);
    this.$store.commit("sync");
    this.$store.commit("setSaveVisible", false);
    this.$store.commit("setChangelogModeEnabled", false);
    this.$store.commit("setChangelogFileModeEnabled", false);
    this.$store.commit("setRevisionModeEnabled", true);
    this.fetch(this.$route.params.id);
    this.fetchAllRevisions(this.$route.params.id);
  },
  destroyed() {
    this.$store.commit("setMetadata", null);
    this.$store.commit("setRevisionMode", false);
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);

      const day = String(date.getUTCDate()).padStart(2, '0');
      const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Monate sind nullbasiert
      const year = date.getUTCFullYear();
      const hours = String(date.getUTCHours()).padStart(2, '0');
      const minutes = String(date.getUTCMinutes()).padStart(2, '0');

      return `${day}.${month}.${year} - ${hours}:${minutes}`;
    },
    fetch: function (id) {
      this.$formController.getDocument(id)
        .then(res => {
          this.form = res.data;
          return res.data;
        })
    },
    fetchAllRevisions: async function (id) {
      this.$formController.getRevisions(id)
        .then(res => {
          this.revisions = res.data;
          this.revisions.forEach(revision => {
            revision.selectString = this.formatDate(revision.updated) + ' ' + revision.comment
            revision.updated = this.formatDate(revision.updated);
            revision.created = this.formatDate(revision.created);
          })
          return res.data;
        })
    },
    compareFields: function (obj1, obj2) {
      if (obj1 === obj2) return true;

      if (obj1 == null || typeof obj1 !== 'object' ||
        obj2 == null || typeof obj2 !== 'object') {
        return false;
      }

      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      if (keys1.length !== keys2.length) return false;

      for (const key of keys1) {
        if (!keys2.includes(key) || !this.compareFields(obj1[key], obj2[key])) {
          return false;
        }
      }
      return true;
    },
    compareRevision: function () {
      let fieldDifferences = []

      this.form.sections.forEach(section => {
        let compareSection = this.selectedRevision.sections.find(revSection => revSection.id === section.id);
        console.log('Section ', section)
        console.log('Compare Section ', compareSection)
        section.fields.forEach(field => {
          if (field.type === 'Section') {
            // this is a subsection
          }

          let compareField = compareSection.fields.find(revField => revField.id === field.id);
          let tmpField = {...field};
          delete tmpField.ts;
          delete tmpField.editor;
          let tmpCompareField = {...compareField};
          delete tmpCompareField.ts;
          delete tmpCompareField.editor;

          if (!this.compareFields(tmpField, tmpCompareField)) {
            console.log('Field ', tmpField)
            console.log('Compare Field ', tmpCompareField)
            fieldDifferences.push(tmpField.id);
          }
        })
      })

      console.log(fieldDifferences);
    },
    fetchRevision: async function (revId) {
      this.showRevisionPrompt = false;
      this.loadingRevision = true;
      this.$formController.getRevision(this.$route.params.id, revId)
        .then(res => {
          this.selectedRevision = res.data;
          // Todo: create revision compare and mark with colors
          //this.compareRevision();
          this.loadingRevision = false;
          return res.data;
        })
    },
    cancelRoute: function () {
      this.noSaveConfirmed = false;
      this.showNoSaveConfirm = false;
    },
    confirmRoute: function () {
      this.noSaveConfirmed = true;
      this.showNoSaveConfirm = false;
      this.$router.push(this.targetRoute);
    }
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
