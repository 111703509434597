<template>
  <div class="pb-5 pt-10" ref="target">
    <v-card
      class="mx-auto"
    >
      <v-carousel
        v-if="images.length > 0"
        v-model="currentImageIndex"
        class="pb-16"
        height="500"

        show-arrows
      >
        <v-carousel-item
          v-for="(image, i) in images"
          :key="i"
        >
          <v-sheet
            height="100%"
          >
            <div class="d-flex fill-height justify-center align-center">
              <v-img
                :ref="'previewImage' + image.id"
                :src="image.attachment"
                contain
                :height="imageHeight(image.id)"
                :width="imageWidth(image.id)"
                :loading="loading"
              >
              </v-img>
            </div>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
      <v-card-actions>
        <v-row justify="end">
          <v-col cols="auto">
            <v-btn v-if="images.length > 0 && !loading"
                   text
                   :disabled="loading"
                   @click="edit(images[currentImageIndex].id)"
            >
              <v-icon dark>
                mdi-image-edit
              </v-icon>
            </v-btn>
            <v-btn v-if="images.length > 0 && !loading"
                   text
                   :disabled="loading"
                   @click="remove(images[currentImageIndex].id)">
              <v-icon dark>
                mdi-trash-can
              </v-icon>
            </v-btn>
            <LoadingSpinner :loading="loading"/>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-dialog
              v-model="dialog"
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
            >
              <template #activator="{ on }">
                <v-btn
                  :color="validationError ? 'error' : 'primary'"
                  :disabled="disabled || loading"
                  @click="newImage=true"
                  v-on="on"
                >
                  <v-icon dark>
                    mdi-image-plus
                  </v-icon>
                </v-btn>
              </template>
              <v-card style="background-color: #FAFAFA" >
                <v-toolbar ref="overlayToolbar" color="primary" dark>
                  <v-btn dark icon @click="discard">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-toolbar-title>{{ $t('fields.sketch') }}</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-btn dark fab icon @click="undo">
                      <v-icon>mdi-undo</v-icon>
                    </v-btn>
                    <v-menu offset-y>
                      <template #activator="{ on, attrs }">
                        <v-btn dark icon text v-bind="attrs" v-on="on">
                          <v-icon>mdi-palette</v-icon>
                        </v-btn>
                      </template>
                      <v-list class="pa-0 ma-0">
                        <v-list-item v-for="color in colors" :key="color" class="pa-0">
                          <v-icon
                            :color="color"
                            large
                            style="margin: auto"
                            @click="changeColor(color)"
                          >mdi-checkbox-blank-circle
                          </v-icon>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <input
                      ref="fileSelectDialog"
                      accept="image/png, image/jpeg"
                      style="display: none"
                      type="file"
                      @change="uploadDialogEnd"
                    />
                    <v-btn dark fab icon @click="upload">
                      <v-icon>mdi-camera</v-icon>
                    </v-btn>
                    <v-btn dark fab icon @click="removeCurrent">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn dark icon text @click="save">
                      <v-icon>mdi-floppy</v-icon>
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>
                <v-list class="mt-6" style="background-color: #FAFAFA" subheader three-line>
                  <v-list-item>
                    <v-row justify="center">
                      <div id="mountPoint" ref="mountPoint"></div>
                    </v-row>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-dialog>

          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>


  </div>
</template>

<script>
import Editor from "vue-image-markup";
import {v1 as uuidv1} from "uuid";
import Vue from "vue";
import LoadingSpinner from "@/components/utils/LoadingSpinner";

const maxHeight = window.innerHeight * 0.5;
const maxWidth = window.innerWidth * 0.7;

export default {
  name: "Sketch",
  components: {LoadingSpinner, Editor},
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    description: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    validationError: {
      type: String,
      default: null
    },
    docId: {
      type: String,
      required: true
    },
    field: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      newImage: false,
      images: [],
      naturalImageHeight: [],
      naturalImageWidth: [],
      image: '100%',
      currentBgImage: null,
      canvasHeight: null,
      canvasWidth: null,
      currentImageIndex: 0,
      editorId: null,
      showTooltips: true,
      dialog: false,
      currentEditor: null,
      editor: {
        options: {
          stroke: "red",
          strokeWidth: 5
        },
        mode: "freeDrawing"
      },
      loading: true,
      colors: ["red", "green", "blue", "yellow", "black"]
    };
  },
  computed: {
    margins: function () {
      return document.body.clientWidth * 0.1;
    },
    imageHeight: function () {
      return index => {
        if (!this.naturalImageWidth[index] || !this.naturalImageHeight[index]) {
          return maxHeight;
        }

        let widthRatio = this.naturalImageWidth[index] / maxWidth;
        let heightRatio = this.naturalImageHeight[index] / maxHeight;

        if (widthRatio > heightRatio && widthRatio > 1) {
          return this.naturalImageHeight[index] / widthRatio;
        }

        if (heightRatio > 1) return maxHeight;

        return this.naturalImageHeight[index];
      };
    },
    imageWidth: function () {
      return index => {
        if (!this.naturalImageWidth[index] || !this.naturalImageHeight[index]) {
          return maxWidth;
        }

        let widthRatio = this.naturalImageWidth[index] / maxWidth;
        let heightRatio = this.naturalImageHeight[index] / maxHeight;

        if (widthRatio <= heightRatio && heightRatio > 1) {
          return this.naturalImageWidth[index] / heightRatio;
        }

        if (widthRatio > 1) return maxWidth;

        return this.naturalImageWidth[index];
      };
    }
  },
  watch: {
    dialog: function () {
      this.$nextTick().then(() => {
        this.currentBgImage = null;
        if (this.currentImageIndex != null && !this.newImage) {
          this.currentBgImage = this.images[this.currentImageIndex].attachment;
        }
        if (this.imageHeight(this.currentImageIndex) && this.imageWidth(this.currentImageIndex)) {
          this.destroyAndCreateEditor();
        } else {
          this.destroyAndCreateEditorWithDimensions(800, 600);
        }
      });
    }
  },
  mounted() {
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Führe hier den API Call aus
          this.fetchPng();
          // Stoppe das Observieren, wenn du die Daten geladen hast
          this.observer.unobserve(this.$refs.target);
        }
      });
    });

    this.observer.observe(this.$refs.target);

  },
  created() {
    this.editorId = uuidv1();
  },
  methods: {
    createEditorInstanceWithProps: function (props) {
      let ExtendedEditor = Vue.extend(Editor);
      return new ExtendedEditor({
        propsData: props
      });
    },
    replaceCurrentEditorWithEmptyMountPoint: function () {
      let parent = this.currentEditor.$el.parentElement;
      this.currentEditor.$destroy();
      parent.innerHTML = '<div id="mountPoint"></div>';
    },
    destroyAndCreateEditor: function () {
      this.destroyAndCreateEditorWithDimensions(
        this.imageWidth(this.currentImageIndex),
        this.imageHeight(this.currentImageIndex)
      );
    },
    destroyAndCreateEditorWithDimensions: function (width, height) {
      if (this.currentEditor) this.replaceCurrentEditorWithEmptyMountPoint();

      let editor = this.createEditorInstanceWithProps({
        editorId: uuidv1(),
        canvasWidth: width,
        canvasHeight: height
      });

      editor.$mount("#mountPoint");
      editor.set(this.editor.mode, this.editor.options);
      editor.setBackgroundImage(this.currentBgImage);

      this.currentEditor = editor;
    },
    promiseImageDataWillBeLoaded: function (file) {
      return new Promise(resolve => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          let img = document.createElement("img");

          img.src = reader.result;
          img.onload = () => {
            resolve({
              width: img.naturalWidth,
              height: img.naturalHeight,
              data: reader.result
            });
          };
        };
      });
    },
    processImageData: function (imgData) {
      this.currentBgImage = imgData.data;
      Vue.set(this.naturalImageWidth, this.currentImageIndex, imgData.width);
      Vue.set(this.naturalImageHeight, this.currentImageIndex, imgData.height);
    },
    uploadDialogEnd: async function (event) {
      await this.promiseImageDataWillBeLoaded(event.target.files[0].slice())
        .then(this.processImageData)
        .then(this.destroyAndCreateEditor);
    },
    upload: function () {
      this.$refs.fileSelectDialog.click();
    },
    changeColor: function (color) {
      this.editor.options.stroke = color;
      this.currentEditor.set(this.editor.mode, this.editor.options);
    },
    save: async function () {
      let savedImage = this.currentEditor.saveImage();
      this.dialog = false;
      this.loading = true;
      let nextId = 0;
      let nextIdString = '';
      let images = await this.$formController.getAttachment(this.docId, this.field.id, 'image');

      if (this.newImage) {
        if (images.length === 0) {
          nextId = 1;
        } else {
          const maxId = images.reduce((max, item) => item.id > max ? item.id : max, images[0].id);
          nextId = parseInt(maxId.replace('.png', ''), 10) + 1;
        }
        nextIdString = String(nextId) + '.png'
      } else {
        nextIdString = this.images[this.currentImageIndex].id;
      }

      await this.$formController.putAttachment(this.docId, this.field.id, 'image', nextIdString, savedImage)
      await this.fetchPng()
      this.currentImageIndex = this.images.length - 1
      this.$store.commit("setSaveEventCompleted", true);
    },
    undo: function () {
      this.currentEditor.undo();
    },
    redo: function () {
      this.currentEditor.redo();
    },
    setMode: function (mode) {
      this.editor.mode = mode;
      this.currentEditor.set(this.editor.mode, this.editor.options);
    },
    discard: function () {
      this.currentBgImage = null;
      this.dialog = false;
    },
    removeCurrent: function () {
      Vue.delete(this.images, this.currentImageIndex);
      this.dialog = false;
    },
    remove: async function (index) {
      Vue.delete(this.images, index);
      await this.$formController.deleteAttachment(this.docId, this.field.id, 'image', index);
      await this.fetchPng();
      this.$store.commit("setSaveEventCompleted", true);
    },
    edit: function (index) {
      this.processImageData(this.images[this.currentImageIndex])
      this.newImage = false;
      this.dialog = true;
    },
    async fetchPng() {
      this.loading = true;
      let images = await this.$formController.getAttachment(this.docId, this.field.id, 'image');
      if(images) {
        this.images = images
        this.loading = false;
      } else {
        this.$store.commit("setSyncAlertError", "error fetching images")
      }
    }
  },
};
</script>
