<template>
  <div>
    {{ label }}
    <v-row>
      <v-col
        v-for="(element, index) in size"
        :key="index"
        :cols="12 / mutableElements.length"
      >
        <v-text-field
          v-model="mutableElements[index]"
          solo
          :disabled="disabled"
          :prefix="index === 0 ? prefix : ''"
          :suffix="index !== mutableElements.length - 1 ? 'x' : suffix"
          @input="$emit('change', mutableElements)"
        >
        </v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Vector",
  model: {
    prop: "elements",
    event: "change"
  },
  props: {
    label: {
      type: String,
      default: ""
    },
    size: {
      type: Number,
      required: true
    },
    elements: {
      type: Array,
      required: true
    },
    prefix: {
      type: String,
      default: ""
    },
    suffix: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    mutableElements: function() {
      return this.elements;
    }
  }
};
</script>

<style scoped></style>
