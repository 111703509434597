<template>
  <div>
    <v-row>
      <v-col>
        <h3>Vektor</h3>
      </v-col>
      <v-col cols="auto">
        <v-btn
          class="mx-1"
          fab
          dark
          x-small
          color="secondary"
          @click="$emit('delete')"
        >
          <v-icon dark>mdi-trash-can</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2">
        <v-text-field
          v-model="mutableValue.prefix"
          label="Prefix"
          @change="$emit('change', value)"
        />
      </v-col>
      <v-col cols="8">
        <v-text-field
          v-model="mutableValue.name"
          label="Name"
          @change="$emit('change', value)"
        />
      </v-col>
      <v-col cols="2">
        <v-text-field
          v-model="mutableValue.suffix"
          label="Suffix"
          @change="$emit('change', value)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          type="number"
          label="Anzahl der Felder"
          :value="mutableValue.elements.length"
          append-outer-icon="add"
          prepend-icon="remove"
          :rules="[
            v => {
              if (v >= 1) {
                return true;
              }
              return 'Der Wert des Feldes muss mindestens 1 sein.';
            }
          ]"
          @change="changedSize($event)"
          @click:append-outer="changedSize(mutableValue.elements.length + 1)"
          @click:prepend="changedSize(mutableValue.elements.length - 1)"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "VectorBuilder",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  computed: {
    mutableValue: function() {
      return this.value;
    }
  },
  methods: {
    changedSize: function(newSize) {
      if (newSize < 0) newSize = 0;

      if (newSize < this.mutableValue.elements.length) {
        this.mutableValue.elements = this.mutableValue.elements.slice(
          0,
          newSize
        );
      }

      while (newSize > this.mutableValue.elements.length) {
        this.mutableValue.elements.push("");
      }

      this.mutableValue.fieldCount = Number(newSize);
      this.$emit("change", this.mutableValue);
    }
  },
  mounted() {
    if(!this.mutableValue.fieldCount) {
      this.mutableValue.fieldCount = 1;
    }
  }
};
</script>

<style scoped></style>
