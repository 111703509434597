import axiosPackage from 'axios'
import {Auth} from 'aws-amplify'
import * as jdenticon from "jdenticon";

let axios

export default class UserController {
  store = null
  currentUserId = null

  constructor(store) {
    this.store = store
    axios = axiosPackage.create({
      baseURL: this.store.getters.config['api_url']
    })

    axios.interceptors.request.use(async config => {
        await Auth.currentSession().then(res => {
          config.headers['Authorization'] = res.getAccessToken().getJwtToken()
        }).catch(err => console.log(err))
        return config
      })
  }

  setCurrentUserId(jwtToken) {
    this.currentUserId = jwtToken
  }

  getUserList() {
    return axios.get('/users')
      .then((res) => res.data)
  }

  getUser(userId) {
    return axios.get('/users/' + userId)
      .then(res => res.data);
  }

  getCurrentUser() {
    if(!this.currentUserId)
      localStorage.getItem("currentUserId")

    return axios.get('/users/' + this.currentUserId)
      .then(res => res.data).catch(err => console.error(err))
  }

  getCurrentUserLocally() {
    if(!this.currentUserId)
      localStorage.getItem("currentUserId")

    let userList = this.store.getters.getUserList
    let user = userList.get(this.currentUserId)
    if (user) {
      return user
    } else {
      return this.getCurrentUser()
    }
  }

  generateProfilePicture(parameter) {
    jdenticon.configure({
      lightness: {
        color: [0.40, 0.80],
        grayscale: [0.30, 0.90]
      },
      saturation: {
        color: 0.50,
        grayscale: 0.00
      },
      backColor: "#fff"
    })

    return "data:image/svg+xml;base64," + btoa(jdenticon.toSvg(parameter, 100).toString())
  }

  resolveUserNameById(userId) {
    let userList = this.store.getters.getUserList
    let user = userList.get(userId)
    if (user) {
      return user.name
    } else {
      //todo call getUsers api endpoint and refresh cache :D
      return '???'
    }
  }

  createUser(user) {
    return axios.post('/users', user)
      .then(res => {
        return res.data
      }).catch(err => {
        console.error(err)
        throw err
      })
  }

  addUserToGroup(userName, groupName) {
    return axios.put('/groups/' + groupName + '/users/' + userName)
      .then(res => res.data)
      .catch(err => {
        console.error(err)
        throw err;
      });
  }

  removeUserFromGroup(userName, groupName) {
    return axios.delete('/groups/' + groupName + '/users/' + userName)
      .then(res =>  res.data)
      .catch(err => {
        console.log(err)
        throw err
      });
  }

  getPasswordPolicy() {
    return axios.get('/settings/password-policy')
      .then(res =>  res.data)
      .catch(err => {
        console.log(err)
        throw err
      });
  }

  setPassword(username, password, permanent){
    return axios.post('/users/' + username + '/password', {
      password: password,
      permanent: permanent
    })
      .then(res =>  res.data)
      .catch(err => {
        console.log(err)
        throw err
      });
  }

  hasWrite() {
    return this.store.state.auth.groups.includes("write")
  }

  isAdmin() {
    return this.store.state.auth.groups.includes("admin")
  }

  isGerman() {
    return localStorage.getItem("lang") === "de";
  }
}
