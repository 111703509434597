<template>
  <v-container>
    {{ $store.commit('setMetadata',  $t("nav.Aufträge") ) }}
    <div>
      <v-row justify="center">
        <v-col cols="12" class="col-md-8">
          <v-row align="center">
            <v-col v-if="hasWrite()" cols="auto">
              <form-creation-dialog />
            </v-col>
            <v-col>
              <v-text-field
                v-model="searchText"
                solo
                dense
                hide-details
                clearable
                :label="$t('actions.search')"
                prepend-inner-icon="mdi-magnify"
                @click:clear="searchText = ''"
              ></v-text-field>
            </v-col>
            <v-col cols="auto" v-if="false">
              <MultiFilter
                @filtersApply="onFiltersApply"
                @filtersReset="onFiltersReset"
                :search-text="searchText"
                :search-text.sync="searchText"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <loading-spinner :loading="loading"/>
      <v-row justify="center">
        <v-col cols="12" class="col-md-8">
          <div>
            <v-list>
              <v-list-item
                v-for="(form, index) in filterResults(searchText)"
                :key="form.id"
                @click="href('/forms/' + form.id)"
              >
                <v-list-item-content>
                  <v-list-item-title
                    v-text="form.name"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="renderSubtitle(form)"
                  ></v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action v-if="hasWrite()">
                  <copy-form-dialog
                    :form="form"
                    :forms="forms"
                  />
                </v-list-item-action>
                <v-list-item-action>
                  <export :form="form"/>
                </v-list-item-action>
                <v-list-item-action v-if="hasWrite() && $userController.isGerman()">
                  <v-btn icon @click="href('/forms/' + form.id + '/info')">
                    <v-icon>mdi-information-outline</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </div>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col class="col-md-8">
          <Pagination
            :current-page.sync="currentPage"
            :items="forms"
            :search-text="searchText"/>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import FormCreationDialog from '../components/form/dialog/FormCreationDialog'
import LoadingSpinner from '../components/utils/LoadingSpinner'
import DeleteFormDialog from '../components/form/dialog/DeleteFormDialog'
import Export from '../components/export/Export'
import CopyFormDialog from '../components/form/dialog/CopyFormDialog'
import SelectConflictDialog from '../components/form/dialog/SelectConflictDialog'
import {mapGetters} from 'vuex'
import RenameDocumentDialog from '@/components/form/dialog/RenameDocumentDialog'
import {format, register} from 'timeago.js'
import de from 'timeago.js/lib/lang/de'
import en from 'timeago.js/lib/lang/en_US'
import Pagination from "@/components/utils/Pagination";
import MultiFilter from "@/components/utils/MultiFilter";
import CreateNewRevisionDialog from "../components/form/dialog/CreateNewRevision.vue";

export default {
  name: 'FormList',
  components: {
    CreateNewRevisionDialog,
    MultiFilter,
    Pagination,
    RenameDocumentDialog,
    CopyFormDialog,
    Export,
    DeleteFormDialog,
    LoadingSpinner,
    FormCreationDialog,
    SelectConflictDialog
  },
  data: function () {
    return {
      currentPage: 1,
      dialog: false,
      loading: true,
      forms: [],
      searchText: '',
    }
  },
  computed: {
    ...mapGetters(['config']),
  },
  created: function () {
  },
  mounted() {
    this.$store.commit('sync')
    //this.fetchDocs()
  },
  beforeRouteEnter: function (to, from, next) {
    next(vm => vm.fetchDocs())
  },
  methods: {
    href: function (dest) {
      this.$router.push(dest)
    },
    fillDoc: function (formId) {
      this.$router.push(
        '/forms/' + formId + '/fill'
      )
    },
    fetchDocs: function () {
      this.$formController.getDocumentList(this.$route.query)
        .then(res => {
          this.forms = res.data
          this.loading = false
        })
        .catch(res => {
          console.error(res)
          throw res
        })
    },
    onFiltersApply: function(filters) {
      this.$router.push(this.$route.path + "?" + new URLSearchParams(filters).toString())
      this.fetchDocs()
    },
    onFiltersReset: function() {
      this.onFiltersApply({})
    },
    renderSubtitle: function (form) {
      register('de', de)
      register('en', en)
      if (localStorage.getItem('lang') === "de")
        return "Bearbeitet " + format(form.updated, 'de') + " von " + form.editorName
      else
        return "Changed " + format(form.updated, 'en') + " by " + form.editorName
    },
    filterResults(searchText) {
      const lowerSearchText = searchText.toLowerCase();
      const searchFields = ["name", "creatorName", "editorName"];
      return this.forms.filter(form => {
        return searchFields.some(field => {
          const value = form[field];
          return typeof value === "string" && value.toLowerCase().includes(lowerSearchText)
        });
      }).slice((this.currentPage - 1) * 10, (this.currentPage - 1) * 10 + 10)
    },
    hasWrite: function () {
      return this.$store.state.auth.groups.includes("write")
    }
  }
}
</script>

<style scoped></style>
