<template>
  <div>
    <v-row>
      <v-col>
        <h3>Textfeld</h3>
      </v-col>
      <v-col cols="auto">
        <deletion-warning-dialog :name="'Textfeld'" @delete="$emit('delete')">
        </deletion-warning-dialog>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2">
        <v-text-field
          v-model="mutableValue.prefix"
          label="Prefix"
          @change="$emit('change', mutableValue)"
        />
      </v-col>
      <v-col cols="8">
        <v-text-field
          v-model="mutableValue.name"
          label="Name"
          @change="$emit('change', mutableValue)"
        />
      </v-col>
      <v-col cols="2">
        <v-text-field
          v-model="mutableValue.suffix"
          label="Suffix"
          @change="$emit('change', mutableValue)"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DeletionWarningDialog from "./dialog/DeletionWarningDialog";

export default {
  name: "TextBuilder",
  components: { DeletionWarningDialog },
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  computed: {
    mutableValue: function() {
      return this.value;
    }
  }
};
</script>

<style scoped></style>
