<template>
  <div>
    <v-row>
      <v-col>
        <h3>Statisches Bildfeld</h3>
      </v-col>
      <v-col cols="auto">
        <deletion-warning-dialog
          :name="'Statisches Bildfeld'"
          @delete="$emit('delete')"
        >
        </deletion-warning-dialog>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="mutableValue.name"
          label="Bildunterschrift"
          @change="$emit('change', mutableValue)"
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="mutableValue.link"
          label="URL"
          @change="$emit('change', mutableValue)"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DeletionWarningDialog from "./dialog/DeletionWarningDialog";

export default {
  name: "PictureBuilder",
  components: { DeletionWarningDialog },
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  computed: {
    mutableValue: function() {
      return this.value;
    }
  }
};
</script>

<style scoped></style>
