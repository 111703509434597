<template>
  <v-container>
    <div :class="formStyleClass">
      {{ $store.commit("setMetadata", form.name) }}
      <v-bottom-navigation
        v-if="saving"
        app
        height="200">
        <v-progress-linear
          class="mb-8"
          color="cyan"
          indeterminate>
        </v-progress-linear>
      </v-bottom-navigation>

      <template>
        <v-row justify="center">
          <v-dialog v-model="showNoSaveConfirm" max-width="500px" persistent>
            <v-card>
              <v-card-title class="headline">
                {{ $t('actions.unsavedChanges') }}
              </v-card-title>
              <v-card-text>
                {{ $t('actions.unsavedChangesDescription') }}
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="cancelRoute">
                  {{ $t('actions.cancel') }}
                </v-btn>
                <v-btn color="error" text @click="confirmRoute">
                  {{ $t('actions.continueWithOutSave') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>

      <v-row justify="center">
        <value-editor
          v-if="$store.state.eye && form"
          v-model="form"
          :changelog-mode="changelogFileMode"
          :showMetaHeader="showMetaHeader"
          @change="
          () => (!saveEnabled ? $store.commit('setSaveEnabled', true) : '')
        "
        />

        <structure-editor v-else-if="!$store.state.eye && form" v-model="form"/>
      </v-row>
    </div>
  </v-container>
</template>

<style scoped>
.container {
  display: flex;
  width: 100vw;
  height: 100vh;
}

.full-screen {
  width: 100%;
  height: 100%;
}

.half-screen {
  width: 50%;
  height: 100%;
}

.changelog {
  background-color: #f0f0f0; /* optional: Hintergrundfarbe */
}
</style>

<script>
import StructureEditor from "../template/StructureEditor";
import ValueEditor from "./ValueEditor";
import {mapGetters} from "vuex";
import pdfExport from '@/components/export/pdf-export';

export default {
  name: "FormChild",
  components: {
    ValueEditor,
    StructureEditor,
  },
  props: {
    form: Object,
    formStyleClass: {
      type: String,
      default: 'full-screen'
    },
    changelogMode: {
      type: Boolean,
      default: false
    },
    changelogFileMode: {
      type: Boolean,
      default: false
    },
    showMetaHeader: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      "config",
      "saveEnabled",
      "saveEvent",
      "additionalNavigationEntries",
      "printIntended",
      "saveCompleted"
    ])
  },
  watch: {
    saveEvent(value) {
      if (value) this.save();
    },
    printIntended(value) {
      if (value) {
        this.$store.commit("resetPrint");
        this.pdf();
      }
    }
  },
  data: function () {
    return {
      edit: false,
      showNoSaveConfirm: false,
      noSaveConfirmed: false,
      targetRoute: "/",
      showChangelog: false,
      changelogStyleClass: "half-screen changelog",
      saving: false,
    };
  },
  destroyed() {
    this.$store.commit("setMetadata", null);
  },
  methods: {
    pdf: function () {
      pdfExport(this.form);
    },
    save: function () {
      this.saving = true;
      return (
        this.$formController.updateDocument(this.form)
          // refetch to prevent conflicts on next save
          .then(() => this.$emit('fetch', this.$route.params.id))
          // generate success message
          .then(() => {
            this.$store.commit("setSaveEnabled", false);
            this.$store.commit("setSaveEvent", false);
            this.$store.commit("setSaveEventCompleted", true);
            return {
              active: true,
              color: "success",
              message: "Erfolgreich gespeichert."
            };
          })
          // generate error message // todo better description
          .catch(err => {
            this.$store.commit("setSaveEvent", false);
            return {
              active: true,
              color: "error",
              message: err
            };
          })
          .then(alert => {
              this.$store.commit("setSyncAlert", alert)
              this.saving = false;
            }
          )
      )
        ; // send alert
    },
    requestLeaveConfirmation: function (to) {
      this.showNoSaveConfirm = true;
      this.noSaveConfirmed = false;
      this.targetRoute = to.fullPath;
    }
    ,
    cancelRoute: function () {
      this.noSaveConfirmed = false;
      this.showNoSaveConfirm = false;
    }
    ,
    confirmRoute: function () {
      this.noSaveConfirmed = true;
      this.showNoSaveConfirm = false;
      this.$router.push(this.targetRoute);
    }
  }
}
;
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
