import Vue from "vue";
import Vuex from "vuex";
import jwt from "jsonwebtoken";

Vue.use(Vuex);

let i18n;

export default new Vuex.Store({
  state: {
    username: "",
    password: "",
    menu: false,
    metadata: "",
    printMode: false,
    eye: true,
    config: {},
    navigationContext: null,
    additionalNavigationEntries: [],
    revisionButtonProperties: {
      enabled: false,
      visible: false,
      triggered: false
    },
    changeLogFileButtonProperties: {
      enabled: false,
      visible: false,
      triggered: false
    },
    changeLogButtonProperties: {
      enabled: false,
      visible: false,
      triggered: false
    },
    requiredFieldsDetailButtonProperties: {
      enabled: false,
      visible: false,
      triggered: false
    },
    saveCompleted: false,
    saveButtonProperties: {
      enabled: false,
      visible: false,
      triggered: false,
      completed: false,
    },
    printIntended: false,
    sync: {
      // ok, progress, error
      state: "ok",
      message: ""
    },
    syncAlert: {
      active: false,
      color: "red",
      message: "Alaaarm"
    },
    auth: {
      token: null,
      groups: []
    },
    userList: null,
  },
  mutations: {
    init: function (state, f_i18n) {
      if (localStorage.getItem("tkn")) {
        state.auth.token = localStorage.getItem("tkn");
        state.auth.groups = JSON.parse(localStorage.getItem("groups"));
      }
      i18n = f_i18n;
    },
    sync: function (state) {
    },
    setPrintMode(state, payload) {
      state.printMode = payload;
    },
    setCreds: function (state, payload) {
      state.username = payload.username;
      state.password = payload.password;
    },
    print: function (state) {
      state.printIntended = true;
    },
    resetPrint: function (state) {
      state.printIntended = false;
    },
    navigationChange: function (state, payload) {
      state.config.navigation.forEach(configItem => {
        if (payload.path === "/" && configItem.isHome) {
          state.navigationContext = configItem;
          return;
        }

        if (payload.path.startsWith("/" + configItem.route)) {
          state.navigationContext = configItem;
        }
      });
    },
    clearAdditionalNavigationEntries: function (state) {
      state.additionalNavigationEntries = [];
    },
    setAdditionalNavigationEntries: function (state, navigationEntries) {
      state.additionalNavigationEntries = navigationEntries;
    },
    setMetadata: function (state, metadata) {
      state.metadata = metadata;
    },
    toggleMenu: function (state) {
      state.menu = !state.menu;
    },
    toggleEye: function (state) {
      state.eye = !state.eye;
    },
    setEye: function (state, payload) {
      state.eye = payload;
    },
    setConfig: function (state, payload) {
      state.config = payload;
    },
    unsetSyncAlert: function (state) {
      state.syncAlert = {
        active: false,
        color: "red",
        message: i18n.t("errors.unexpected.description")
      };
    },
    setSyncAlert: function (state, payload) {
      state.syncAlert = payload;
    },
    setSyncAlertError: function (state, payload) {
      state.syncAlert = {
        active: true,
        color: "red",
        message: payload || i18n.t("errors.unexpected.description")
      };
    },
    setLoading: function (state, payload) {
      state.sync.state = payload;
    },
    setAuthToken: function (state, payload) {
      localStorage.setItem("tkn", payload);

      let token = jwt.decode(payload);
      if (token && token.exp * 1000 - Date.now() > 0) {
        localStorage.setItem("tkn", payload);
        localStorage.setItem("groups", JSON.stringify(token["cognito:groups"]));
        state.auth.token = payload;
        state.auth.groups = token["cognito:groups"]
      }
    },
    setRequiredFieldsDetailVisible: function (state, payload) {
      // Parse to true/false (in js logic) if values are not boolean
      state.requiredFieldsDetailButtonProperties.visible = payload == true;
    },
    setRequiredFieldsDetailEnabled: function (state, payload) {
      // Parse to true/false (in js logic) if values are not boolean
      state.requiredFieldsDetailButtonProperties.enabled = payload == true;
      state.requiredFieldsDetailButtonProperties.visible = payload == true;
    },
    setRequiredFieldsDetailMode: function (state, payload) {
      // Parse to true/false (in js logic) if values are not boolean
      state.requiredFieldsDetailButtonProperties.triggered = payload == true;
    },
    setRevisionModeVisible: function (state, payload) {
      // Parse to true/false (in js logic) if values are not boolean
      state.revisionButtonProperties.visible = payload == true;
    },
    setRevisionModeEnabled: function (state, payload) {
      // Parse to true/false (in js logic) if values are not boolean
      state.revisionButtonProperties.enabled = payload == true;
      state.revisionButtonProperties.visible = payload == true;
    },
    setRevisionMode: function (state, payload) {
      // Parse to true/false (in js logic) if values are not boolean
      state.revisionButtonProperties.triggered = payload == true;
    },
    setChangelogModeVisible: function (state, payload) {
      // Parse to true/false (in js logic) if values are not boolean
      state.changeLogButtonProperties.visible = payload == true;
    },
    setChangelogModeEnabled: function (state, payload) {
      // Parse to true/false (in js logic) if values are not boolean
      state.changeLogButtonProperties.enabled = payload == true;
      state.changeLogButtonProperties.visible = payload == true;
    },
    setChangelogMode: function (state, payload) {
      // Parse to true/false (in js logic) if values are not boolean
      state.changeLogButtonProperties.triggered = payload == true;
    },
    unsetChangelogMode: function (state, payload) {
      // Parse to true/false (in js logic) if values are not boolean
      state.changeLogButtonProperties.triggered = payload == false;
    },
    resetChangelogState: function (state, payload) {
      state.changeLogButtonProperties = {
        triggered: false,
        visible: false,
        enabled: false
      };
    },
    setChangelogFileModeVisible: function (state, payload) {
      // Parse to true/false (in js logic) if values are not boolean
      state.changeLogFileButtonProperties.visible = payload == true;
    },
    setChangelogFileModeEnabled: function (state, payload) {
      // Parse to true/false (in js logic) if values are not boolean
      state.changeLogFileButtonProperties.enabled = payload == true;
      state.changeLogFileButtonProperties.visible = payload == true;
    },
    setChangelogFileMode: function (state, payload) {
      console.log("changelog file mode", payload);
      // Parse to true/false (in js logic) if values are not boolean
      state.changeLogFileButtonProperties.triggered = payload == true;
    },
    unsetChangelogFileMode: function (state, payload) {
      // Parse to true/false (in js logic) if values are not boolean
      state.changeLogFileButtonProperties.triggered = payload == false;
    },
    resetChangelogFileState: function (state, payload) {
      state.changeLogFileButtonProperties = {
        triggered: false,
        visible: false,
        enabled: false
      };
    },
    setSaveEvent: function (state, payload) {
      // Parse to true/false (in js logic) if values are not boolean
      state.saveButtonProperties.triggered = payload == true;
    },
    setSaveEventCompleted: function (state, payload) {
      // Parse to true/false (in js logic) if values are not boolean
      state.saveCompleted = payload == true;
    },
    setSaveEnabled: function (state, payload) {
      // Parse to true/false (in js logic) if values are not boolean
      state.saveButtonProperties.enabled = payload == true;
      state.saveButtonProperties.visible = payload == true;
    },
    setSaveVisible: function (state, payload) {
      // Parse to true/false (in js logic) if values are not boolean
      state.saveButtonProperties.visible = payload == true;
    },
    resetSaveState: function (state, payload) {
      state.saveButtonProperties = {
        triggered: false,
        visible: false,
        enabled: false
      };
    },
    invalidateToken: function (state) {
      localStorage.removeItem("tkn");
      state.auth.token = null;
      state.auth.groups = null;
    },
    setUserList: function (state, payload) {
      localStorage.setItem("userList", JSON.stringify(payload));
      state.userList = new Map(payload.map(key => [key.id, key]));
    },
    removeUserList: function (state) {
      localStorage.removeItem("userList");
      state.userList = null;
    },
  },
  actions: {},
  modules: {},
  getters: {
    printIntended: state => state.printIntended,
    saveEvent: state => state.saveButtonProperties.triggered,
    saveCompleted: state => state.saveCompleted,
    additionalNavigationEntries: state => state.additionalNavigationEntries,
    saveEnabled: state => state.saveButtonProperties.enabled,
    saveVisible: state => state.saveButtonProperties.visible,
    printMode: state => state.printMode,
    config: state => state.config,
    getLoading: state => state.loading,
    syncAlert: state => state.syncAlert,
    navigationContext: state => state.navigationContext,
    changelogMode: state => state.changeLogButtonProperties.triggered,
    changelogModeEnabled: state => state.changeLogButtonProperties.enabled,
    changelogModeVisible: state => state.changeLogButtonProperties.visible,
    changelogFileMode: state => state.changeLogFileButtonProperties.triggered,
    changelogFileModeEnabled: state => state.changeLogFileButtonProperties.enabled,
    changelogFileModeVisible: state => state.changeLogFileButtonProperties.visible,
    revisionMode: state => state.revisionButtonProperties.triggered,
    revisionModeEnabled: state => state.revisionButtonProperties.enabled,
    revisionModeVisible: state => state.revisionButtonProperties.visible,
    requiredFieldsDetail: state => state.requiredFieldsDetailButtonProperties.triggered,
    requiredFieldsDetailEnabled: state => state.requiredFieldsDetailButtonProperties.enabled,
    requiredFieldsDetailVisible: state => state.requiredFieldsDetailButtonProperties.visible,
    authToken: state => {
      let token = jwt.decode(state.auth.token);
      if (!token || token.exp * 1000 - Date.now() < 0) {
        return null;
      }
      return state.auth.token;
    },
    getUserList: state => {
      if (!state.userList) {
        try {
          let userList = JSON.parse(localStorage.getItem("userList"))
          state.userList = new Map(userList.map(key => [key.id, key]))
        } catch (ex) {
          console.error(ex)
          state.userList = new Map()
        }
      }
      return state.userList
    },
    isAdmin: state => {
      return state.auth.groups.includes("admin")
    }
  }
});
