<template>
  <v-dialog v-model="dialog" width="500">
    <template #activator="{ on }">
      <v-btn :cy-data="'new-template'" color="primary" v-on="on">
        <v-icon left>mdi-plus</v-icon>
        {{ $t('template.createNew') }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title>{{ $t('template.createNewDescription') }}</v-card-title>
      <v-divider/>
      <v-card-text>
        <v-text-field
          v-model="name"
          :cy-data="'new-template-name'"
          label="Name"
        ></v-text-field>
      </v-card-text>

      <v-card-actions>
        <v-spacer/>
        <v-btn text @click="dialog = false">{{ $t('actions.cancel') }}</v-btn>
        <v-btn
          :cy-data="'new-template-ok'"
          :disabled="name.length <= 0"
          :loading="loading"
          color="primary"
          text
          @click="createTemplate"
        >{{ $t('actions.create') }}
        </v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: "TemplateCreationDialog",
  data() {
    return {
      name: "",
      dialog: false,
      loading: false
    };
  },
  computed: {
    ...mapGetters(['config']),
  },
  methods: {
    createTemplate: function () {
      this.loading = true
      this.$templateController.createDocument({'name': this.name, sections: []})
        .then(res => {
          this.$router.push(
            '/templates/' + res.data['id']
          )
        })
        .catch(() => this.$store.commit("setSyncAlertError"))
        .finally(() => this.loading = false)
    },
  }
};
</script>

<style scoped></style>
