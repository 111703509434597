module.exports = {
  "nav": {
    "Aufträge": "Orders",
    "Vorlagen": "Templates",
    "me": "last edited",
  },
  "general": {
    "userName": "user name",
    "userId": "user id",
    "german": "German",
    "english": "English",
    "updateInfo": "Update available. Unsaved changes will be lost."
  },
  "headlines": {
    "revisionTable": 'Revisions',
    "upgradeDocuments": 'Versions',
    "changelog": 'Changelog',
    "changelog-subHeadline": "Hier sieht man alle Änderungen."
  },
  "actions": {
    "save": "save",
    "create": "create",
    "cancel": "cancel",
    "search": "search",
    "change": "change",
    "rename": "rename",
    "duplicate": "duplicate",
    "delete": "delete",
    "edit": "edit",
    "show": "show",
    "upgrade": "Upgrade",
    deleteAction: (ctx) => `Do you really want to delete ${ctx.named('name')}`,
    "continueWithOutSave": "Continue without saving",
    "unsavedChanges": "Unsaved changes!",
    "unsavedChangesDescription": "You have unsaved changes. Please save your changes.",
    "startChangeLog": "Changelog",
    "startChangeLogFileMode": "Document History",
    "startRevisionMode": "compare revisions",
    "startRequiredFieldsDetail": "check required fields",
    "chooseTemplateRevision": "Please choose a template revision",

  },
  "document": {
    "rename": "Rename document",
    "new-name": "New name",
    "duplicate": "Duplicate document",
    "export": "Export document",
    "delete": "Delete document",
    "edit": "Edit current document",
    "openTemplate": "Switch to template",
    "lastEdit": "Last edit on: ",
    "created": "Created on: ",
    deleteOrder: (ctx) => `Do you really want to delete the order ${ctx.named('name')}`,
    deleteTemplate: (ctx) => `Do you really want to delete the template ${ctx.named('name')}`,
    "selectRevision": "Please select a revision",
    "newRevision": "Create new revision",
    "required": "required field",
    "missingValue": "Missing Value",
    "missingValueDemand": "Please complete all mandatory fields.",
    "noMissingValues": "No missing fields left!",
    "signedBy": "Signed By: ",
    "changedSketch": "Changed sketch",
    "changelogSketch-updated": "Bilder bearbeitet in den Positionen:",
    "changelogSketch-deleted": "Bilder gelöscht in den Positionen:",
    "changelogSketch-added": "Bilder hinzugefügt in den Positionen:",
    "noChangelogFilesFound": "No changelog files found.",
    "onlyOneChangelogFileFound": "Until now, no further changes were applied.",
    "oldValue": "Previous Value",
    "newValue": "New Value",
  },
  "order": {
    "singular": "order",
    "plural": "orders",
    "orderNumber": "order number",
    "createNew": "create order",
    "createNewDescription": "create new order from template",
    "noDataText": "No templates available"
  },
  "template": {
    "singular": "template",
    "plural": "templates",
    "createNew": "create template",
    "createNewDescription": "create new template",
    "newName": "Name of the new template",
    duplicateAction: (ctx) => `You are duplicating ${ctx.named('name')}`,
    existing: "This name already exists",
    copyPostfix: " - Copy"
  },
  "language-switcher": {
    "title": "Language Settings",
    "description": "Choose your language"
  },
  "fields": {
    table: {
      newElement: "New element",
      editElement: "Edit element",
      newEntry: "New Entry",
      noData: "No data available",
      deleteEntry: "Delete entry",
      deleteEntryText: "Do you really want to delete the entry?"
    },
    signature: {
      signature: "Signature",
      replace: "Replace signature",
      add: "Add signature",
      signedBy: "Signed by",
      clear: "Clear signature field"
    },
    sketch: "Edit new image"
  },
  sectionBuilder: {
    withOut: "without"
  },
  errors: {
    unexpected: {
      title: "Unexpected Error",
      description: "An unexpected error occurred. Please try again later."
    }
  },
  validation: {
    required: "Required"
  },
  login: {
    username: "Username",
    password: "Password",
    login: "Login",
    incorrect: "Incorrect username or password",
    usernameCannotBeEmpty: "Username cannot be empty",
    changePassword: "Change Password",
    youNeedNewPassword: "You need to set a new password",
    oldPassword: "Old Password",
    newPassword: "New Password",
    invalidPasswordException: "The password does not meet the policy",
    userNotFoundException: "User does not exist",
    notAuthorizedException: "Incorrect username or password",
    authError: "Username cannot be empty",
    invalidParameterException: "An unexpected error occurred. Please try again later",
  },
  rules: {
    minimumLength: "Password does not meet the minimum length",
    requireLowercase: "Password does not contain a lowercase letter",
    requireUppercase: "Password does not contain an uppercase letter",
    requireNumbers: "Password does not contain a number",
    requireSymbols: "Password does not contain any special characters",
  },
  settings: {
    password: {
      edit: "edit",
      label: "New password",
      permanent: "Set password permanent"
    },
    userMgmt: {
      title: "User Management",
      desc: "Here you can create users and change their password.",
      action: "Create User"
    },
    grpMgmt: {
      title: "Groups",
      desc: "All available groups.",
      action: "Create Group",
      name: "group",
      add: "Add member"
    }
  },
  me: {
    "description": "The documents you have most recently edited in the last 30 days are displayed here."
  }
}
