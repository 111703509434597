<template>
  <v-dialog v-model="dialog" width="500">
    <template #activator="{ on }">
      <v-btn icon v-on="on">
        <v-icon>mdi-file-arrow-up-down</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>{{ $t('document.chooseTemplateRevision') }}</v-card-title>
      <v-card-text>
        <v-select
          v-model="revisionId"
          :items="revisions.slice().reverse()"
          item-text="selectString"
          item-value="templateRevision">
        </v-select>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false">{{ $t('actions.cancel') }}</v-btn>
        <v-btn :loading="loading" color="error" text @click="upgradeRevision()">{{ $t('actions.upgrade') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "UpgradeTemplateRevisionDialog",
  props: {
    revisions: {
      type: Array,
      required: true
    },
    formID: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      revisionId: null,
    };
  },
  methods: {
    upgradeRevision: function () {
      this.loading = true
      this.$formController.upgradeTemplateRevision(this.formID, this.revisionId)
        .then(() => {
          this.dialog = false
          this.$emit('fetch')
        })
        .finally(() => this.loading = false)
    }
  }
};
</script>

<style scoped></style>
