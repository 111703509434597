<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col>
            <template>
              <v-skeleton-loader
                v-if="loading"
                class="mx-auto"
                max-width="300"
                type="card"
              ></v-skeleton-loader>
              <v-card class="mx-auto" v-else tile>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="title"> {{ $t('language-switcher.title') }}</v-list-item-title>
                    <v-list-item-subtitle> {{ $t('language-switcher.description') }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-card-actions>

                  <!-- inspired by https://codepen.io/HELWATANY/details/yLOLyQO -->
                  <v-menu v-model="languageMenu" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="text-capitalize" v-bind="attrs" v-on="on" text>
                        <v-icon left>mdi-translate</v-icon>
                        {{ activeLang }}
                        <v-icon small right>mdi-menu-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item v-for="(lang, index) in languages" :key="index" @click="changeLanguage(lang)">
                        <v-list-item-title>{{ lang.icon + ' ' + lang.title }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>

                </v-card-actions>
              </v-card>
            </template>
          </v-col>
        </v-row>
        <template>
          <v-skeleton-loader
            v-if="loading"
            class="mx-auto"
            max-width="300"
            type="card"
          ></v-skeleton-loader>
          <v-card class="mx-auto" v-else tile>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="title">Light / Dark Mode</v-list-item-title>
                <v-list-item-subtitle></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-card-actions>
              <div>
                <v-tooltip v-if="!$vuetify.theme.dark" bottom>
                  <template #activator="{ on }">
                    <v-btn color="info" small fab v-on="on" @click="darkMode">
                      <v-icon class="mr-1">mdi-moon-waxing-crescent</v-icon>
                    </v-btn>
                  </template>
                  <span>Dark Mode On</span>
                </v-tooltip>

                <v-tooltip v-else bottom>
                  <template #activator="{ on }">
                    <v-btn color="info" small fab v-on="on" @click="darkMode">
                      <v-icon color="yellow">mdi-white-balance-sunny</v-icon>
                    </v-btn>
                  </template>
                  <span>Dark Mode Off</span>
                </v-tooltip>
              </div>
            </v-card-actions>
          </v-card>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "SettingsView",
  data() {
    return {
      loading: false,
      languageMenu: false,
      activeLang: localStorage.getItem('lang'),
      languages: [
        {title: this.$t('general.english'), code: 'en', icon: '🇺🇸'},
        {title: this.$t('general.german'), code: 'de', icon: '🇩🇪'},
      ],
    };
  },
  methods: {
    darkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    changeLanguage: function (lang) {
      this.activeLang = lang.code
      this.$i18n.locale = lang.code
      localStorage.setItem('lang', lang.code)
    },
  }
};
</script>
