<template>
  <div>
    <v-app v-if="$route.path === '/login'"
      data-background="/img/banner.png"
      style="
      background-image: url('/img/banner.png');
      background-repeat:no-repeat;
      background-size: cover;
    ">
      <router-view/>
    </v-app>
    <v-app v-if="routeMatcher('settings')">
      <error-snackbar />
      <div>
        <v-app-bar app color="primary" dark>
          <v-btn icon @click="$router.push('/')">
            <v-icon>mdi-home</v-icon>
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn icon @click="$router.push('/profile')">
            <v-icon>mdi-account</v-icon>
          </v-btn>

          <template v-slot:extension>
            <v-tabs align-with-title value="activeSettingsTab">
              <v-tab v-for="tab in tabs" :key="tab.path" @click="$router.push(tab.path)">
                {{ tab.name }}
              </v-tab>
            </v-tabs>
          </template>
        </v-app-bar>
      </div>
      <v-main>
        <router-view/>
      </v-main>
    </v-app>
    <v-app v-if="routeMatcher('/') || routeMatcher('forms') || routeMatcher('templates')">
      <div v-if="!printMode">
        <v-app-bar app color="primary" dark>
          <div class="d-flex align-left">
            <v-app-bar-nav-icon
              @click="$store.commit('toggleMenu')"
            ></v-app-bar-nav-icon>
          </div>

          <div class="d-flex align-left">
            <router-link
              v-if="!authToken && !$route.path.endsWith('/fill')"
              to="/login"
            >
              <v-btn color="primary" class="ma-2 white--text">
                <v-icon left>mdi-file-document</v-icon>
                Login
              </v-btn>
            </router-link>
          </div>

          <v-spacer></v-spacer>
          <h2 v-if="/\/forms|.*/.exec($route.path)">
            {{ $store.state.metadata }}
          </h2>
          <v-spacer></v-spacer>

          <v-btn
            v-if="requiredFieldsDetailVisible && !requiredFieldsDetail"
            icon
            :disabled="!requiredFieldsDetailEnabled"
            @click="$store.commit('setRequiredFieldsDetailMode', true)"
          >
            <v-tooltip v-if="authToken" bottom>
              <template #activator="{ on, attrs }">
                <v-icon dark v-bind="attrs" v-on="on">
                  mdi-file-alert-outline
                </v-icon>
              </template>
              <span>{{ $t('actions.startRequiredFieldsDetail') }}</span>
            </v-tooltip>
          </v-btn>

          <v-btn
            v-if="requiredFieldsDetailVisible && requiredFieldsDetail"
            icon
            :disabled="!requiredFieldsDetailEnabled"
            @click="$store.commit('setRequiredFieldsDetailMode', false)"
          >
            <v-tooltip v-if="authToken" bottom>
              <template #activator="{ on, attrs }">
                <v-icon dark v-bind="attrs" v-on="on">
                  mdi-file-alert-outline
                </v-icon>
              </template>
              <span>{{ $t('actions.startRequiredFieldsDetail') }}</span>
            </v-tooltip>
          </v-btn>

          <v-btn
            v-if="saveVisible"
            icon
            :disabled="!saveEnabled"
            @click="$store.commit('setSaveEvent', true)"
          >
            <v-tooltip v-if="authToken" bottom>
              <template #activator="{ on, attrs }">
                <v-icon dark v-bind="attrs" v-on="on">
                  mdi-floppy
                </v-icon>
              </template>
              <span>{{ $t('actions.save') }}</span>
            </v-tooltip>
          </v-btn>

          <v-btn
            v-if="/^\/forms\/[a-f0-9\-]+$/.test($route.path)"
            icon @click="$store.commit('setPrintMode', true)"
          >
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon dark v-bind="attrs" v-on="on">
                  mdi-printer
                </v-icon>
              </template>
              <span>Drucken</span>
            </v-tooltip>
          </v-btn>

          <v-btn
            v-if="/^\/templates\/[a-f0-9\-]+$/.test($route.path)"
            icon
            @click="$store.commit('toggleEye')"
          >
            <v-icon v-if="$store.state.eye">mdi-eye</v-icon>
            <v-icon v-else>mdi-eye-off</v-icon>
          </v-btn>

          <v-btn v-if="isAdmin" icon @click="$router.push('/settings')">
            <v-icon>mdi-cog</v-icon>
          </v-btn>

          <v-btn v-if="authToken" icon @click="$router.push('/profile')">
            <v-icon>mdi-account</v-icon>
          </v-btn>

          <template #extension>
            <v-tabs align-with-title v-model="activeTab">
              <v-tab @click="$router.push('/')" v-if="$userController.isGerman()">
                <v-icon left>mdi-account-details</v-icon>
                {{ $t('nav.me')}}
              </v-tab>
              <v-tab @click="$router.push('/forms')">
                <v-icon left>mdi-file-document</v-icon>
                {{ $t('nav.Aufträge')}}
              </v-tab>
              <v-tab @click="$router.push('/templates')" v-if="$userController.isGerman()">
                <v-icon left>mdi-book</v-icon>
                {{ $t('nav.Vorlagen')}}
              </v-tab>
            </v-tabs>
          </template>
        </v-app-bar>

      </div>
      <v-main>
        <v-row v-if="printMode" justify="end">
          <v-col align-self="right" cols="1">
            <v-btn icon @click="$store.commit('setPrintMode', false)">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-icon dark v-bind="attrs" v-on="on">
                    mdi-printer
                  </v-icon>
                </template>
                <span>Druckmodus verlassen</span>
              </v-tooltip>
            </v-btn>
          </v-col>
        </v-row>
        <error-snackbar />
        <v-navigation-drawer v-model="$store.state.menu" fixed bottom temporary>
          <v-list>
            <v-list-item-group v-model="activeTab">
              <router-link
                v-for="cfg in config.navigation"
                :key="cfg.route"
                :to="'/' + cfg.route"
              >
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>{{ cfg.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ $t('nav.' + cfg.name ) }}</v-list-item-title>
                </v-list-item>
              </router-link>
            </v-list-item-group>
          </v-list>
          <v-divider v-if="additionalNavigationEntries.length > 0"></v-divider>
          <v-list v-if="additionalNavigationEntries.length > 0">
            <v-list-item-group>
              <router-link
                v-for="entry in additionalNavigationEntries"
                :to="entry.href"
              >
                <v-list-item>
                  <v-list-item-title>{{ entry.name }}</v-list-item-title>
                </v-list-item>
              </router-link>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>

        <v-snackbar
          bottom
          :value="updateExists"
          :timeout="-1"
          style="margin-top: 30px"
          color="primary"
        >
          Ein Update ist verfügbar. Ungespeicherte Änderungen gehen verloren.
          <v-btn text @click="refreshApp">
            Update
          </v-btn>
        </v-snackbar>

        <InfoSnackbar/>
        <router-view/>
      </v-main>
    </v-app>
    <v-app v-if="routeMatcher('profile')">
      <div>
        <v-app-bar app color="primary" dark>
          <v-btn icon @click="goHome()">
            <v-icon>mdi-home</v-icon>
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn v-if="isAdmin" icon @click="$router.push('/settings')">
            <v-icon>mdi-cog</v-icon>
          </v-btn>

          <v-btn icon @click="$router.push('/profile')">
            <v-icon>mdi-account</v-icon>
          </v-btn>

          <template v-slot:extension>
            <v-tabs align-with-title>
              <v-tab>
                Profile
              </v-tab>
            </v-tabs>
          </template>
        </v-app-bar>
      </div>
      <v-main>
        <router-view/>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import update from './mixins/update'
import InfoSnackbar from "@/components/utils/InfoSnackbar";
import ErrorSnackbar from "@/components/utils/ErrorSnackbar.vue";

export default {
  name: 'App',
  components: {ErrorSnackbar, InfoSnackbar},
  mixins: [update],
  data: () => ({
    saved: true,
    nav: null,
    activeTab: 0,
    activeSettingsTab: 0,
    mini: true
  }),
  computed: {
    ...mapGetters([
      'syncAlert',
      'authToken',
      'printMode',
      'saveEnabled',
      'saveVisible',
      'config',
      'additionalNavigationEntries',
      "isAdmin",
      "changelogModeVisible",
      "changelogModeEnabled",
      "changelogFileModeVisible",
      "changelogFileModeEnabled",
      "changelogMode",
      "changelogFileMode",
      "revisionModeVisible",
      "revisionModeEnabled",
      "revisionMode",
      "requiredFieldsDetailVisible",
      "requiredFieldsDetailEnabled",
      "requiredFieldsDetail",
    ]),
    tabs() {
      return this.$router.options.routes.filter( route => route.path.includes('/settings'))
    },
  },
  beforeRouteEnter () {
    this.redirectOnInvalidToken(this.authToken)
  },
  watch: {
    authToken () {
      this.redirectOnInvalidToken(this.authToken)
    },
    $route(to, from) {
      this.setActiveTab()
      this.$store.commit("unsetSyncAlert")
    }
  },
  mounted () {
    this.$store.commit('sync')
    this.redirectOnInvalidToken(this.authToken)
    this.setActiveTab()
  },
  methods: {
    redirectOnInvalidToken (tkn) {
      if (!tkn) {
        this.$store.commit("unsetSyncAlert")
        this.$router.push('/login')
      }
    },
    downloadPdf () {

    },
    getUpdateStatus () {
      return localStorage.getItem('updateAvailable')
    },
    routeMatcher(option){
      let routePath = this.$route.path
      if(routePath === "/login")
        return false

      switch (option) {
        case "settings":
          return routePath.includes(option)
        case "profile":
          return routePath.includes(option)
        case "/":
          return routePath === option
        case "forms":
        case "templates":
          return routePath.includes(option)
        default:
          return false
      }
    },
    setActiveTab() {
      if(!this.$userController.isGerman())
        return 0

      if(this.$route.path.includes('templates')){
        this.activeTab = 2
      } else if(this.$route.path.includes('forms')) {
        this.activeTab = 1
      } else {
        this.activeTab = 0
      }
    },
    goHome() {
      if(localStorage.getItem("lang") === "de") {
        this.$router.push('/')
      } else {
        this.$router.push('/forms')
      }
    }
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
