<template>
  <div>
    <a :id="anchor"></a>
    <!-- Section -->
    <v-row>
      <v-col>
        <h2>{{ mutableSection.name }}</h2>
      </v-col>
      <v-col v-if="mutableSection.disableable" cols="auto">
        <v-switch
          v-model="mutableSection.disabled"
          class="ma-0"
          :disabled="disabled"
          @change="$emit('change', mutableSection)"
        >
          <template #label><strong>{{ $t('sectionBuilder.withOut') }}</strong></template>
        </v-switch>
      </v-col>
    </v-row>
    <div v-if="!mutableSection.disabled">
      <div v-for="(field, index) in mutableSection.fields" :key="field.id" :id="field.id">
        <Section
          v-if="field.type === 'Section'"
          :disabled="disabled"
          :anchor="anchor + '-' + index"
          :section="mutableSection.fields[index]"
          @change="$emit('change', mutableSection)"
        ></Section>
        <text-field
          v-if="field.type === 'Text'"
          v-model="field.value"
          :disabled="disabled"
          :prefix="field.prefix"
          :suffix="field.suffix"
          :validation-error="field.validationError"
          :name="field.name"
          @change="$emit('change', mutableSection)"
        ></text-field>
        <number-text-field
          v-if="field.type === 'NumberText'"
          v-model="mutableSection.fields[index]"
          :disabled="disabled"
          :validation-error="field.validationError"
          @change="$emit('change', mutableSection)"
        />
        <signature
          class="pa-6"
          v-if="field.type === 'Signature'"
          v-model="field.value"
          :field="field"
          :docId="$route.params.id"
          :disabled="disabled"
          :description="field.description"
          :validation-error="field.validationError"
          @change="$emit('change', mutableSection)"
        ></signature>
        <RadioButtonList
          v-if="field.type === 'RadioButtonList'"
          v-model="field.checkedElement"
          :disabled="disabled"
          :radios="field.elements"
          :validation-error="field.validationError"
          :label="field.name"
          @change="$emit('change', mutableSection)"
        ></RadioButtonList>
        <CheckboxList
          v-if="field.type === 'CheckboxList'"
          v-model="field.checkedElements"
          :elements="field.elements"
          :disabled="disabled"
          :validation-error="field.validationError"
          :label="field.name"
          @change="$emit('change', mutableSection)"
        ></CheckboxList>
        <CheckboxText
          v-if="field.type === 'CheckboxText'"
          v-model="field.value"
          :disabled="disabled"
          :validation-error="field.validationError"
          :label="field.name"
          @change="$emit('change', mutableSection)"
        ></CheckboxText>
        <InfoBox
          v-if="field.type === 'InfoBox'"
          :disabled="disabled"
          :label="field.name"
          :validation-error="field.validationError"
          :text="field.text"
        ></InfoBox>
        <TextArea
          v-if="field.type === 'TextArea'"
          v-model="field.value"
          :disabled="disabled"
          :label="field.name"
          :validation-error="field.validationError"
          @change="$emit('change', mutableSection)"
        ></TextArea>
        <Vector
          v-if="field.type === 'Vector'"
          v-model="field.elements"
          :validation-error="field.validationError"
          :disabled="disabled"
          :label="field.name"
          :size="Number(field.fieldCount)"
          :prefix="field.prefix"
          :suffix="field.suffix"
          @change="$emit('change', mutableSection)"
        ></Vector>
        <DateTimePicker
          v-if="field.type === 'Date'"
          v-model="field.value"
          :disabled="disabled"
          :validation-error="field.validationError"
          :label="field.name"
          :time-enabled="field.timeEnabled"
          @change="$emit('change', mutableSection)"
        ></DateTimePicker>
        <Table
          v-if="field.type === 'Table'"
          v-model="field.values"
          :validation-error="field.validationError"
          :disabled="disabled"
          :headers="field.elements.headers"
          :caption="field.name"
          @change="$emit('change', mutableSection)"
        ></Table>
        <PictureField
          v-if="field.type === 'Picture'"
          :validation-error="field.validationError"
          :name="field.name"
          :link="field.link"
        />
        <Sketch
          class="pa-6"
          v-if="field.type === 'Sketch'"
          v-model="field.value"
          :field="field"
          :docId="$route.params.id"
          :disabled="disabled"
          @change="$emit('change', section)"
        ></Sketch>
        <p v-if="changelogMode"
           style="font-style: italic; font-size: 12px;">
          {{ $formController.formatDate(field.ts) }} - {{ $formController.resolveUserNameById(field.editor) }}</p>
        <p v-if="field.required"
           style="font-style: italic; font-size: 12px; color: red">
          {{ $t('document.required') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import DateTimePicker from "./DateTimePicker";
import Signature from "./Signature";
import RadioButtonList from "./RadioButtonList";
import TextField from "./Text";
import CheckboxList from "./CheckboxList";
import InfoBox from "./InfoBox";
import TextArea from "./TextArea";
import Vector from "./Vector";
import Table from "./Table";
import CheckboxText from "./CheckboxText";
import NumberTextField from "./NumberText";
import PictureField from "./PictureField";
import Sketch from "./Sketch";

export default {
  name: "Section",
  components: {
    PictureField,
    NumberTextField,
    CheckboxText,
    Table,
    DateTimePicker,
    Signature,
    RadioButtonList,
    TextField,
    CheckboxList,
    InfoBox,
    TextArea,
    Sketch,
    Vector
  },
  model: {
    prop: "section",
    event: "change",
  },
  props: {
    anchor: {
      type: Number,
      required: true
    },
    section: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    changelogMode: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    mutableSection() {
      return this.section;
    }
  }
};
</script>

<style scoped></style>
