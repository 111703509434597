import { render, staticRenderFns } from "./CloneTemplateDialog.vue?vue&type=template&id=4f60b795&scoped=true"
import script from "./CloneTemplateDialog.vue?vue&type=script&lang=js"
export * from "./CloneTemplateDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f60b795",
  null
  
)

export default component.exports