<template>
  <v-skeleton-loader
    v-if="loading"
    class="mx-auto"
    max-width="300"
    type="card"
  >
  </v-skeleton-loader>
  <v-card v-else class="mx-auto" tile>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title"> {{ $t('settings.grpMgmt.name') }}: {{ group.GroupName }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-card-actions>
      <v-autocomplete
        v-model="selectedUser[group.GroupName]"
        :items="Array.from(getUserList.values())"
        item-text="username"
        item-value="username"
        :label="$t('settings.grpMgmt.name')"
        prepend-icon="mdi-account"
        return-object
      ></v-autocomplete>
      <v-btn :disabled="addLoading" class="ma-2" color="primary"
             @click="addUserToGroup(selectedUser[group.GroupName].username, group.GroupName)">{{ $t('settings.grpMgmt.add') }}
      </v-btn>
    </v-card-actions>

    <v-card-actions>

      <template>
        <v-container>
          <v-data-table
            :headers="headers"
            :items="userInGroupMap[group.GroupName]"
            :items-per-page="10"
            :loading="delLoading || addLoading"
          >
            <template v-slot:item.UserStatus="{ item }">
              <v-chip
                :color="getColor(item.UserStatus)"
                dark
              >
                {{ item.UserStatus }}
              </v-chip>
            </template>
            <template v-slot:item.Enabled="{ item }">
              <v-btn :disabled="delLoading" icon @click="removeUserFromGroup(item.Username, group.GroupName)">
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>

            </template>
          </v-data-table>
        </v-container>
      </template>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "GroupList",
  props: ['loading', 'headers', 'group', 'userInGroupMap', 'selectedUser'],
  computed: {
    ...mapGetters(["getUserList"]),
  },
  data() {
    return {
      addLoading: false,
      delLoading: false
    };
  },
  watch: {
    group: {
      handler(n, o) {
        this.addLoading = false
        this.delLoading = false
      },
      deep: true
    }
  },
  methods: {
    addUserToGroup: async function (userName, groupName) {
      this.addLoading = true
      await this.$userController.addUserToGroup(userName, groupName)
        .then(() => this.$emit('update-list'))
        .catch(() => {
          this.addLoading = false;
          this.$store.commit("setSyncAlertError")
        });
    },
    removeUserFromGroup: async function (userName, groupName) {
      this.delLoading = true
      await this.$userController.removeUserFromGroup(userName, groupName)
        .then(() => this.$emit('update-list'))
        .catch(() => {
          this.delLoading = false;
          this.$store.commit("setSyncAlertError");
        });
    },
    getColor: function (userStatus) {
      return userStatus === "CONFIRMED" ? "green" : "red"
    }
  },
}
</script>

<style scoped>

</style>
