module.exports = {
  "nav": {
    "Aufträge": "Aufträge",
    "Vorlagen": "Vorlagen",
    "me": "zuletzt bearbeitet",
  },
  "general": {
    "userName": "Benutzername",
    "userId": "Benutzer ID",
    "german": "Deutsch",
    "english": "Englisch",
    "updateInfo": "Ein Update ist verfügbar. Ungespeicherte Änderungen gehen verloren."
  },
  "headlines": {
    "revisionTable": 'Revisionen',
    "upgradeDocuments": 'Versionen',
    "changelog": 'Changelog',
    "changelog-subHeadline": "Hier sieht man alle Änderungen."
  },
  "actions": {
    "save": "Speichern",
    "create": "Anlegen",
    "cancel": "Abbrechen",
    "search": "Suchen",
    "change": "Ändern",
    "rename": "Umbennenen",
    "duplicate": "Duplizieren",
    "delete": "Löschen",
    "edit": "Bearbeiten",
    "show": "anzeigen",
    "upgrade": "Upgrade",
    deleteAction: (ctx) => `Möchten Sie wirklich ${ctx.named('name')} löschen?`,
    "continueWithOutSave": "Ohne Speichern fortfahren",
    "unsavedChanges": "Ungespeicherte Änderungen!",
    "unsavedChangesDescription": "Sie haben noch ungespeicherte Änderungen. Bitte speichern Sie Ihre Änderungen.",
    "startChangeLog": "Changelog",
    "startChangeLogFileMode": "Dokumenten History",
    "startRevisionMode": "Revisionen vergleichen",
    "startRequiredFieldsDetail": "Dokument überprüfen"
  },
  "document": {
    "rename": "Dokumentennamen ändern",
    "new-name": "Neuer Name",
    "duplicate": "Dokument duplizieren",
    "export": "Dokument exportieren",
    "delete": "Dokument löschen",
    "edit": "Aktuelles Dokument bearbeiten",
    "openTemplate": "Zur Vorlage wechseln",
    "lastEdit": "Bearbeitet am: ",
    "created": "Erstellt am: ",
    deleteOrder: (ctx) => `Möchten Sie den Auftrag ${ctx.named('name')} wirklich löschen?`,
    deleteTemplate: (ctx) => `Möchten Sie die Vorlage ${ctx.named('name')} wirklich löschen?`,
    "selectRevision": "Bitte wählen Sie eine Revision aus",
    "newRevision": "Neue Revision erstellen",
    "required": "Pflichtfeld",
    "missingValue": "Fehlender Wert",
    "missingValueDemand": "Bitte schließen Sie alle Pflichtfelder ab.",
    "noMissingValues": "Alle Pflichtfelder sind abgeschlossen!",
    "signedBy": "Unterschrieben von: ",
    "changedSketch": "Sketch geändert",
    "changelogSketch-updated": "Bilder bearbeitet in den Positionen:",
    "changelogSketch-deleted": "Bilder gelöscht in den Positionen:",
    "changelogSketch-added": "Bilder hinzugefügt in den Positionen:",
    "noChangelogFilesFound": "Es wurden noch keine Changelogs angelegt.",
    "onlyOneChangelogFileFound": "Es wurden bisher noch keine weiteren Änderungen vorgenommen.",
    "oldValue": "Vorheriger Wert",
    "newValue": "Neuer Wert",
    "chooseTemplateRevision": "Bitte wählen Sie eine Revision für das Upgrade.",
  },
  "order": {
    "singular": "Auftrag",
    "plural": "Aufträge",
    "orderNumber": "Auftragsnummer",
    "createNew": "Neuen Auftrag",
    "createNewDescription": "Neue Auftrag aus Vorlage erstellen",
    "noDataText": "Keine Vorlagen vorhanden"
  },
  "template": {
    "singular": "Vorlage",
    "plural": "Vorlagen",
    "createNew": "Neue Vorlage",
    "createNewDescription": "Neue Vorlage erstellen",
    "newName": "Name der neuen Vorlage",
    duplicateAction: (ctx) => `Sie kopieren ${ctx.named('name')}`,
    existing: "Dieser Name existiert bereits",
    copyPostfix: " - Kopie"
  },
  "language-switcher": {
    "title": "Spracheinstellungen",
    "description": "Wählen Sie Ihre Sprache"
  },
  "fields": {
    table: {
      newElement: "Neues Element",
      editElement: "Element bearbeiten",
      newEntry: "Neuer Eintrag",
      noData: "Keine Daten vorhanden",
      deleteEntry: "Eintrag löschen",
      deleteEntryText: "Möchten Sie den Eintrag wirklich löschen?"
    },
    signature: {
      signature: "Signatur",
      replace: "Signatur ersetzen",
      add: "Signatur hinzufügen",
      signedBy: "Signiert von",
      clear: "Signaturfeld leeren"
    },
    sketch: "Neues Bild bearbeiten"
  },
  sectionBuilder: {
    withOut: "Ohne"
  },
  errors: {
    unexpected: {
      title: "Unerwarteter Fehler",
      description: "Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es später noch einmal."
    }
  },
  validation: {
    required: "Erforderlich"
  },
  login: {
    username: "Benutzername",
    password: "Passwort",
    login: "Login",
    incorrect: "Falscher Benutzername oder Passwort",
    usernameCannotBeEmpty: "Benutzername darf nicht leer sein",
    changePassword: "Passwort ändern",
    youNeedNewPassword: "Sie müssen ein neues Passwort festlegen",
    oldPassword: "Altes Passwort",
    newPassword: "Neues Passwort",
    invalidPasswordException: "Das Passwort entspricht nicht der Richtlinie",
    userNotFoundException: "Benutzer existiert nicht",
    notAuthorizedException: "Falscher Benutzername oder falsches Passwort",
    authError: "Benutzername darf nicht leer sein",
    invalidParameterException: "Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut."
  },
  rules: {
    minimumLength: "Passwort hat nicht die Mindestlänge",
    requireLowercase: "Passwort enhält kein Kleinbuchstabe",
    requireUppercase: "Passwort enhält kein Großbuchstabe",
    requireNumbers: "Passwort enhält keine Zahl",
    requireSymbols: "Passwort enhält keine Sonderzeichen",
  },
  settings: {
    password: {
      edit: "bearbeiten",
      label: "Neues Passwort",
      permanent: "Passwort dauerhaft setzen"
    },
    userMgmt: {
      title: "Benutzerverwaltung",
      desc: "Hier können Sie Benutzer erstellen und ihr Passwort ändern.",
      action: "Benutzer erstellen"
    },
    grpMgmt: {
      title: "Gruppen",
      desc: "Alle verfügbaren Gruppen.",
      action: "Gruppe erstellen",
      name: "Gruppe",
      add: "Mitglied hinzufügen"
    }
  },
  me: {
    "description": "Hier werden die von Ihnen zuletzt bearbeiteten Dokumente der letzten 30 Tage angezeigt."
  }
}
