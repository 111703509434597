<template>
  <v-dialog v-model="dialog" width="500">
    <template #activator="{ on }">
      <v-btn color="primary" :data-cy="'new-form'" v-on="on" @click="fetch()">
        <v-icon left>mdi-plus</v-icon>
        {{ $t('order.createNew') }}
      </v-btn>
    </template>
    <v-card>
      <v-form v-model="formValid">
      <v-card-title>{{ $t('order.createNewDescription') }}</v-card-title>
      <v-divider/>
      <v-card-text>
        <v-text-field
          v-model="name"
          :rules="rules"
          :label="$t('order.orderNumber')"
        ></v-text-field>
        <v-select
          v-model="templateId"
          :items="templates"
          item-text="name"
          item-value="id"
          :rules="rules"
          :label="$t('template.singular')"
          :no-data-text="$t('order.noDataText')"
        ></v-select>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn text @click="dialog = false">{{ $t('actions.cancel') }}</v-btn>
        <v-btn color="primary" text @click="createForm()" :loading="loading" :disabled="!formValid">{{ $t('actions.create') }}</v-btn>
      </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import TextField from '../Text'
import { mapGetters } from 'vuex'

export default {
  name: 'FormCreationDialog',
  components: { TextField },
  data () {
    return {
      formValid: false,
      dialog: false,
      loading: false,
      name: '',
      templateId: '',
      templates: [],
      rules: [value => !!value || this.$t("validation.required")],
    }
  },
  computed: {
    ...mapGetters(['config']),
  },
  methods: {
    createForm: function () {
      this.loading = true;
      this.$templateController.getDocument(this.templateId)
        .then(res => (this.forms = res.data))
        .then(async doc => {
          await this.$formController.createDocument({
            'name': this.name,
            template: this.templateId,
            templateRevision: doc.templateRevision,
            sections: doc.sections
          })
            .then(resp => {
              this.$router.push(
                '/forms/' + resp.data['id']
              )
            })
          }
        )
        .finally(() => this.loading = false)
    },
    fetch: function () {
      this.$templateController.getDocumentList()
        .then(res => {
          res.data.forEach(doc => {
            this.templates.push({ id: doc.id, name: doc.name })
          })
        })
        .catch(res => {
          console.error(res)
          throw res
        })
    }
  }
}
</script>

<style scoped></style>
