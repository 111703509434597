<template>
  <v-dialog ref="dialog" v-model="dialog" width="500">
    <template #activator="{ on }">
      <v-btn icon v-on="on">
        <v-icon>mdi-content-duplicate</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>{{ $t('document.duplicate') }}</v-card-title>
      <v-card-text>
        {{ $t('template.duplicateAction', { name: template.name }) }}
      </v-card-text>
      <v-card-text>
        <v-text-field
          v-model="name"
          :label="$t('template.newName')"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false">{{ $t('actions.cancel') }}</v-btn>
        <v-btn
          text
          color="primary"
          :disabled="name.length <= 0"
          :loading="loading"
          @click="cloneTemplate()"
        >{{ $t('actions.duplicate') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import {fa} from "timeago.js/lib/lang";

export default {
  name: 'CloneTemplateDialog',
  props: {
    template: {
      type: Object,
      required: true
    },
    nameError: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      loading: false,
      dialog: false,
      name: this.template.name + ' - Kopie'
    }
  },
  computed: {
    ...mapGetters(['config']),
  },
  methods: {
    cloneTemplate () {
      this.loading = true;
      this.$templateController.getDocument(this.template.id)
        .then( resp =>
          this.$templateController.createDocument({
            'name': this.name,
            sections: resp.data.sections
          })
            .then(resp => {
              this.$router.push('/templates/' + resp.data.id)
            })
            .catch(()=> this.$store.commit("setSyncAlertError"))
        )
        .finally(() => this.loading = false)
    }
  }
}
</script>

<style scoped></style>
