<template>
  <v-dialog v-model="dialog" width="500">
    <template #activator="{ on }">
      <v-btn icon v-on="on">
        <v-icon class="error--text">mdi-alert</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-form>
        <v-card-title>Konflikt auswählen</v-card-title>
        <v-col>
          <v-select
            v-model="conflict"
            :items="form._conflicts"
            label="Konfliktnummer"
          ></v-select>
        </v-col>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="dialog = false">Abbrechen</v-btn>
          <v-btn
            text
            color="primary"
            @click="
              $router.push('/formsdiff/' + form._id + '?conflict=' + conflict)
            "
            >Auswählen
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SelectConflictDialog",
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      conflict: ""
    };
  },
  mounted() {},
  methods: {}
};
</script>

<style scoped></style>
