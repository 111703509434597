<template>
  <v-dialog v-model="dialog" width="500">
    <template #activator="{ on }">
      <slot :on="on">
        <v-btn class="mx-1" fab dark x-small color="secondary" v-on="on">
          <v-icon dark>mdi-trash-can</v-icon>
        </v-btn>
      </slot>
    </template>

    <v-card>
      <v-card-title>{{ name }}</v-card-title>
      <v-divider />
      <v-card-text> {{ $t('document.deleteTemplate', { name: name }) }} </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text @click="dialog = false">{{ $t('actions.cancel') }}</v-btn>
        <v-btn color="primary" text @click="deleteItem">{{ $t('actions.delete') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DeletionWarningDialog",
  props: {
    name: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dialog: false
    };
  },
  methods: {
    deleteItem: function() {
      this.$emit("delete");
      this.dialog = false;
    }
  }
};
</script>

<style scoped></style>
