<template>
  <v-dialog v-model="dialog" width="500">
    <template #activator="{ on }">
      <v-btn disabled icon v-on="on">
        <v-icon>mdi-trash-can</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-form>
        <v-card-title>{{ $t('document.delete') + ': ' + form.name}}</v-card-title>
        <v-card-text>
          {{ $t('document.deleteOrder', { name: form.name }) }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false">{{ $t('actions.cancel') }}</v-btn>
          <v-btn text color="error" :loading="loading" @click="del">{{ $t('actions.delete') }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DeleteFormDialog',
  computed: {
    ...mapGetters(['config'])
  },
  props: {
    form: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      dialog: false,
      loading: false
    }
  },
  methods: {
    del: function () {
      this.loading = true
      this.$formController.deleteDocument(this.form.id)
        .then(() => {
          this.$emit('delete', this.form)
          this.dialog = false
        })
        .finally( () => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped></style>
