import en from './assets/i18n/en.js'
import de from './assets/i18n/de.js'

import VueI18n from 'vue-i18n'
import Vue from 'vue'

Vue.use(VueI18n)

if(!localStorage.getItem('lang'))
  localStorage.setItem('lang', 'de')

export default new VueI18n({
  locale: localStorage.getItem('lang'),
  messages: {
    en: en,
    de: de,
  }
})
