<template>
  <div>
    <v-text-field
      :disabled="disabled === 'true' || disabled === true"
      :prefix="prefix"
      :value="value"
      :suffix="suffix"
      :label="name"
      :error-messages="validationError"
      @change="$emit('change', $event)"
    />
  </div>
</template>

<script>
export default {
  name: "TextField",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: ""
    },
    prefix: {
      type: String,
      default: ""
    },
    suffix: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    validationError: {
      type: String,
      default: null
    }
  },
  data: function() {
    return {
      test: {}
    };
  }
};
</script>

<style scoped></style>
