<template>
  <div >
    <h1>{{ $t('headlines.revisionTable')}}</h1>
    <template v-if="loading">
      <v-skeleton-loader
        max-width="300"
        type="card, list-item"
      ></v-skeleton-loader>
    </template>
    <template v-if="!loading">
      <v-list-item v-for="(item, index) in revisions.slice().reverse()" :key="item.title">
        <template v-slot:default="{ active }">
          <v-list-item-content>
            <v-list-item-title v-text="item.name + ' - Revisions Nr '+item.revisionNumber"></v-list-item-title>

            <v-list-item-subtitle class="text--primary" v-text="item.comment"></v-list-item-subtitle>
            <v-list-item-subtitle  v-text="'Angelegt von ' + $formController.resolveUserNameById(item.editor) + ' am ' + formatDate(item.updated)"></v-list-item-subtitle>

          </v-list-item-content>

          <!--
          <div class="icon-row" @click="goToRevision(item)">
            <v-icon @click="">mdi-content-duplicate</v-icon>
            <v-icon @click="">mdi-download</v-icon>
          </div>
          -->

          <v-list-item-action>

          </v-list-item-action>
        </template>
      </v-list-item>
    </template>
  </div>
</template>

<style scoped>

</style>

<script>

import CopyFormDialog from "../dialog/CopyFormDialog.vue";
import Export from "../../export/Export.vue";
import CreateNewRevisionDialog from "../dialog/CreateNewRevision.vue";
import DeleteFormDialog from "../dialog/DeleteFormDialog.vue";

export default {
  name: "RevisionTable",
  components: {DeleteFormDialog, CreateNewRevisionDialog, Export, CopyFormDialog},
  props: {
    type: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      revisions: [],
      loading: true
    };
  },
  methods: {
    async fetch() {
      this.loading = true

      let controller = this.$templateController

      if(this.type === 'FORM') {
        controller = this.$formController
      }

      controller.getRevisions(this.$route.params.id)
        .then( response => {
          this.revisions = response.data
          this.loading = false
        })
    },
    goToRevision(revisionItem) {
      this.$router.push('revisions/' + revisionItem.revisionNumber)
    },
    formatDate(dateString) {
      const date = new Date(dateString);

      const day = String(date.getUTCDate()).padStart(2, '0');
      const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Monate sind nullbasiert
      const year = date.getUTCFullYear();
      const hours = String(date.getUTCHours()).padStart(2, '0');
      const minutes = String(date.getUTCMinutes()).padStart(2, '0');

      return `${day}.${month}.${year} - ${hours}:${minutes}`;
    }
  },
  mounted() {
    this.fetch()
  }

};
</script>

