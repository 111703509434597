<template>
  <div>
    <v-snackbar
      bottom
      :value="visible"
      :timeout="timeout"
      style="margin-top: 30px"
      :color="color"
      left
    >
      {{ message }}
      <v-btn text v-if="dismissable" @click="visible = !visible">
        x
      </v-btn>
    </v-snackbar>
  </div>

</template>

<script>
export default {
  name: "InfoSnackbar",
  data() {
    return {
      visible: false,
      message: "message",
      color: "info",
      dismissable: true,
      timeout: -1,
    }
  }
};
</script>
