<template>
  <div>
    <v-row>
      <v-col>
        <h2>Abschnitt</h2>
      </v-col>
      <v-col cols="auto">
        <v-btn fab dark x-small color="secondary" @click="$emit('delete')">
          <v-icon dark>mdi-trash-can</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col>
        <TextField
          v-model="mutableSection.name"
          name="Name des Abschnittes"
          @change="$emit('change', mutableSection)"
        ></TextField>
      </v-col>
      <v-col cols="auto">
        <v-switch
          v-model="mutableSection.disableable"
          @change="$emit('change', mutableSection)"
        >
          <template #label>Deaktivierbar</template>
        </v-switch>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row justify="end">
      <v-col>
        <div v-for="(field, index) in mutableSection.fields">
          <v-divider v-if="index !== 0" class="ma-1"></v-divider>
          <v-row>
            <v-col cols="auto">
              <v-row>
                <v-btn
                  icon
                  :disabled="index === 0"
                  @click="moveField(index, true)"
                >
                  <v-icon>mdi-arrow-up</v-icon>
                </v-btn>
              </v-row>
              <v-row>
                <v-btn
                  icon
                  :disabled="index === mutableSection.fields.length - 1"
                  @click="moveField(index, false)"
                >
                  <v-icon>mdi-arrow-down</v-icon>
                </v-btn>
              </v-row>
            </v-col>
            <v-col>
              <section-builder
                v-if="field.type === 'Section'"
                :section="mutableSection.fields[index]"
                @delete="removeFromSectionFields(index)"
                @change="$emit('change', mutableSection)"
              />
              <text-area-builder
                v-if="field.type === 'TextArea'"
                :value="mutableSection.fields[index]"
                @delete="removeFromSectionFields(index)"
                @change="$emit('change', mutableSection)"
              />
              <text-builder
                v-if="field.type === 'Text'"
                :value="mutableSection.fields[index]"
                @delete="removeFromSectionFields(index)"
                @change="$emit('change', mutableSection)"
              />
              <number-text-builder
                v-if="field.type === 'NumberText'"
                :value="mutableSection.fields[index]"
                @delete="removeFromSectionFields(index)"
                @change="$emit('change', mutableSection)"
              />
              <info-box-builder
                v-if="field.type === 'InfoBox'"
                :value="mutableSection.fields[index]"
                @delete="removeFromSectionFields(index)"
                @change="$emit('change', mutableSection)"
              />
              <checkbox-list-builder
                v-if="field.type === 'CheckboxList'"
                :value="mutableSection.fields[index]"
                @delete="removeFromSectionFields(index)"
                @change="$emit('change', mutableSection)"
              />
              <checkbox-text-builder
                v-if="field.type === 'CheckboxText'"
                :value="mutableSection.fields[index]"
                @delete="removeFromSectionFields(index)"
                @change="$emit('change', mutableSection)"
              />
              <radio-button-list-builder
                v-if="field.type === 'RadioButtonList'"
                :value="mutableSection.fields[index]"
                @delete="removeFromSectionFields(index)"
                @change="$emit('change', mutableSection)"
              />
              <table-builder
                v-if="field.type === 'Table'"
                :value="mutableSection.fields[index]"
                @delete="removeFromSectionFields(index)"
                @change="$emit('change', mutableSection)"
              />
              <signature-builder
                v-if="field.type === 'Signature'"
                :value="mutableSection.fields[index]"
                @delete="removeFromSectionFields(index)"
                @change="$emit('change', mutableSection)"
              />
              <DateTimePickerBuilder
                v-if="field.type === 'Date'"
                v-model="mutableSection.fields[index]"
                @delete="removeFromSectionFields(index)"
                @change="$emit('change', mutableSection)"
              />
              <VectorBuilder
                v-if="field.type === 'Vector'"
                v-model="mutableSection.fields[index]"
                @delete="removeFromSectionFields(index)"
                @change="$emit('change', mutableSection)"
              />
              <PhotoBuilder
                v-if="field.type === 'Picture'"
                v-model="mutableSection.fields[index]"
                @delete="removeFromSectionFields(index)"
                @change="$emit('change', mutableSection)"
              />
              <SketchBuilder
                v-if="section.fields[index].type === 'Sketch'"
                v-model="mutableSection.fields[index]"
                @delete="removeFromSectionFields(index)"
                @change="$emit('change', section)"
              />
              <v-checkbox
                v-if="field.type !== 'Section'"
                v-model="mutableSection.fields[index].required"
                          @change="$emit('change', section)"
                          :label="$t('document.required')">
              </v-checkbox>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col cols="auto">
        <field-add-dialog
          :label="mutableSection.name"
          @add="addField($event)"
        ></field-add-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TextField from "../form/Text";
import TextBuilder from "./TextBuilder";
import FieldAddDialog from "./dialog/FieldAddDialog";
import InfoBoxBuilder from "./InfoBoxBuilder";
import SignatureBuilder from "./SignatureBuilder";
import TextAreaBuilder from "./TextAreaBuilder";
import RadioButtonListBuilder from "./RadioButtonBuilder";
import CheckboxListBuilder from "./CheckboxButtonBuilder";
import DateTimePickerBuilder from "./DatePickerBuilder";
import CheckboxTextBuilder from "./CheckboxTextBuilder";
import TableBuilder from "./TableBuilder";
import NumberTextBuilder from "./NumberTextBuilder";
import VectorBuilder from "./VectorBuilder";
import PhotoBuilder from "@/components/template/PictureBuilder";
import SketchBuilder from "./SketchBuilder";

export default {
  name: "SectionBuilder",
  components: {
    PhotoBuilder,
    VectorBuilder,
    NumberTextBuilder,
    TableBuilder,
    CheckboxTextBuilder,
    DateTimePickerBuilder,
    CheckboxListBuilder,
    RadioButtonListBuilder,
    TextAreaBuilder,
    SignatureBuilder,
    InfoBoxBuilder,
    FieldAddDialog,
    TextBuilder,
    TextField,
    SketchBuilder
  },
  model: {
    prop: "section",
    event: "change"
  },
  props: {
    section: {
      type: Object,
      required: true
    }
  },
  computed: {
    mutableSection: function() {
      return this.section;
    }
  },
  methods: {
    addField: function(field) {
      console.log(field);
      this.mutableSection.fields.push(field);
      this.$emit("change", this.mutableSection);
    },
    removeFromSectionFields: function(index) {
      this.mutableSection.fields.splice(index, 1);
      this.$emit("change", this.mutableSection);
    },
    moveField: function(index, upwards) {
      const secondIndex = index + (upwards ? -1 : 1);

      let temp = JSON.parse(
        JSON.stringify(this.mutableSection.fields[secondIndex])
      );
      this.$set(
        this.mutableSection.fields,
        secondIndex,
        this.mutableSection.fields[index]
      );
      this.$set(this.mutableSection.fields, index, temp);

      this.$emit("change", this.mutableSection);
    }
  }
};
</script>

<style scoped></style>
