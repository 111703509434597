<template>
  <v-dialog v-model="dialog" width="500">
    <template #activator="{ on }">
      <div class="text-center">
        <v-btn color="primary" small v-on="on">
          {{ label }}
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
    </template>

    <v-card>
      <v-card-title>Neues Feld hinzufügen</v-card-title>
      <v-divider></v-divider>

      <v-list>
        <v-list-item-group v-model="model">
          <v-list-item
            dense
            v-for="(item, i) in items"
            :key="i"
            @click="emitAddFieldEvent(item.event)"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
import Fields from "../../../definitions/Fields";

export default {
  name: "FieldAddDialog",
  props: {
    label: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      items: [
        {
          icon: 'mdi-note-text-outline',
          text: 'Unterabschnitt',
          event: 'Section'
        },
        {
          icon: 'mdi-text',
          text: 'Textfeld',
          event: 'TextField'
        },
        {
          icon: 'mdi-text-box-search-outline',
          text: 'Spezial Textfeld mit Anzahl',
          event: 'NumberText'
        },
        {
          icon: 'mdi-text-long',
          text: 'Mehrzeiliges Textfeld',
          event: 'TextArea'
        },
        {
          icon: 'mdi-checkbox-outline',
          text: 'Ja / Nein Textfeld',
          event: 'CheckboxText'
        },
        {
          icon: 'mdi-information-outline',
          text: 'Infobox',
          event: 'InfoBox'
        },
        {
          icon: 'mdi-checkbox-multiple-marked-outline',
          text: 'Mehrfachauswahl',
          event: 'CheckboxList'
        },
        {
          icon: 'mdi-checkbox-marked-circle-outline',
          text: 'Einfachauswahl',
          event: 'RadioButtonList'
        },
        {
          icon: 'mdi-table',
          text: 'Tabelle',
          event: 'Table'
        },
        {
          icon: 'mdi-signature-freehand',
          text: 'Signaturfeld',
          event: 'Signature'
        },
        {
          icon: 'mdi-calendar',
          text: 'Datumsfeld',
          event: 'Date'
        },
        {
          icon: 'mdi-panorama-variant-outline',
          text: 'Statisches Bildfeld',
          event: 'PictureField'
        },
        {
          icon: 'mdi-palette',
          text: 'Bildfeld (Sketch)',
          event: 'Sketch'
        }
      ],
    };
  },
  mounted() {},
  created() {},
  methods: {
    emitAddFieldEvent(name) {
      this.dialog = false;
      this.$emit("add", JSON.parse(JSON.stringify(Fields[name])));
    }
  }
};
</script>

<style scoped></style>
