const headers = {
  Abschnitt: "Abschnitt",
  Feldtyp: "Feldtyp",
  Feldname: "Feldname",
  Prefix: "Prefix",
  Wert: "Wert",
  Suffix: "Suffix",
  Spezialnummerntyp: "Spezialnummerntyp",
  Spezialnummer: "Spezialnummer",
  Anzahl: "Anzahl"
};

function exportData(form) {
  let sections = form.sections;
  let exportData = [];

  if (sections) {
    sections.forEach(section => {
      iterate(section);
    });
  }

  function iterate(section) {
    section.fields.forEach(field => {
      if (field.type === "Section") {
        iterate(field);
      } else {
        let line = createRow(field, section);
        if (line) {
          exportData.push(line);
        }
      }
    });
  }

  function createRow(field, section) {
    function addElements(elements) {
      let list = [];
      elements.forEach(element => {
        if (element.checked) list += element.name;
      });
      return list;
    }

    function addTableElements(elements) {
      let list = "";
      let headers = [];
      elements.headers.forEach((element, index) => {
        if (index !== elements.headers.length - 1) {
          list += element.text;
          headers.push(element.value);
          list += ",";
        }
      });
      list += "\n";
      elements.values.forEach(element => {
        headers.forEach(header => {
          list += element[header];
          list += ",";
        });
        list += "\n";
      });
      return list;
    }

    switch (field.type) {
      case "Text":
        return {
          Abschnitt: section ? section.name : "",
          Feldtyp: "Textfeld",
          Feldname: field ? field.name : "",
          Prefix: field.prefix ? field.prefix : "",
          Wert: field.value ? field.value : "",
          Suffix: field.suffix ? field.suffix : ""
        };
      case "NumberText":
        return {
          Abschnitt: section ? section.name : "",
          Feldtyp: "Spezial Textfeld mit Anzahl",
          Feldname: field.fields[0] ? field.fields[0].name : "",
          Wert: field.fields[0].value ? field.fields[0].value : "",
          Spezialnummerntyp: field.fields[1].name,
          Spezialnummer: field.fields[1].value,
          Anzahl: field.fields[2].value
        };
      case "TextArea":
        return {
          Abschnitt: section ? section.name : "",
          Feldtyp: "Mehrzeiliges Textfeld",
          Feldname: field ? field.name : "",
          Wert: field.value ? field.value : ""
        };
      case "CheckboxText":
        return {
          Abschnitt: section ? section.name : "",
          Feldtyp: "Ja / Nein Textfeld",
          Feldname: field ? field.name : "",
          Wert: field.value ? "JA" : "NEIN"
        };
      case "CheckboxList":
        return {
          Abschnitt: section ? section.name : "",
          Feldtyp: "Mehrfachauswahl",
          Feldname: field ? field.name : "",
          Wert: addElements(field.elements)
        };
      case "RadioButtonList":
        return {
          Abschnitt: section ? section.name : "",
          Feldtyp: "Einfachauswahl",
          Feldname: field ? field.name : "",
          Wert: field.checkedElement ? field.checkedElement : ""
        };
      case "Table":
        return {
          Abschnitt: section ? section.name : "",
          Feldtyp: "Tabelle",
          Feldname: field ? field.name : "",
          Wert: addTableElements(field.elements)
        };
      case "Signature":
        return {
          Abschnitt: section ? section.name : "",
          Feldtyp: "Signaturfeld",
          Feldname: field ? field.description : "",
          Wert: field.value.signee
            ? field.value.signee
            : "Noch nicht Unterzeichnet"
        };
      case "Date":
        return {
          Abschnitt: section ? section.name : "",
          Feldtyp: "Datumsfeld",
          Feldname: field ? field.name : "",
          Prefix: field.prefix ? field.prefix : ""
        };
      case "Vector":
        return {
          Abschnitt: section ? section.name : "",
          Feldtyp: "Vektorfeld",
          Feldname: field ? field.name : "",
          Prefix: field.prefix ? field.prefix : "",
          Wert: field.elements.join(" x "),
          Suffix: field.suffix ? field.suffix : ""
        };
      default:
        return {};
    }
  }

  return exportData;
}

export default class {
  constructor(includeHeaders = true, encloseAllFields = false) {
    this.includeHeaders = includeHeaders;
    this.encloseAllFields = encloseAllFields;
  }

  async export(headers, data) {
    if (this.includeHeaders) {
      data = [headers].concat(data);
    }
    let csv = "";
    data.forEach(dataSet => {
      let row = "";
      for (let header in headers) {
        if (dataSet[header]) {
          if (typeof dataSet[header] == "number") {
            dataSet[header] = "" + dataSet[header];
          }

          if (Array.isArray(dataSet[header])) {
            dataSet[header] = dataSet[header].join();
          }

          if (this.encloseAllFields || shouldBeEnclosed(dataSet[header])) {
            row += getEnclosedData(dataSet[header]);
          } else {
            row += dataSet[header];
          }
        }
        row += ",";
      }

      row = removeTailingComma(row) + "\r\n";
      csv += row;
    });

    return csv;
  }
}

function shouldBeEnclosed(data) {
  return (
    data.includes("\n") ||
    data.includes("\r") ||
    data.includes(",") ||
    data.includes('"')
  );
}

function removeTailingComma(value = "") {
  if (value.charAt(value.length - 1) === ",") {
    return value.slice(0, value.length - 1);
  }
  return value;
}

function getEnclosedData(data) {
  let enclosedData = '"';

  // Escape all quotes with double quotes if a value contains linebreaks and needs to be double quoted
  // See https://www.ietf.org/rfc/rfc4180.txt Chapter 2.7
  enclosedData += data.replaceAll('"', '""');
  enclosedData += '"';

  return enclosedData;
}
