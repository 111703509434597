<template>
  <v-dialog v-model="dialog" width="500">
    <template #activator="{ on }">
      <slot :on="on"></slot>
    </template>

    <v-card>
      <v-card-title>{{ $t('fields.table.deleteEntry') }}</v-card-title>
      <v-card-text>{{ $t('fields.table.deleteEntryText') }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false">{{ $t('actions.cancel') }}</v-btn>
        <v-btn text color="error" @click="click">{{ $t('actions.delete') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DeleteFormDialog",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialog: false
    };
  },
  methods: {
    click: function() {
      this.dialog = false;
      this.$emit("delete", this.item);
    }
  }
};
</script>

<style scoped></style>
