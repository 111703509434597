<template>
  <v-container>
    {{ $store.commit('setMetadata',  $t("nav.me") ) }}
    <loading-spinner :loading="loading"/>
    <v-row justify="center">
      <v-col class="col-md-8" cols="12">
        <div v-if="!loading">
          <h4>{{$t("me.description")}}</h4>
        </div>
        <div>
          <v-list>
            <v-list-item
              v-for="(form) in forms"
              :key="form.id"
              @click="href('/forms/' + form.id)"
            >
              <v-list-item-content>
                <v-list-item-title
                  v-text="form.name"
                ></v-list-item-title>
                <v-list-item-subtitle
                  v-text="renderSubtitle(form)"
                ></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action v-if="hasWrite()">
                <copy-form-dialog
                  :form="form"
                  :forms="forms"
                />
              </v-list-item-action>
              <v-list-item-action>
                <export :form="form"/>
              </v-list-item-action>
              <v-list-item-action v-if="hasWrite()">
                <v-btn icon @click="href('/forms/' + form.id + '/info')">
                  <v-icon>mdi-information-outline</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Export from "../components/export/Export.vue";
import CopyFormDialog from "../components/form/dialog/CopyFormDialog.vue";
import LoadingSpinner from "../components/utils/LoadingSpinner.vue";
import {format, register} from "timeago.js";
import de from "timeago.js/lib/lang/de";
import en from "timeago.js/lib/lang/en_US";


export default {
  name: "Me",
  components: {LoadingSpinner, CopyFormDialog, Export},
  data: function () {
    return {
      dialog: false,
      loading: true,
      forms: [],
    }
  },
  methods: {
    href: function (dest) {
      this.$router.push(dest)
    },
    fetchDocs: function () {
      this.$formController.getDocumentsForUser()
        .then(res => {
          this.forms = this.toLowerCaseKeys(res);
          this.loading = false
        })
        .catch(res => {
          console.error(res)
          throw res
        })
    },
    renderSubtitle: function (form) {
      register('de', de)
      register('en', en)
      if (localStorage.getItem('lang') === "de")
        return "Bearbeitet " + format(form.updated, 'de') + " von " + this.$formController.resolveUserNameById(form.editor)
      else
        return "Changed " + format(form.updated, 'en') + " by " + this.$formController.resolveUserNameById(form.editor)
    },
    hasWrite: function () {
      return this.$store.state.auth.groups.includes("write")
    },
    toLowerCaseKeys: function(list) {
      return list.map(obj => {
        return Object.keys(obj).reduce((acc, key) => {
          acc[key.toLowerCase()] = obj[key];
          return acc;
        }, {});
      });
    }
  },
  mounted() {
    this.fetchDocs()
  },
}
</script>
