<template>
  <v-main>
    <!-- https://codepen.io/madyanalj/pen/KRXxpN -->
    <v-container fill-height="fill-height">
      <v-layout align-center="align-center" justify-center="justify-center">
        <v-flex class="login-form text-xs-center">
          <div class="display-1 mb-3">
            <a href="https://formify.us">
              <img src="img/formify_logo_white.svg" width="25%">
            </a>
          </div>
          <v-card light="light">
            <v-card-text>
              <v-form>
                <v-fade-transition>
                  <v-alert v-if="error" type="error" :data-cy="'message'">
                    {{ message }}
                  </v-alert>
                </v-fade-transition>

                <v-text-field v-model="username" light="light" prepend-icon="person"
                              :label="$t('login.username')" type="email"></v-text-field>
                <v-text-field v-model="password" v-if="!resetPassword" light="light" prepend-icon="lock"
                              :label="$t('login.password')" type="password" @keyup.enter="login" ></v-text-field>

                <v-slide-y-transition>
                  <v-text-field v-model="password" v-if="resetPassword" light="light" prepend-icon="lock"
                                :label="$t('login.oldPassword')" type="password"></v-text-field>
                </v-slide-y-transition>
                <v-slide-y-transition>
                  <v-text-field v-model="newPassword" v-if="resetPassword" light="light" prepend-icon="lock"
                                :label="$t('login.newPassword')" type="password"></v-text-field>
                </v-slide-y-transition>

                <v-btn color="primary" @click="login" v-if="!resetPassword" :loading="loading">
                  {{ $t('login.login') }}
                </v-btn>
                <v-slide-y-transition>
                  <v-btn color="primary" @click="changePassword" v-if="resetPassword" :loading="loading">
                    {{ $t('login.changePassword') }}
                  </v-btn>
                </v-slide-y-transition>
              </v-form>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import {mapGetters} from 'vuex'
import {Auth} from 'aws-amplify'

export default {
  name: 'Login',
  data: function () {
    return {
      options: {
        isLoggingIn: true
      },
      user: {
        email: "",
        password: ""
      },
      username: '',
      password: '',
      error: false,
      message: '',
      loading: false,
      resetPassword: false,
      newPassword: "",
      awsUser: null,
    }
  },
  computed: {
    ...mapGetters(['authToken'])
  },
  methods: {
    login: async function () {
      this.loading = true

      this.error = false
      this.message = ''

      try {
        let signInResponse = await Auth.signIn(this.username, this.password)
        this.awsUser = signInResponse
        console.log("signInResponse", signInResponse)

        if (signInResponse.challengeName === "NEW_PASSWORD_REQUIRED") {
          this.error = true
          this.resetPassword = true
          this.message = this.$t("login.youNeedNewPassword")
        } else {
          this.$store.commit('setAuthToken', signInResponse.signInUserSession.accessToken.jwtToken)

          try {
            let lang
            signInResponse.signInUserSession.idToken.payload.locale === "en-US" ? lang = "en" : lang = "de"
            localStorage.setItem("lang", lang)
          } catch (exception) {
            console.log(exception)
          }

          let userList = await this.$formController.getUserList()
          this.$store.commit("setUserList", userList)
          this.$userController.setCurrentUserId(signInResponse.attributes.sub)
          localStorage.setItem("currentUserId", signInResponse.attributes.sub)

          if(localStorage.getItem("lang") === "de") {
            //this.$i18n.locale = 'de'
            this.$router.push('/')
          } else {
            //this.$i18n.locale = 'en'
            this.$router.push('/forms')
          }
        }
      } catch (error) {
        this.error = true
        let errorCode = this.splitStringAtColon(""+error)

        switch (errorCode) {
          case "InvalidPasswordException":
            this.message = this.$t("login.invalidPasswordException");
            break;
          case "UserNotFoundException":
            this.message = this.$t("login.userNotFoundException");
            break;
          case "NotAuthorizedException":
            this.message = this.$t("login.notAuthorizedException");
            break;
          case "AuthError":
            this.message = this.$t("login.authError");
            break;
          case "InvalidParameterException":
            this.message = this.$t("login.invalidParameterException");
            break;
          default:
            this.message = this.$t("errors.unexpected.description");
        }
      }
      this.loading = false
    },
    changePassword: async function () {
      try {
        let changeResponse = await Auth.completeNewPassword(this.awsUser, this.newPassword)
        console.log(changeResponse)
        this.error = false
        this.resetPassword = false
        this.loading = false
        this.password = ""
      } catch (error) {
        this.error = true
        console.log(error)
        if (error.code === "InvalidPasswordException") {
          this.message = this.$t("login.invalidPasswordException")
        } else {
          console.log(error.message)
          this.message = this.$t("errors.unexpected.description");
        }
      }
    },
    splitStringAtColon(inputString) {
      const splitArray = inputString.split(":");
      if (splitArray.length > 0) {
        return splitArray[0].trim();
      } else {
        return inputString;
      }
    },
  },
}
</script>

<style scoped></style>
