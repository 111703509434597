<template>
  <div>
    <v-row>
      <v-col>
        <h3>Mehrfachauswahl</h3>
      </v-col>
      <v-col cols="auto">
        <v-btn
          class="mx-1"
          fab
          dark
          x-small
          color="secondary"
          @click="$emit('delete')"
        >
          <v-icon dark>mdi-trash-can</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="mutableValue.name"
          label="Beschreibung"
          @change="$emit('change', mutableValue)"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row
      v-for="(element, index) in mutableValue.elements"
      :key="index"
      align="center"
      justify="center"
    >
      <v-text-field
        v-model="element.name"
        prepend-icon="check_box_outline_blank"
        label="Auswahlmöglichkeit"
        append-outer-icon="mdi-trash-can"
        @click:append-outer="removeOption(index)"
        @change="$emit('change', mutableValue)"
      />
    </v-row>

    <div class="text-center">
      <v-btn color="primary" small @click="addItem()">
        {{ mutableValue.name }}
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckboxListBuilder",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  computed: {
    mutableValue: function() {
      return this.value;
    }
  },
  methods: {
    addItem: function() {
      this.mutableValue.elements.push({});
      this.$emit("change", this.mutableValue);
    },
    removeOption: function(index) {
      this.mutableValue.elements.splice(index, 1);
      this.$emit("change", this.mutableValue);
    }
  }
};
</script>

<style scoped></style>
