<template>
  <v-col v-if="mutableValue" cols="12" class="col-md-8">
    <!-- From -->
    <div v-if="mutableValue.sections">
      <div v-for="(section, index) in mutableValue.sections">
        <v-card class="pa-10 ma-5" outlined>
          <v-row>
            <v-col cols="auto">
              <v-row>
                <v-btn
                  icon
                  :disabled="index === 0"
                  @click="moveField(index, true)"
                >
                  <v-icon>mdi-arrow-up</v-icon>
                </v-btn>
              </v-row>
              <v-row>
                <v-btn
                  icon
                  :disabled="index === mutableValue.sections.length - 1"
                  @click="moveField(index, false)"
                >
                  <v-icon>mdi-arrow-down</v-icon>
                </v-btn>
              </v-row>
            </v-col>
            <v-col>
              <SectionBuilder
                v-model="mutableValue.sections[index]"
                @change="$emit('change', mutableValue)"
                @delete="removeSection(index)"
              ></SectionBuilder>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>
    <v-btn
      color="primary"
      :data-cy="'template-new-section'"
      @click="addSection"
    >
      Abschnitt hinzufügen
    </v-btn>
  </v-col>
</template>

<script>
import SectionBuilder from "./SectionBuilder";
import Fields from "../../definitions/Fields";

export default {
  name: "StructureEditor",
  components: { SectionBuilder },
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      id: null
    };
  },
  computed: {
    mutableValue: function() {
      return this.value;
    }
  },
  methods: {
    addSection: function() {
      this.mutableValue.sections.push(
        JSON.parse(JSON.stringify(Fields.Section))
      );
      this.$emit("change", this.mutableValue);
    },
    removeSection(index) {
      this.mutableValue.sections.splice(index, 1);
      this.$emit("change", this.mutableValue);
    },
    moveField: function(index, upwards) {
      const secondIndex = index + (upwards ? -1 : 1);

      let temp = JSON.parse(
        JSON.stringify(this.mutableValue.sections[secondIndex])
      );
      this.$set(
        this.mutableValue.sections,
        secondIndex,
        this.mutableValue.sections[index]
      );
      this.$set(this.mutableValue.sections, index, temp);

      this.$emit("change", this.mutableValue);
    }
  }
};
</script>

<style scoped></style>
