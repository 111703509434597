<template>
  <div>
    <v-row>
      <v-col>
        <h3>Spezial Textfeld mit Anzahl</h3>
      </v-col>
      <v-col cols="auto">
        <v-btn
          class="mx-1"
          fab
          dark
          x-small
          color="secondary"
          @click="$emit('delete')"
        >
          <v-icon dark>mdi-trash-can</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="mutableValue.name"
          label="Name"
          @change="$emit('change', mutableValue)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
          v-model="mutableValue.fields[1].name"
          :items="templates"
          item-text="_id"
          label="Nummernfeldtyp"
          @change="$emit('change', mutableValue)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="auto">
        <v-switch
          v-model="mutableValue.extraText"
          label="Extra Textfeld"
          @change="$emit('change', mutableValue)"
        />
      </v-col>
      <v-col>
        <v-text-field
          v-model="mutableValue.fields[0].name"
          :disabled="!mutableValue.extraText"
          label="Extra Textfeld"
          @change="$emit('change', mutableValue)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-switch
          v-model="mutableValue.fields[2].disabled"
          :false-value="true"
          :true-value="false"
          label="Anzahl vorhanden"
          @change="$emit('change', mutableValue)"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "NumberTextBuilder",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      templates: [
        "Zeichnungsnummer",
        "Kreditorenummer",
        "Artikelnummer",
        "Sonstige Nummer"
      ]
    };
  },
  computed: {
    mutableValue: function() {
      return this.value;
    }
  }
};
</script>

<style scoped></style>
