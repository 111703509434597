<template>
  <div>
    <v-checkbox
      v-model="state"
      :label="label"
      :disabled="disabled"
      :error-messages="validationError"
      @change="$emit('change', $event)"
    ></v-checkbox>
  </div>
</template>

<script>
export default {
  name: "CheckboxText",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    label: {
      type: String,
      default: ""
    },
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    validationError: {
      type: String,
      default: null
    }
  },
  computed: {
    state: function() {
      return this.value;
    }
  }
};
</script>

<style scoped></style>
