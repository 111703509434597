<template>
  <v-container v-if="template">
    <v-row>
      {{ $store.commit("setMetadata", "Vorlage - " + template.name) }}
    </v-row>
    <v-bottom-navigation
      v-if="saving"
      app
      height="200">
      <v-progress-linear
        class="mb-8"
        color="cyan"
        indeterminate>
      </v-progress-linear>
    </v-bottom-navigation>
    <template>
      <v-row justify="center">
        <v-dialog v-model="showNoSaveConfirm" max-width="500px" persistent>
          <v-card>
            <v-card-title class="headline">
              Ungespeicherte Änderungen!!
            </v-card-title>
            <v-card-text
            >Sie haben noch ungespeicherte Änderungen. Bitte speichern Sie Ihre Änderungen.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="cancelRoute">
                Abbrechen
              </v-btn>
              <v-btn color="error" text @click="confirmRoute">
                Ohne Speichern fortfahren
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <v-row justify="center">
      <structure-editor
        v-if="$store.state.eye"
        v-model="template"
        @change="
          () => (!saveEnabled ? $store.commit('setSaveEnabled', true) : '')
        "
      />
      <value-editor v-else v-model="template" disabled="true"/>
    </v-row>
  </v-container>
  <v-container v-else>
    <v-sheet
      class="ma-9"
    >
      <v-skeleton-loader
        class="mx-auto"
        max-width="300"
        type="card, list-item"
      ></v-skeleton-loader>
    </v-sheet>
  </v-container>
</template>

<script>
import Fields from "../definitions/Fields";
import StructureEditor from "../components/template/StructureEditor";
import ValueEditor from "../components/form/ValueEditor";
import {mapGetters} from "vuex";

export default {
  name: "TemplateBuilder",
  components: {ValueEditor, StructureEditor},
  data() {
    return {
      id: null,
      editStructure: false,
      template: null,
      showNoSaveConfirm: false,
      noSaveConfirmed: false,
      targetRoute: "/",
      saving: false,
    };
  },
  computed: {
    ...mapGetters(["config", "saveEnabled", "saveEvent"])
  },
  watch: {
    saveEvent(value) {
      if (value) this.save();
    }
  },
  mounted() {
    this.$store.commit("setEye", true);
    this.$store.commit("sync");
    this.$store.commit("setSaveVisible", true);
    this.fetch(this.$route.params.id);
  },
  beforeRouteUpdate(to, from, next) {
    if (!this.saveEnabled) next();
    if (this.saveEnabled && this.noSaveConfirmed) next();
    this.requestLeaveConfirmation(to);
    next(false);
  },
  beforeRouteLeave(to, from, next) {
    if (!this.saveEnabled) next();
    if (this.saveEnabled && this.noSaveConfirmed) next();
    this.requestLeaveConfirmation(to);
    next(false);
  },
  created() {
  },
  methods: {
    addSection: function () {
      this.template.sections.push(Fields.Section);
    },
    removeSection(index) {
      this.template.sections.splice(index, 1);
    },
    moveField: function (index, upwards) {
      const secondIndex = index + (upwards ? -1 : 1);

      let temp = this.template.sections[secondIndex];
      this.template.sections[secondIndex] = this.template.sections[index];
      this.template.sections[index] = temp;
    },
    fetch: async function (id) {
      await this.$templateController.getDocument(id)
        .then(res => (this.template = res.data))
        .catch(err => console.error(err));
    },
    save: async function () {
      this.saving = true
      this.$templateController.updateDocument(this.template)
        // refetch to prevent conflicts on next save
        .then(() => this.fetch(this.$route.params.id))
        // generate success message
        .then(() => {
          this.$store.commit("setSaveEnabled", false);
          this.$store.commit("setSaveEvent", false);
          return {
            active: true,
            color: "success",
            message: "Erfolgreich gespeichert."
          };
        })
        // generate error message // todo better description
        .catch(err => {
          this.$store.commit("setSaveEvent", false);
          return {
            active: true,
            color: "error",
            message: err
          };
        })
        .then(alert => {
            this.$store.commit("setSyncAlert", alert)
            this.saving = false;
          }
        )
    },
    requestLeaveConfirmation: function (to) {
      this.showNoSaveConfirm = true;
      this.noSaveConfirmed = false;
      this.targetRoute = to.fullPath;
    },
    cancelRoute: function () {
      this.noSaveConfirmed = false;
      this.showNoSaveConfirm = false;
    },
    confirmRoute: function () {
      this.noSaveConfirmed = true;
      this.showNoSaveConfirm = false;
      this.$router.push(this.targetRoute);
    },
  }
};
</script>

<style scoped></style>
