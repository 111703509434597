<template>
  <v-dialog v-model="dialog" width="500">
    <template #activator="{ on }">
      <v-btn icon v-on="on">
        <v-icon>mdi-download</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>{{ $t('document.export') }}</v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <downloadexcel
              :name="name"
              :fields="headers"
              :fetch="exportExcelData"
              :before-finish="after"
            >
              <v-btn
                outlined
                class="ma-2"
                color="primary"
                :loading="xlsSpinner"
              >
                <v-icon>mdi-file-excel</v-icon>
                Excel
              </v-btn>
            </downloadexcel>
            <v-btn
              class="ma-2"
              outlined
              color="primary"
              :loading="pdfSpinner"
              @click="pdf()">
              <v-icon>mdi-file</v-icon>
              PDF
            </v-btn>
            <v-btn
              class="ma-2"
              outlined
              color="primary"
              @click="generateCSV"
              :loading="csvSpinner"
            >
              <v-icon>mdi-file-document</v-icon>
              CSV
            </v-btn>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false">{{ $t('actions.cancel') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import downloadexcel from 'vue-json-excel'
import pdfExport from '@/components/export/pdf-export'
import excelExport from '@/components/export/excel-export'
import { mapGetters } from 'vuex'
import CSVExport from './csv-export'

export default {
  name: 'Export',
  components: {
    downloadexcel
  },
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...
      mapGetters(['config']),
  },
  data: function () {
    return {
      pdfSpinner: false,
      xlsSpinner: false,
      csvSpinner: false,
      name: 'export.xls',
      dialog: false,
      headers: {
        Abschnitt: 'Abschnitt',
        Feldtyp: 'Feldtyp',
        Feldname: 'Feldname',
        Prefix: 'Prefix',
        Wert: 'Wert',
        Suffix: 'Suffix',
        Spezialnummerntyp: 'Spezialnummerntyp',
        Spezialnummer: 'Spezialnummer',
        Anzahl: 'Anzahl'
      }
    }
  },
  methods: {
    async pdf () {
      this.pdfSpinner = true
      let form = await this.getForm(this.form.id)

      await pdfExport(form)
        .then(() => (this.dialog = false))
        .then(() => (this.pdfSpinner = false))
    },
    async exportExcelData () {
      this.xlsSpinner = true
      this.name = this.form.name + ' ' + new Intl.DateTimeFormat('de-DE').format(new Date()) + '_export.xls'
      let form = await this.getForm(this.form.id)
      return excelExport(form)
    },
    after() {
      this.xlsSpinner = false
      this.dialog = false
    },
    getForm (id) {
      return this.$formController.getDocument(id)
        .then(res => {
          console.log(res.data)
          return res.data
        })
        .catch(err => console.error(err))
    },
    async generateCSV () {
      this.csvSpinner = true
      let form = await this.getForm(this.form.id)
      new CSVExport(true, true)
        .export(this.headers, excelExport(form))
        .then(csv => {
          const anchor = document.createElement('a')
          anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
          anchor.target = '_blank'
          anchor.download =
            this.form['name'] +
            '_' +
            new Intl.DateTimeFormat('de-DE').format(new Date()) +
            '_export.csv'
          anchor.click()
        })
        .then(() => (this.dialog = false))
        .then(() => (this.csvSpinner = false))
    },
  },
}
</script>
