<template>
  <v-card class="mx-auto" tile>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">Daten Export</v-list-item-title>
        <v-list-item-subtitle>Alle Aufträge als CSV exportieren</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-card-actions>
      <v-btn disabled color="primary" @click="getCSVs" :loading="loading">
        <v-icon>mdi-file-delimited-outline</v-icon>
        export
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ExportController from "./ExportController";

export default {
  name: "ExportCard",
  data() {
    return {
      loading: false
    }
  },
  methods: {
    getCSVs: async function () {
      this.loading = true
      let exportController = new ExportController(this.$store)
      let csvData = await exportController.getAllCSV()
        .catch( () => {
          this.loading = false
        })

      if(!csvData)
        return

      const anchor = document.createElement('a')
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData)
      anchor.target = '_blank'
      anchor.download = '_export.csv'
      this.loading = false
      anchor.click()
    }
  }
}
</script>

