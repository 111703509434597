import { render, staticRenderFns } from "./TextAreaBuilder.vue?vue&type=template&id=6d2bdc22&scoped=true"
import script from "./TextAreaBuilder.vue?vue&type=script&lang=js"
export * from "./TextAreaBuilder.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d2bdc22",
  null
  
)

export default component.exports