<template>
  <v-container>
    {{ $store.commit('setMetadata', 'Profil') }}
    <div>
      <v-row>
        <v-col cols="12" class="col-md-8">
          <template>
            <v-skeleton-loader
              v-if="loading"
              class="mx-auto"
              max-width="300"
              type="card"
            ></v-skeleton-loader>

            <v-card v-else class="mx-auto" tile>
              <div style="height:200px;background-color:#186BCC;"></div>
              <v-col>
                <v-avatar size="100" style="position:absolute; top: 130px">
                  <v-img :src="$formController.generateProfilePicture(user.id)"></v-img>
                </v-avatar>
              </v-col>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="title">{{ user.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ $t('general.userName') }}: {{ user.username }} | {{ $t('general.userId') }}:
                    {{ user.id }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider class="mx-4"></v-divider>

              <v-card-actions>
                <v-btn color="error" text @click="logout" outlined class="mr-2">
                  <v-icon>mdi-logout</v-icon>
                  Logout
                </v-btn>
                <!-- v-btn text @click="logout" outlined class="mr-2">
                  <v-icon>mdi-lock-reset</v-icon>
                  Change Password
                </v-btn -->
              </v-card-actions>
            </v-card>
          </template>
        </v-col>
      </v-row>

    </div>

  </v-container>
</template>

<script>
import {Auth} from 'aws-amplify'
import ExportCard from "../components/export/ExportCard"

export default {
  name: 'UserView',
  components: {
    ExportCard
  },
  data() {
    return {
      awsUser: null,
      user: null,
      loading: true,
      userList: JSON.parse(localStorage.getItem('userList')),
      groupList: [],
      userInGroupList: []
    }
  },
  methods: {
    fetchUserInfo: async function () {
      //https://stackoverflow.com/a/53653397/6615718
      let amplifyResponse = await Auth.currentAuthenticatedUser()
      this.awsUser = amplifyResponse.attributes

      this.user = await this.$formController.getUser(amplifyResponse.attributes.sub)

      this.$formController.generateProfilePicture(this.user.id)
      this.loading = false
    },
    logout: function () {
      this.$store.commit('invalidateToken')
      this.$store.commit('removeUserList')
      localStorage.clear()
      this.$router.push('/login')
    },
    getGroups: async function () {
      this.groupList = await this.$formController.getGroupList()
      this.userInGroupList = await this.$formController.getGroup("admin")
    },
  },
  mounted() {
    this.fetchUserInfo()
    this.getGroups()
  }
}
</script>
